import { useState, useCallback, useEffect, useContext } from 'react';
// import Calendar from 'react-calendar-multiday'
// import { Calendar } from '@natscale/react-calendar';
import Calendar from 'react-calendar';
import moment from 'moment';
import 'react-calendar/dist/Calendar.css';
import { UserContext } from '../App';
// import '@natscale/react-calendar/dist/main.css';

function CalendarView(props) {
  console.log('props.datesData: ', props.datesData);

  const tempDates = ['01-04-2022', '08-04-2022', '13-04-2022', '18-04-2022'];

  const userContext = useContext(UserContext);

  const onViewChange = ({ action, activeStartDate, value, view }) => {
    console.log('date onChange called: ', activeStartDate)
    const tempDate = new Date(activeStartDate);
    const tempMonth = ("0" + (tempDate.getMonth() + 1)).slice(-2);
    const tempYear = tempDate.getFullYear();
    console.log('navigated months and year is: ', tempMonth, tempYear)
    props.handleDateChange({ month_digit: tempMonth, year_digit: tempYear });
  }

  const reactToChange = (ob) => {
    console.log('date onChange called: ', ob)
    const tempDate = new Date(ob.current);
    const tempMonth = ("0" + (tempDate.getMonth() + 1)).slice(-2);
    const tempYear = tempDate.getFullYear();
    console.log('navigated months and year is: ', tempMonth, tempYear)
    props.handleDateChange({ month_digit: tempMonth, year_digit: tempYear });
  }

  // const isHighlight = useCallback((date) => {
  //   // highlight any data that is divisible by 5
  //   if (date.getDate() % 2 === 0) {
  //     return true;
  //   }
  // }, []);

  const onChange = (date) => {
    console.log('onchaged called: ', date);
  }

  const PositionDay = props => {
    const onClick = (e) => {
      if (props.isInthePast) {
        e.stopPropagation()
      }
    }
    return (
      <div onClick={onClick}
        className={getStyle(props)}
        style={getInline(props)}>
        {props.label}
      </div>)
  }

  const getStyle = function ({ date, isSelected }) {
    return `${isSelected ? 'o_selected-day' : ''} ${date.type}-day`
  }

  const getInline = ({ isToday, isInThePast }) => ({
    cursor: isInThePast ? 'inherit' : 'inherit',
    background: isToday
      ? 'rgba(141, 224, 229, 0.5)'
      : isInThePast ? 'inherit' : 'inherit',
    color: isInThePast ? '#555555' : 'inherit',
  })

  useEffect(()=> {

  },[props.datesData])

  return <>
    {/* <Calendar hideAdjacentDates isHighlight={isHighlight} className="calendarContainer" noPadRangeCell size={400} fontSize={18} value={props.datesData} onChange={onChange} />  */}
    <Calendar
      className="calendarContainer"
      tileClassName={({ date, view }) => {
        if (props.datesData.find(x => x === moment(date).format("MM-DD-YYYY"))) {
          return 'highlightCalendar'
        }
      }}      
      size={400}
      fontSize={18}
      value={new Date()}
      onActiveStartDateChange={onViewChange} />
    {/* <Calendar
      isMultiple={true}
      reset={false}
      PositionDay
      selected={props.datesData !== null ? props.datesData : ''}
      onChange={reactToChange} /> */}

  </>;
}

export default CalendarView;
