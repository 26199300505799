import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../App';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { AddUser, DialogBox } from '../common/modalView';
import { checkTokenValidity, custSingleUserInfo, custUserList, deleteSingleUserAPI } from '../api';
import '../App.css';

function Users(props) {
  const [userList, setUserList] = useState(null);
  const [addUser, setAddUser] = useState(false);
  const [showDialoge, setShowDialoge] = useState(false);
  const [msgDisplay, setMsgDisplay] = useState('');
  const [editUser, setEditUser] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [isSearchFilter, setIsSearchFilter] = useState(false);
  const [searchArray, setSearchArray] = useState([]);
  const userContext = useContext(UserContext);


  const handleAddUser = () => {
    addUser ? setAddUser(false) : setAddUser(true)
  }

  const handleDialogue = () => {
    showDialoge ? setShowDialoge(false) : setShowDialoge(true)
  }

  const handleDeleteUser = (data) => {
    console.log('UserID for delete: ', data);
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure want to delete user.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            deleteSingleUserAPI(data).then((response) => {
              console.log(response)
              if (response.request_status === 200) {
                setMsgDisplay(response)
                setShowDialoge(true);
                custUserList().then((response) => {
                  console.log("custUserList", response.data.data.resultData);
                  setUserList(response.data.data.resultData);
                }).catch((e) => console.log(e));
              }
            }).catch((e) => console.log(e))
          }
        },
        {
          label: 'No',
          onClick: () => { console.log('no clicked') }
        }
      ]
    });
  }

  const handleEditUser = (data) => {
    console.log('UserID for Edit: ', data);
    custSingleUserInfo(data).then((response) => {
      console.log('custSingleUserInfo : ', response.data.data.resultData)
      setEditUser(response.data.data.resultData)
      setAddUser(true);
    }).catch((e) => console.log(e))
  }

  const handleSearch = (event) => {
    const tempData = userList;
    setSearchValue(event.target.value);
    event.target.value !== '' ? setIsSearchFilter(true) : setIsSearchFilter(false);
    let searchArrayData = tempData.filter((item) => item.user_id.toUpperCase().includes(event.target.value.toUpperCase()) || item.user_name.toUpperCase().includes(event.target.value.toUpperCase()) || item.contact_phone_1.toUpperCase().includes(event.target.value.toUpperCase()));
    setSearchArray(searchArrayData);
    console.log('searchArray: ', searchArray);
    console.log('handle search: ', event.target.value);
  }

  useEffect(() => {
    if (userList === null && userContext !== null) {
      custUserList().then((response) => {
        if (checkTokenValidity(response.data)) {
          console.log("custUserList", response.data.data.resultData);
          setUserList(response.data.data.resultData);
        }
      }).catch((e) => console.log(e));
    }
  }, [userContext])

  return (
    <div className='container-fluid mt50 padlr50'>
      <div className='row'>
        <div className='col'>
          <h2 className="text-primary">User</h2>
        </div>
        <div className='col-sm-6 justify-content-end text-right d-flex align-items-center searchfilter'>
          <div style={{ marginRight: 20, position: 'relative' }}>
            <input type="text" className='form-control' placeholder='Search' value={searchValue} onChange={handleSearch} />
            <span className='searchicon'></span>
          </div>
          <button type='button' className='btn btn-primary' onClick={handleAddUser}><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#ffffff"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm4 11h-3v3c0 .55-.45 1-1 1s-1-.45-1-1v-3H8c-.55 0-1-.45-1-1s.45-1 1-1h3V8c0-.55.45-1 1-1s1 .45 1 1v3h3c.55 0 1 .45 1 1s-.45 1-1 1z" /></svg>&nbsp;&nbsp;Add New</button>
        </div>
      </div>

      <div className='row mt50'>
        {userList !== null && userList.length > 0 && !isSearchFilter ?
          <nav id='usersTable'>
            {userList.map((item, index) =>
              <li key={index}>
                <div><h3 className='text-gray'>{item.user_id}</h3></div>
                <div className='d-flex align-items-center justify-content-start'><span className='circleName'><span className='thumb1 '>{item.first_name.slice(0, 1)}</span></span><span style={{ marginLeft: 10, fontWeight: 600 }}>{item.first_name + ' ' + item.last_name}  <br /><small>{item.customer_user_type_txt}</small></span></div>
                <div>{item.contact_phone_1}</div>
                {/* <div>
                  <ul className='inlineList'>
                    <li><svg xmlns="http://www.w3.org/2000/svg" height="25px" viewBox="0 0 24 24" width="25px" fill="#2a9bdb"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M19.23 15.26l-2.54-.29c-.61-.07-1.21.14-1.64.57l-1.84 1.84c-2.83-1.44-5.15-3.75-6.59-6.59l1.85-1.85c.43-.43.64-1.03.57-1.64l-.29-2.52c-.12-1.01-.97-1.77-1.99-1.77H5.03c-1.13 0-2.07.94-2 2.07.53 8.54 7.36 15.36 15.89 15.89 1.13.07 2.07-.87 2.07-2v-1.73c.01-1.01-.75-1.86-1.76-1.98z" /></svg></li>
                    <li><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#2a9bdb"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-.4 4.25l-7.07 4.42c-.32.2-.74.2-1.06 0L4.4 8.25c-.25-.16-.4-.43-.4-.72 0-.67.73-1.07 1.3-.72L12 11l6.7-4.19c.57-.35 1.3.05 1.3.72 0 .29-.15.56-.4.72z" /></svg></li>
                    <li><svg xmlns="http://www.w3.org/2000/svg" height="25px" viewBox="0 0 24 24" width="25px" fill="#2a9bdb"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM7 9h10c.55 0 1 .45 1 1s-.45 1-1 1H7c-.55 0-1-.45-1-1s.45-1 1-1zm6 5H7c-.55 0-1-.45-1-1s.45-1 1-1h6c.55 0 1 .45 1 1s-.45 1-1 1zm4-6H7c-.55 0-1-.45-1-1s.45-1 1-1h10c.55 0 1 .45 1 1s-.45 1-1 1z" /></svg></li>
                  </ul>
                </div> */}
                <div>
                  <ul className='inlineList'>
                    <li style={{ cursor: 'pointer' }} onClick={() => handleEditUser(item.user_id)}><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#2a9bdb"><path d="M0 0h24v24H0z" fill="none" /><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" /></svg></li>
                    <li style={{ cursor: 'pointer' }} onClick={() => handleDeleteUser(item.user_id)}><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#2a9bdb"><path d="M0 0h24v24H0z" fill="none" /><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" /></svg></li>
                  </ul>
                </div>
              </li>
            )}
          </nav>
          :
          !isSearchFilter && <h3 className='text-center'>No Data Available</h3>
        }

        {isSearchFilter && searchArray.length > 0 ?
          <nav id='usersTable'>
            {searchArray.map(item =>
              <li>
                <div><h3 className='text-gray'>{item.user_id}</h3></div>
                <div className='d-flex align-items-center justify-content-start'><span className='circleName'><span className='thumb1 '>{item.first_name.slice(0, 1)}</span></span><span style={{ marginLeft: 10, fontWeight: 600 }}>{item.first_name + ' ' + item.last_name}  <br /><small>{item.customer_user_type_txt}</small></span></div>
                <div>{item.contact_phone_1}</div>
                {/* <div>
                  <ul className='inlineList'>
                    <li><svg xmlns="http://www.w3.org/2000/svg" height="25px" viewBox="0 0 24 24" width="25px" fill="#2a9bdb"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M19.23 15.26l-2.54-.29c-.61-.07-1.21.14-1.64.57l-1.84 1.84c-2.83-1.44-5.15-3.75-6.59-6.59l1.85-1.85c.43-.43.64-1.03.57-1.64l-.29-2.52c-.12-1.01-.97-1.77-1.99-1.77H5.03c-1.13 0-2.07.94-2 2.07.53 8.54 7.36 15.36 15.89 15.89 1.13.07 2.07-.87 2.07-2v-1.73c.01-1.01-.75-1.86-1.76-1.98z" /></svg></li>
                    <li><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#2a9bdb"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-.4 4.25l-7.07 4.42c-.32.2-.74.2-1.06 0L4.4 8.25c-.25-.16-.4-.43-.4-.72 0-.67.73-1.07 1.3-.72L12 11l6.7-4.19c.57-.35 1.3.05 1.3.72 0 .29-.15.56-.4.72z" /></svg></li>
                    <li><svg xmlns="http://www.w3.org/2000/svg" height="25px" viewBox="0 0 24 24" width="25px" fill="#2a9bdb"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM7 9h10c.55 0 1 .45 1 1s-.45 1-1 1H7c-.55 0-1-.45-1-1s.45-1 1-1zm6 5H7c-.55 0-1-.45-1-1s.45-1 1-1h6c.55 0 1 .45 1 1s-.45 1-1 1zm4-6H7c-.55 0-1-.45-1-1s.45-1 1-1h10c.55 0 1 .45 1 1s-.45 1-1 1z" /></svg></li>
                  </ul>
                </div> */}
                <div>
                  <ul className='inlineList'>
                    <li style={{ cursor: 'pointer' }} onClick={() => handleEditUser(item.user_id)}><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#2a9bdb"><path d="M0 0h24v24H0z" fill="none" /><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" /></svg></li>
                    <li style={{ cursor: 'pointer' }} onClick={() => handleDeleteUser(item.user_id)}><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#2a9bdb"><path d="M0 0h24v24H0z" fill="none" /><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" /></svg></li>
                  </ul>
                </div>
              </li>
            )}
          </nav>
          :
          isSearchFilter && <h3 className='text-center'>No Data Available</h3>
        }
      </div>

      {addUser && <AddUser data={editUser} handleAddUser={handleAddUser} />}
      {showDialoge && <DialogBox data={msgDisplay} handleDialogue={handleDialogue} />}
    </div >
  );
}

export default Users;
