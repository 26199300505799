import { useContext, useState,useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { UserContext } from '../App'
import logo from '../assets/imgs/logo.png';
// import Logo from "./logo";


function Header(props) {
  const userContextInfo = useContext(UserContext);
  
  const [toggle, setToogle] = useState(false);
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'));
  const handleToggle = () => {
    toggle ? setToogle(false) : setToogle(true)
  }
  
  const handleLinkTag = (e) => {
    toggle ? setToogle(false) : setToogle(true)
  }
  console.log('userContextInfo from header: ', userContextInfo);
  console.log('props from header: ', props)
  // console.log('userInformation: ', userInformation.comp_logo) 

  
      
  

  return (
    <header className="padlr50 fixed-top shrinkheader">
      <nav className="navbar navbar-expand-md">
        <div>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
            aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" onClick={handleToggle}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 50 50" width="30px" height="30px"><path d="M 0 7.5 L 0 12.5 L 50 12.5 L 50 7.5 Z M 0 22.5 L 0 27.5 L 50 27.5 L 50 22.5 Z M 0 37.5 L 0 42.5 L 50 42.5 L 50 37.5 Z" /></svg>
          </button>
          {/* <NavLink to="/"><img
        src={userInformation ? "https://app.ncserp.com/member/media/assets/logos/company/72637-20220401094543.jpg" : logo}
        alt={userInformation ? "Logo After Login" : "Logo Before Login"}
        width="100px"
      /></NavLink> */}
        <NavLink to="/">
  {userInformation ? (
    <img
      src={userInformation.comp_logo}
      // src="https://app.ncserp.com/member/media/assets/logos/company/72637-20220401094543.jpg"
      alt="Logo"    //Logo After Login   
      width="50px"             //Adjusted the Ncs icon according to API path icon on 31 oct 2023
    />
  ) : (
    <img
      src={logo}
      alt="Logo"   //Logo Before Login
      width="100px"  
    />
  )}
</NavLink>

          {/* <NavLink to="/"><Logo/></NavLink> */}
          {/* <NavLink to="/"><img src="https://app.ncserp.com/member/media/assets/logos/company/72637-20220401094543.jpg" width="80px"  /></NavLink> */}
          {/* <NavLink to="/"><img src={logo} width="140px" alt="Logo" /></NavLink> */}
        </div>
        {userContextInfo !== null  ?
          <div className={toggle ? "navbar-collapse collapse show" : "collapse navbar-collapse justify-content-center"} id="navbarCollapse">
            {userInformation !== null && userInformation.menu_access.length > 0 ?
              <ul>
                {userInformation.menu_access.map((item, index) =>
                  <li key={index}><NavLink activeclassname='active' to={item.menu === 'Dashboard' ? "/home" : item.menu === 'Projects' ? "/projects" : item.menu === 'Billing' ? "/billing" : item.menu === 'Job Request' ? "/job-request" : item.menu === 'Users' ? "/users" : item.menu === 'Inbox' ? "/inbox" : ""} onClick={handleToggle}>{item.menu}</NavLink></li>
                )}
                {/* <li><NavLink activeclassname='active' to="/projects" onClick={handleToggle}>Projects</NavLink></li>
                <li><NavLink activeclassname='active' to="/billing" onClick={handleToggle}>Billing</NavLink></li>
                <li><NavLink activeclassname='active' to="/job-request" onClick={handleToggle}>Job Request</NavLink></li>
                <li><NavLink activeclassname='active' to="/users" onClick={handleToggle}>Users</NavLink></li>
                <li><NavLink activeclassname='active' to="/inbox" onClick={handleToggle}>Inbox</NavLink></li> */}
              </ul>
              : ''}
          </div>
        :""}
        <nav>
          <li className="circleName"><span>
            <NavLink activeclassname='userIcon active' to="/myaccount" onClick={handleToggle}>
              {userContextInfo !== null ? userContextInfo.first_name.slice(0, 1) : 'G'}
            </NavLink>
          </span></li>
          {userContextInfo !== null ?
            <li className="logoutbtn" onClick={props.doLogout}><svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#ee3197" style={{ enableBackground: "new 0 0 24 24" }}><path d="M0 0h24v24H0z" fill="none" /><path d="M17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z" /></svg></li>
            :
            <li>Guest</li>
          }
        </nav>
      </nav>

    </header>
  );
}

export default Header;