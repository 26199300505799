import { useState, useEffect, useContext,useRef  } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

import {
  changePasswordAPI,
  checkTokenValidity,
  createJobRequestAPI,
  createUserAPI,
  custJobPriority,
  updateSingleUserAPI,
} from "../api";
import { UserContext } from "../App";
import {
  custTaskDetails,
  custTaskDocsList,
  custJobReqList,
  custTaskDocUpload,
  custTaskMakeComment,
  taskCommentList,
} from "../api";
import { Link, Link as RouterLink, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function DocumentsView(props) {
  const [jobPriority, setJobPriority] = useState(null);
  const [formData, setFormData] = useState({
    jobTitle: "",
    jobType: "",
    job_priority: "",
    description: "",
    year: "",
    filename: "",
    fileSize: "",
    files: [],
    // file_title: "", //added
  });
  const [fileList, setFileList] = useState([]);
  const [shoDialog, setShoDialog] = useState(false);
  const [msgDisplay, setMsgDisplay] = useState("");
  const navigate = useNavigate();
  const filesformData = new FormData();
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const [userCommentList, setUserCommentList] = useState(null);
  const userContext = useContext(UserContext);
  const [filteredData, setFilteredData] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [docList, setDocList] = useState([]);
  const [jobStatus, setJobStatus] = useState(null);

  const [hovered, setHovered] = useState(false)
  const hoverAlertRef = useRef(null);

  const hoverAlert = () => {
    hoverAlertRef.current = Swal.fire({
      icon: 'info',
      title: 'Note:',
      text: 'Special characters like  % # \' / & ^ () etc are not allowed in file name.',
      // showConfirmButton: false,
    });

    // Manually close the alert on mouse leave
    document.addEventListener('mouseleave', handleCloseAlert);
  };

  const handleCloseAlert = () => {
    if (hoverAlertRef.current) {
      hoverAlertRef.current.close();
      document.removeEventListener('mouseleave', handleCloseAlert);
    }
  };

  const handleInfoButtonClick = () => {
    console.log("pressed");
  };
  useEffect(() => {
    const job_request_id = window.location.pathname.split("/job-request/")[1];
    console.log("job_request_id----------------->", job_request_id);
    custJobReqList(job_request_id)
      .then((response) => {
        const allData = response.data.data.resultData;

        // Filter the data to get only the items with the matching job_request_id
        const filteredData = allData.filter(
          (item) => item.job_request_id === job_request_id
        );

        console.log(filteredData[0]);
        setFilteredData(filteredData[0]);
        if (filteredData) {
          console.log(filteredData[0]);
          setFormData({
            jobTitle: filteredData[0].job_title,
            jobType: filteredData[0].job_type,
            job_priority: filteredData[0].priority_text,
            description: filteredData[0].job_description,
            year: filteredData[0].year,
            filename: "",
            fileSize: "",
            files: filteredData[0].document_files,
          });
          setDocList(filteredData[0].document_files);
          setJobStatus(filteredData[0].job_status);
        } else {
          console.log("No matching data found.");
        }
        console.log("formData----->", formData.jobTitle);
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  }, []);
  // }, [window.location.pathname, formData ])

  console.log("FILTERED DATA", filteredData);
  console.log("job status", jobStatus);
  // const handleDialogue = () => {
  //     setFormData({ jobTitle: '', jobType: '', job_priority: '', description: '', year: '', files: [], filename: '' })
  //     setFileList([])
  //     shoDialog ? setShoDialog(false) : setShoDialog(true);
  // }

  console.log("userContext from Modal: ", userContext);

  // const handleFileUpload = () => {

  //     // console.log('before setting filelist: ', formData.files[0].target.files)
  //     if (formData.files[0] !== undefined) {
  //         //setFormData({ ...formData, filename: formData.files[0].target.files[0].name, fileSize: formData.files[0].target.files[0].size })
  //         //console.log('filesformData: ', filesformData.values)
  //         console.log('before setting filelist: ', formData.files[0].target.files)
  //         // let tempFileName = [];
  //         let tempFileName = [];
  //         let files = formData.files[0].target.files
  //         for (let i = 0; i < files.length; i++) {
  //             tempFileName.push({name: files[i].name, size: files[i].size})
  //         }
  //         console.log('tempFileName: ', tempFileName)
  //         setFileList(tempFileName)
  //     }
  // }
  const handleFileChange = (e) => {
    const files = e.target.files;
    const disallowedSpecialCharsRegex =/[^a-zA-Z0-9_.\- ]/; // Allow only alphanumeric characters, underscores, and dots

    // Check if any file name contains disallowed special characters
    const isInvalidFileNames = Array.from(files).some((file) =>
      disallowedSpecialCharsRegex.test(file.name)
    );

    if (!isInvalidFileNames) {
      // Extract file names
      const fileNames = Array.from(files).map((file) => file.name);

      // Update state with file names
      setFormData({ ...formData, files: [e] });
    } else {
      const invalidFileNames = Array.from(files)
        .filter((file) => disallowedSpecialCharsRegex.test(file.name))
        .map((file) => file.name)
        .join(", ");
      // alert(
      //   `(${invalidFileNames}) contain special characters. Please use only alphanumeric characters, underscores, hyphens, dots.`
      // );
      Swal.fire({
        icon: 'error',
        title: 'Invalid File Names',
        text: `${invalidFileNames} may contain one of the special characters. Please use only alphanumeric characters, underscores, hyphens, dots and spaces.`,
      });

      e.target.value = null; // Change to e.target.files = null;
    }
  };
  useEffect(() => {
    const handleUploadAndSetFileList = async () => {
      if (formData.files[0] !== undefined) {
        console.log("executed");
        let tempFileName = [];
        let files = formData.files[0].target.files;

        for (let i = 0; i < files.length; i++) {
          tempFileName.push({ name: files[i].name, size: files[i].size });
        }

        console.log("tempFileName: ", tempFileName);
        // setFileList((prevFileList) => [...prevFileList, ...tempFileName]);
        setFileList(tempFileName);
      }
      console.log("fileList: ", fileList);
    };

    handleUploadAndSetFileList();

    if (jobPriority === null) {
      custJobPriority()
        .then((response) => {
          if (checkTokenValidity(response.data)) {
            setJobPriority(response.data.data);
          }
        })
        .catch((e) => console.log(e));
    }
  }, [formData.files]);

  console.log("fileList: ", fileList);
  // console.log('formData.files:------------> ', fileList)

  const handleSubmit = () => {
    Swal.fire({
      title: "Uploading...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
      // showConfirmButton: false,
    });

    let attachedFiles = formData.files;
    // let attachedFiles = fileList;
    console.log("attachedFiles:------------> ", fileList);
    console.log("attachedFiles:------------> ", formData.files);

    //setDisplayUpldList(tempData);
    // let attachedFiles = [];
    let files = formData.files.length > 0 && formData.files[0].target.files;
    console.log("files from handleSubmit: ", files);
    for (let i = 0; i < files.length; i++) {
      if (formData.files.length > 0) {
        let reader = new FileReader();
        reader.readAsDataURL(files[i]);
        reader.onload = (e) => {
          let tempfile = e.target.result.split(",");
          console.log("tempfile: ", tempfile);
          attachedFiles.push({ file: tempfile[1], filename: files[i].name });
          console.log("attachedFiles: ", attachedFiles);
        };
      }
    }

    console.log("attachedFile: ", attachedFiles);

    setTimeout(function () {
      const job_request_id = window.location.pathname.split("/job-request/")[1];
      console.log("job_request_id------->", job_request_id);
      let jreq_id = job_request_id;
      // let jreq_id = response.job_request_id;
      console.log("attachedFiles-->", attachedFiles);
      // if (response.request_status === 200) {
      const promises = attachedFiles.map((attachedFile) => {
        // console.log("FINAL SUCCESS", formData.file_title);
        return createJobRequestAPI({
          job_title: "",
          attached_file_name: formData.filename,
          job_type: "",
          job_description: "",
          priority_id: "",
          year: "",
          document_files: attachedFile.file,
          attached_file_name: attachedFile.filename,
          job_request_id: jreq_id,
          // // file_title: formData.file_title, //added
        })
          .then((response) => {
            console.log(response);
            if (response.request_status === 200) {
              console.log("FINAL SUCCESS");
            }
          })
          .catch((e) => console.log(e));
      });
      Swal.close();
      showAlertAndRedirect();

      // Wait for all promises to complete
      // Promise.all(promises)
      //   .then(() => {
      //     // setMsgDisplay(response);
      //     setShoDialog(true);
      //     setShowSuccessMessage(true);

      //     setFormData({
      //       jobTitle: "",
      //       jobType: "",
      //       job_priority: "",
      //       description: "",
      //       year: "",
      //       filename: "",
      //       fileSize: "",
      //       files: [],
      //       // file_title: "demo",
      //     });
      //     setFileList([]);
      //     Swal.close();
      //     showAlertAndRedirect();
      //   })
      // .catch((e) => console.log(e));
    }, 500);
  };

  const handleModalClose = () => {
    // setModalVisibility(false);
    // If you want to trigger an additional action, call it here
    console.log("Modal close button pressed");
    // window.location.reload()
    navigate(`/job-request`, { replace: true });
    // props.handleJobRequest();
  };

  function showAlertAndRedirect() {
    // const job_request_id = window.location.pathname.split('/job-request/')[1];
    // console.log("job_request_id------->",job_request_id)
    Swal.fire({
      title: "Success!",
      text: "Job Document Added",
      icon: "success",
      confirmButtonText: "OK",
    }).then((result) => {
      if (result.isConfirmed) {
        // Redirect to the job request page after a delay
        setTimeout(() => {
          window.location.reload();
          // navigate(`/job-request/${job_request_id}`, { replace: true })
        }, 200);
      }
    });
  }

  // let previousFile = formData.files
  console.log("formData: ", formData);
  return (
    <div
      className="modal d-block"
      id="commonModal"
      aria-hidden="true"
      aria-labelledby="commonModalLabel"
      tabIndex="-1"
    >
      {/* <div className={`modal ${isModalVisible ? 'd-block' : ''}`} id="commonModal" aria-hidden="true" aria-labelledby="commonModalLabel" tabIndex="-1"> */}

      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title" id="commonModalLabel">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="40px"
                viewBox="0 0 24 24"
                width="40px"
                fill="#023e62"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M13 11h-2v3H8v2h3v3h2v-3h3v-2h-3zm1-9H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11z" />
              </svg>{" "}
              &nbsp; Job : {formData.jobTitle}
            </h3>
            {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.handleJobRequest} style={{ marginRight: 20 }}></button> */}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleModalClose}
              style={{ marginRight: 20 }}
            ></button>
          </div>
          <div className="modal-body">
            <div className="row noleftRight justify-content-evenly">
              <div className="col-sm-6 border-blue ">
                <div class="col-sm-12 mt-auto" style={{ padding: "10px" }}>
                  <div className="row mb-3">
                    <div className="col-sm-6">
                      <label htmlFor="jobtitle" className="form-label">
                        Job Title :{" "}
                      </label>{" "}
                      {formData.jobTitle}{" "}
                      {/* <input type="email" className="form-control" id="jobtitle" value={formData.jobTitle} onChange={(e) => setFormData({ ...formData, jobTitle: e.target.value })} /> */}
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="jobtype" className="form-label">
                        Job Type :{" "}
                      </label>{" "}
                      {formData.jobType}
                      {/* <input type="email" className="form-control" id="jobtype" value={formData.jobType} onChange={(e) => setFormData({ ...formData, jobType: e.target.value })} /> */}
                    </div>
                    <div className="col-sm-6 mt10">
                      <label htmlFor="jobtype" className="form-label">
                        Job Priority :{" "}
                      </label>{" "}
                      {formData.job_priority}{" "}
                    </div>
                    <div className="col-sm-6 mt10">
                      <label htmlFor="year" className="form-label">
                        Year :{" "}
                      </label>{" "}
                      {formData.year}
                      {/* <input type="email" className="form-control" id="year" value={formData.year} onChange={(e) => setFormData({ ...formData, year: e.target.value })} /> */}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-12">
                      <label htmlFor="jobtitle" className="form-label">
                        Job Description :{" "}
                      </label>{" "}
                      {formData.description}
                      {/* <input type="email" className="form-control" id="jobtitle" value={formData.jobTitle} onChange={(e) => setFormData({ ...formData, jobTitle: e.target.value })} /> */}
                    </div>
                  </div>
                </div>{" "}
                <hr></hr>
                <div className="col-sm-12  mt-auto" style={{ padding: "10px" }}>
                  <div className="text-left">
                    <div className="row">
                      <div className="col-sm-6">
                        <h3>Document(s) uploaded</h3>
                      </div>
                      {jobStatus !== "Converted To Job" && (
                        <div className="col-sm-6 text-right">
                          <div className="col-sm-12">
                            <button
                              style={{ marginRight: 25, float: "right" }}
                              type="button"
                              className="btn btn-primary btn-md"
                              onClick={handleSubmit}
                            >
                              Submit
                            </button>

                            <div
                              className="fileuploadBtn"
                              style={{
                                marginRight: 25,
                                float: "right",
                                cursor: "pointer",
                              }}
                            >
                              <input
                                type="file"
                                id="formFileMultiple"
                                multiple
                                // onChange={(e) =>
                                //   setFormData({ ...formData, files: [e] })
                                // }

                                onChange={handleFileChange}
                                style={{ cursor: "pointer" }}
                              />
                              {/* <input type="file" id="formFileMultiple" onChange={(e) => setFormData({ ...formData, files: [e] })} multiple /> */}
                              <button
                                type="button"
                                className="btn btn-secondary btn-md"
                                // style={{  marginTop : 25 }}
                              >
                                Add New Document
                              </button>
                            </div>

                            <button
                              style={{
                                marginRight: 10,
                                float: "right",
                                backgroundColor: "transparent",
                                border: "none",
                                padding: 10,
                              }}
                              type="button"
                              className="btn btn-info btn-md"
                              title="Note : Special characters like SPACE % # ' / & ^ () etc are not allowed in file name."
                              onMouseEnter={() => { setHovered(true); hoverAlert(); }}
                              onMouseLeave={() => setHovered(false)}
                            >
                              <FontAwesomeIcon icon={faQuestionCircle} />
                            </button>
                          </div>
                        </div>
                      )}
                    </div>

                    {docList.length > 0 && (
                      <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                        <ul type="none">
                          {docList.map((file, index) => (
                            <li
                              key={index}
                              style={{ paddingTop: 10, paddingLeft: 5 }}
                            >
                              <i
                                className="fa fa-download text-success"
                                aria-hidden="true"
                              ></i>
                              &nbsp;
                              {file.filepath && (
                                <a
                                  href={file.filepath}
                                  className="text-primary"
                                  download
                                  target="_blank"
                                  style={{ textDecoration: "none" }}
                                >
                                  {
                                    file.filepath.split(
                                      "/customer-job-request/"
                                    )[1]
                                  }
                                </a>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  <div class="col-sm-12" style={{ marginTop: 25 }}></div>
                </div>
              </div>
              {jobStatus !== "Converted To Job" &&
                jobStatus !== "Rejected" &&
                jobStatus !== "Cancelled" && (
                  <div className="col-sm-5 border-blue mt-top">
                    <div style={{ marginRight: 25 }}>
                      <div
                        className="gray-text-box"
                        style={{
                          // backgroundColor : "#e5cfcf",
                          // padding: "10px",
                          borderRadius: "5px",
                          height: "100%",
                          color: "GrayText",
                        }}
                      >
                        <b>Note : </b> <br />
                        Upload multiple documents at once, and trying to add
                        documents again will clear the previous documents. You
                        can also upload documents after submitting job requests.
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-sm-12" style={{ padding: "10px" }}>
                        <label
                          for="exampleFormControlInput1"
                          className="form-label"
                        >
                          <h3>New Attached Document</h3>
                        </label>

                        {fileList.length > 0 ? (
                          <div className="fileAttachedContainer" style={{ maxHeight : '300px',overflowY : 'auto' }}>
                          <ul className="fileAttachedList">
                            {/* <li><div className="d-flex justify-content-between"><div>{formData.filename.split('.', 3).pop() === 'docx' ? <img src="/assets/imgs/wordIcon.png" alt="fileicon" width="35px" /> : formData.filename.split('.', 3).pop() === 'xlsx' ? <img src="/assets/imgs/excelIcon.png" width="35px" /> : formData.filename.split('.', 3).pop() === 'pptx' ? <img src="/assets/imgs/powerpointIcon.png" width="35px" /> : formData.filename.split('.', 3).pop() === 'gif' ? <img src="/assets/imgs/gifIcon.png" width="35px" /> : formData.filename.split('.', 3).pop() === 'jpg' ? <img src="/assets/imgs/jpgIcon.png" width="35px" /> : formData.filename.split('.', 3).pop() === 'png' ? <img src="/assets/imgs/pngIcon.png" width="35px" /> : formData.filename.split('.', 3).pop() === 'pdf' ? <img src="/assets/imgs/pdfIcon.png" width="35px" /> : <img src="/assets/imgs/file.png" width="35px" />} {formData.filename}</div><div>{parseFloat(formData.fileSize / 1048576).toFixed(2)} MB</div></div></li> */}
                            {fileList.map((item, index) => (
                              <li key={index}>
                                <div className="d-flex justify-content-between">
                                  <div>
                                    {item.name.split(".", 3).pop() ===
                                    "docx" ? (
                                      <img
                                        src="/assets/imgs/wordIcon.png"
                                        alt="fileicon"
                                        width="35px"
                                      />
                                    ) : item.name.split(".", 3).pop() ===
                                      "xlsx" ? (
                                      <img
                                        src="/assets/imgs/excelIcon.png"
                                        width="35px"
                                        alt="Excel"
                                      />
                                    ) : item.name.split(".", 3).pop() ===
                                      "pptx" ? (
                                      <img
                                        src="/assets/imgs/powerpointIcon.png"
                                        width="35px"
                                        alt="Power Point"
                                      />
                                    ) : item.name.split(".", 3).pop() ===
                                      "gif" ? (
                                      <img
                                        src="/assets/imgs/gifIcon.png"
                                        width="35px"
                                        alt="gif"
                                      />
                                    ) : item.name.split(".", 3).pop() ===
                                      "jpg" ? (
                                      <img
                                        src="/assets/imgs/jpgIcon.png"
                                        width="35px"
                                        alt="jpg"
                                      />
                                    ) : item.name.split(".", 3).pop() ===
                                      "png" ? (
                                      <img
                                        src="/assets/imgs/pngIcon.png"
                                        width="35px"
                                        alt="png"
                                      />
                                    ) : item.name.split(".", 3).pop() ===
                                      "pdf" ? (
                                      <img
                                        src="/assets/imgs/pdfIcon.png"
                                        width="35px"
                                        alt="pdf"
                                      />
                                    ) : (
                                      <img
                                        src="/assets/imgs/file.png"
                                        width="35px"
                                        alt="file"
                                      />
                                    )}{" "}
                                    {item.name}
                                  </div>
                                  <div>
                                    {parseFloat(item.size / 1048576).toFixed(2)}{" "}
                                    MB
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                          </div>
                        ) : (
                          <div className="d-flex justify-content-center align-items-center">
                            <img
                              src="/assets/imgs/fileupload.jpg"
                              alt="No files selected"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div></div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
      {/* {shoDialog && <DialogBox2 data={msgDisplay} handleDialogue={handleDialogue} />} */}
    </div>
  );
}

export default DocumentsView;

// export const DialogBox2 = (props) => {
//     return (
//         <div className="modal d-block" tabIndex="-1">
//             <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
//                 <div className="modal-content">
//                     <div className="modal-header dark-bg">
//                         <h5 className="modal-title">{props.data.status ? 'Success' : 'Failed'}</h5>
//                     </div>
//                     <div className="modal-body">
//                         {props.data.status ? <p className="green-text">{props.data.message}</p> : <p className="text-red">{props.data.message}</p>}
//                     </div>
//                     <div className="modal-footer">
//                         <button type="button" className="btn btn-secondary" onClick={props.handleDialogue}>Close</button>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export const AddUser = (props) => {
//     const [formData, setFormData] = useState({
//         first_name: '',
//         last_name: '',
//         user_email: '',
//         contact_phone_1: '',
//         user_name: '',
//         password: '',
//         customer_user_type: '1'
//     });

//     const [shoDialog, setShoDialog] = useState(false);
//     const [msgDisplay, setMsgDisplay] = useState('');

//     const handleDialogue = () => {
//         setFormData({ first_name: '', last_name: '', user_email: '', contact_phone_1: '', user_name: '', password: '', customer_user_type: '1' })
//         shoDialog ? setShoDialog(false) : setShoDialog(true);
//     }

//     const userContext = useContext(UserContext);
//     console.log('userContext from Modal: ', userContext)

// const handleSubmit = () => {
//     if (props.data) {
//         updateSingleUserAPI(formData).then((response) => {
//             console.log(response)
//             if (response.request_status === 200) {
//                 setMsgDisplay(response)
//                 setShoDialog(true);
//             }
//         }).catch((e) => console.log(e))
//     } else {
//         createUserAPI(formData).then((response) => {
//             console.log(response)
//             if (response.request_status === 200) {
//                 setMsgDisplay(response)
//                 setShoDialog(true);
//             }
//         }).catch((e) => console.log(e))
//     }

// }
// useEffect(() => {
//     if (props.data) {
//         setFormData(props.data);
//     }
// }, [props.data])

//     return (
//         <div className="modal d-block" id="commonModal" aria-hidden="true" aria-labelledby="commonModalLabel" tabIndex="-1">
//             <div className="modal-dialog modal-fullscreen">
//                 <div className="modal-content">
//                     <div className="modal-header">
//                         <h3 className="modal-title" id="commonModalLabel">Create New User</h3>
//                         <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.handleAddUser} style={{ marginRight: 20 }}></button>
//                     </div>
//                     <div className="modal-body">
//                         <div className="row noleftRight justify-content-evenly align-items-center">
//                             <div className="col-sm-6 box-shadow pad30" style={{ borderRadius: '5px' }}>
//                                 <div className="row mb-3" style={{ paddingLeft: '30px', paddingRight: '30px' }}>
//                                     <div className="col-sm-6">
//                                         <label htmlFor="first_name" className="form-label">First Name</label>
//                                         <input type="text" className="form-control" name="first_name" id="first_name" value={formData.first_name} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
//                                     </div>
//                                     <div className="col-sm-6">
//                                         <label htmlFor="last_name" className="form-label">Last Name</label>
//                                         <input type="text" className="form-control" name="last_name" id="last_name" value={formData.last_name} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
//                                     </div>
//                                 </div>
//                                 <div className="row mb-3" style={{ paddingLeft: '30px', paddingRight: '30px' }}>
//                                     <div className="col-sm-6">
//                                         <label htmlFor="email" className="form-label">Email</label>
//                                         <input type="email" className="form-control" name="user_email" id="email" value={formData.user_email} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
//                                     </div>
//                                     <div className="col-sm-6">
//                                         <label htmlFor="phone_no" className="form-label">Phone No.</label>
//                                         <input type="tel" className="form-control" name="contact_phone_1" id="phone_no" value={formData.contact_phone_1} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
//                                     </div>
//                                 </div>
//                                 <div className="row mb-3" style={{ paddingLeft: '30px', paddingRight: '30px' }}>
//                                     <div className="col-sm-6">
//                                         <label htmlFor="username" className="form-label">Username</label>
//                                         <input type="email" className="form-control" name="user_name" id="username" value={formData.user_name} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
//                                     </div>
//                                     <div className="col-sm-6">
//                                         <label htmlFor="password" className="form-label">Password</label>
//                                         <input type="password" className="form-control" name="password" id="password" value={formData.password} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
//                                     </div>
//                                 </div>
//                                 <div className="row mb-3" style={{ paddingLeft: '30px', paddingRight: '30px' }}>
//                                     <label htmlFor="role" className="form-label">Role</label>
//                                     <div className="col-sm-12">
//                                         <div className="form-check">
//                                             <input className="form-check-input" type="radio" name="userrole" value="1" id="administrator" onChange={(e) => setFormData({ ...formData, customer_user_type: e.target.value })} checked={formData.customer_user_type === '1'} />
//                                             <label className="form-check-label" htmlFor="administrator">
//                                                 Adminstrator <br />
//                                                 <small>This Role will have access to all modules</small>
//                                             </label>
//                                         </div>
//                                         <div className="form-check mt10">
//                                             <input className="form-check-input" type="radio" name="userrole" value="2" id="projectmanager" onChange={(e) => setFormData({ ...formData, customer_user_type: e.target.value })} checked={formData.customer_user_type === '2'} />
//                                             <label className="form-check-label" htmlFor="projectmanager">
//                                                 Project Manager <br />
//                                                 <small>This Role will have access to Project module and enable feature to communicate with assignee of the Project</small>
//                                             </label>
//                                         </div>
//                                         <div className="form-check mt10">
//                                             <input className="form-check-input" type="radio" name="userrole" value="3" id="accountant" onChange={(e) => setFormData({ ...formData, customer_user_type: e.target.value })} checked={formData.customer_user_type === '3'} />
//                                             <label className="form-check-label" htmlFor="accountant">
//                                                 Accountant <br />
//                                                 <small>This Role will have access to Billing module</small>
//                                             </label>
//                                         </div>
//                                         <div className="form-check mt10">
//                                             <input className="form-check-input" type="radio" name="userrole" value="4" id="hr" onChange={(e) => setFormData({ ...formData, customer_user_type: e.target.value })} checked={formData.customer_user_type === '4'} />
//                                             <label className="form-check-label" htmlFor="hr">
//                                                 HR <br /><small>This role will have access to User Management</small>
//                                             </label>

//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="row mb-3 text-right" style={{ paddingLeft: '30px', paddingRight: '30px' }}>
//                                     <div className="col">
//                                         <button type="button" className="btn btn-primary btn-lg" onClick={handleSubmit}>Submit</button>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-sm-5">
//                                 <div className="row mb-3">

//                                     <div className="col-sm-12 text-center">
//                                         <div className="d-flex justify-content-center align-items-center">
//                                             <img src="/assets/imgs/adduser.jpg" alt="No files selected" />
//                                         </div>
//                                         <h2 className="mt20">How it Work?</h2>
//                                         <h3 className="text-gray font-light">We will send an email inviting team members to join your team</h3>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             {shoDialog && <DialogBox2 data={msgDisplay} handleDialogue={handleDialogue} />}
//         </div>
//     );
// }

// export const ChangePassword = (props) => {
//     const [shoDialog, setShoDialog] = useState(false);
//     const [msgDisplay, setMsgDisplay] = useState('');
//     const [formData, setFormData] = useState({
//         old_password: '',
//         new_password: '',
//         confirm_password: ''
//     });
//     const userContext = useContext(UserContext);

//     const [oldShwPass, setOldShwPass] = useState(false);
//     const [newShwPass, setNewShwPass] = useState(false);
//     const [confShwPass, setConfShwPass] = useState(false);

//     const handleDialogue = () => {
//         shoDialog ? setShoDialog(false) : setShoDialog(true);
//     }

const handleSubmit = () => {
  //         if (formData.old_password !== '' && formData.new_password !== '' && formData.confirm_password !== '') {
  //             if (formData.new_password.length >= 8 && formData.confirm_password.length >= 8) {
  //                 if (formData.new_password === formData.confirm_password) {
  //                     let regex = new RegExp('(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])')
  //                     if (regex.test(formData.new_password)) {
  //                         //final api call
  //                         changePasswordAPI(formData).then((response) => {
  //                             console.log('changePasswordAPI: ', response.status);
  //                             if (response.status) {
  //                                 setMsgDisplay({ status: true, message: response.message })
  //                                 shoDialog ? setShoDialog(false) : setShoDialog(true);
  //                                 setFormData({
  //                                     old_password: '',
  //                                     new_password: '',
  //                                     confirm_password: ''
  //                                 })
  //                             } else {
  //                                 setMsgDisplay({ status: false, message: response.message })
  //                                 shoDialog ? setShoDialog(false) : setShoDialog(true);
  //                             }
  //                         }).catch((e) => console.log(e));
  //                     } else {
  //                         setMsgDisplay({ status: false, message: 'password contains a combination of uppercase and lowercase letter and number are required' })
  //                         shoDialog ? setShoDialog(false) : setShoDialog(true);
  //                     }
  //                 } else {
  //                     setMsgDisplay({ status: false, message: 'New Password and Confirm Password are not matching' })
  //                     shoDialog ? setShoDialog(false) : setShoDialog(true);
  //                 }
  //             } else {
  //                 setMsgDisplay({ status: false, message: 'Password must be atleast 8 characters' })
  //                 shoDialog ? setShoDialog(false) : setShoDialog(true);
  //             }
  //         } else {
  //             setMsgDisplay({ status: false, message: 'All Fields are mandatory' })
  //             shoDialog ? setShoDialog(false) : setShoDialog(true);
  //         }
};

//     return (
//         <div className="modal d-block" id="commonModal" aria-hidden="true" aria-labelledby="commonModalLabel" tabIndex="-1">
//             <div className="modal-dialog modal-fullscreen">
//                 <div className="modal-content">
//                     <div className="modal-header">
//                         <h3 className="modal-title" id="commonModalLabel">Change Password</h3>
//                         <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.handleChangePass} style={{ marginRight: 20 }}></button>
//                     </div>
//                     <div className="modal-body">
//                         <div className="row noleftRight justify-content-evenly align-items-center">
//                             <div className="col-sm-5 box-shadow pad30" style={{ borderRadius: '5px' }}>
//                                 <div className="row mb-3" style={{ paddingLeft: '30px', paddingRight: '30px' }}>
//                                     <div className="col-sm-12 posrel">
//                                         <label htmlFor="old_password" className="form-label">Old Password</label>
//                                         <input type={oldShwPass ? 'text' : 'password'} className="form-control" name="old_password" id="old_password" value={formData.old_password} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} autoComplete="off" />
//                                         <div className="shwpass" onClick={() => setOldShwPass(oldShwPass ? false : true)}><i className={oldShwPass ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i></div>
//                                     </div>
//                                 </div>
//                                 <div className="row mb-3" style={{ paddingLeft: '30px', paddingRight: '30px' }}>
//                                     <div className="col-sm-12 posrel">
//                                         <label htmlFor="new_password" className="form-label">New Password</label>
//                                         <input type={newShwPass ? 'text' : 'password'} minLength="8" className="form-control" name="new_password" id="new_password" value={formData.new_password} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} autoComplete="off" />
//                                         <div className="shwpass" onClick={() => setNewShwPass(newShwPass ? false : true)}><i className={newShwPass ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i></div>
//                                     </div>
//                                 </div>
//                                 <div className="row mb-3" style={{ paddingLeft: '30px', paddingRight: '30px' }}>
//                                     <div className="col-sm-12 posrel">
//                                         <label htmlFor="confirm_password" className="form-label">Confirm Password</label>
//                                         <input type={confShwPass ? 'text' : 'password'} minLength="8" className="form-control" name="confirm_password" id="confirm_password" value={formData.confirm_password} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} autoComplete="off" />
//                                         <div className="shwpass" onClick={() => setConfShwPass(confShwPass ? false : true)}><i className={confShwPass ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i></div>
//                                     </div>
//                                 </div>
//                                 <div className="row mb-3 text-right mt50" style={{ paddingLeft: '30px', paddingRight: '30px' }}>
//                                     <div className="d-flex justify-content-between">
//                                         <button type="button" className="btn btn-link" onClick={handleSubmit}>Forgot Password</button>
//                                         <button type="button" className="btn btn-primary btn-lg" onClick={handleSubmit}>Submit</button>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-sm-7">
//                                 <div className="row mb-3">
//                                     <div className="col-sm-12 text-center">
//                                         <div className="d-flex justify-content-center align-items-center">
//                                             <img src="/assets/imgs/changePassBg.jpg" alt="Change Password Image" />
//                                         </div>
//                                         <h2 className="mt50 text-primary">Note</h2>
//                                         <h3 className="text-gray font-light">A minimum 8 charaters password contains a combination of uppercase and lowercase letter and number are required..</h3>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             {shoDialog && <DialogBox2 data={msgDisplay} handleDialogue={handleDialogue} />}
//         </div>
//     );
// }
