import { useState, useRef, useEffect, useContext } from "react";
import { Link } from 'react-router-dom';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import People from './people';
import CalendarView from './calendarView';
import EventsHistory from './eventsHistory';
import RecentActivity from './recentActivity'
import StatewiseAnalyse from './stagewiseAnalyse'
import OutStandingBill from './outStandingBill';
import moment from "moment";
import { checkTokenValidity, custCalendarData, custCalendarDateHighlightsData, customerActiveTask, custSupportEmails, custUserList } from "../api";
import { UserContext } from '../App';

import 'react-circular-progressbar/dist/styles.css';
import LatestAnnouncment from "./latestAnnoucement";


function Dashboard(props) {
  const [custActiveTask, setCustActiveTask] = useState(null);
  const [myPeople, setMyPeople] = useState(null);
  const [calendarDates, setCalendarDates] = useState([]);
  const [dateEventList, setDateEventList] = useState([]);
  const [custSupportEmail, setCustSupportEmail] = useState(null);
  const [greetingMessage, setGreetingMessage] = useState({
    message: 'Good Morning',
    time: ''
  })

  //const token = sessionStorage.getItem('token');
  const userContext = useContext(UserContext);
  const allowJobRequest = JSON.parse(sessionStorage.getItem('userInfo'))

  const handleDateChange = (data) => {
    console.log('handleDateChange called from dashboard', data);
    custCalendarDateHighlightsData(data).then((response) => {
      if (checkTokenValidity(response.data)) {
        console.log("custCalendarDateHighlightsData", response.data.data.resultData);
        let highlDates = [];
        response.data.data.resultData.forEach(element => {
          let tempDate = element.date.split('-');
          let newDate = `${tempDate[1]}-${tempDate[0]}-${tempDate[2]}`;
          // console.log('newDate: ', newDate);
          highlDates.push(newDate)
        });
        console.log('highlDates: ', highlDates);
        setCalendarDates(highlDates);
      }
    }).catch((e) => console.log(e));

    custCalendarData(data).then((response) => {
      console.log("custCalendarData", response.data.data.resultData);
      setDateEventList(response.data.data.resultData);
    }).catch((e) => console.log(e));

  }

  const constGetLocalTime = () => {
    let datecurrent = new Date();
    
    // Directly format the date using moment
    let todayDate = moment(datecurrent).format('DD MMM yyyy');
    let hrs = datecurrent.getHours();
    
    // Determine AM/PM based on hours
    let todayAMPM = hrs < 12 ? 'am' : 'pm';
    
    var greet;
    if (hrs < 12)
      greet = 'Good Morning';
    else if (hrs >= 12 && hrs <= 17)
      greet = 'Good Afternoon';
    else if (hrs >= 17 && hrs <= 24)
      greet = 'Good Evening';
  
    setGreetingMessage({
      message: greet,
      time: `${todayDate}`
    });
  };

  useEffect(() => {
    if (custActiveTask === null && userContext !== null) {
      constGetLocalTime()
      customerActiveTask().then((response) => {
        if (checkTokenValidity(response)) {
          console.log("custActiveTask", response.data);
          setCustActiveTask(response.data.resultData);
        }
      }).catch((e) => console.log(e));

      if (myPeople === null) {
        custUserList().then((response) => {
          console.log("myPeople", response.data.data.resultData);
          setMyPeople(response.data.data.resultData);
        }).catch((e) => console.log(e));
      }

      if (custSupportEmail === null) {
        custSupportEmails().then((response) => {
          console.log('custSupportEmails: ', response.data.data)
          setCustSupportEmail(response.data.data)

        }).catch((e) => console.log(e));
      }

      if (calendarDates.length === 0) {
        let newDate = new Date()
        let month_digit = ("0" + (newDate.getMonth() + 1)).slice(-2);
        let year_digit = newDate.getFullYear();
        let data = {
          month_digit,
          year_digit
        }

        custCalendarData(data).then((response) => {
          console.log("custCalendarData", response.data.data.resultData);
          setDateEventList(response.data.data.resultData);
        }).catch((e) => console.log(e));

        custCalendarDateHighlightsData(data).then((response) => {
          console.log("custCalendarDateHighlightsData", response.data.data.resultData);
          let highlDates = [];
          response.data.data.resultData.forEach(element => {
            let tempDate = element.date.split('-');
            let newDate = `${tempDate[1]}-${tempDate[0]}-${tempDate[2]}`;
            // console.log('newDate: ', newDate);
            highlDates.push(newDate)
          });
          console.log('highlDates 2: ', highlDates);
          setCalendarDates(highlDates);
        }).catch((e) => console.log(e));
      }
    }
  }, [userContext])

  console.log("USER CONTEXT DATE",greetingMessage)
  return (
    <div className='container-fluid mt50 padlr50'>
      <div className='row'>
        <div className='col'>
          {/* <h2>{greetingMessage.message !== '' && greetingMessage.message}, {userContext !== null ? userContext.first_name : 'Guest'} </h2> */}
          <h2>Hi! {userContext !== null ? userContext.first_name : 'Guest'} </h2>
          <h4 className='text-secondary'>{greetingMessage.time !== '' && greetingMessage.time}</h4>
        </div>
        {allowJobRequest !== null && allowJobRequest.allow_job_request === "1" &&
        <div className='col-lg-2 col-md-4 text-right'>
          <button type='button' className='btn btn-primary' onClick={props.handleJobRequest}><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#ffffff"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm4 11h-3v3c0 .55-.45 1-1 1s-1-.45-1-1v-3H8c-.55 0-1-.45-1-1s.45-1 1-1h3V8c0-.55.45-1 1-1s1 .45 1 1v3h3c.55 0 1 .45 1 1s-.45 1-1 1z" /></svg>&nbsp;&nbsp;New Job Request</button>
        </div>
        }
      </div>
      <div className='row mt20 justify-content-between'>
        <div className='col-12 col-md-6 col-xl-4' style={{ marginBottom: 10 }}>
          <div className="block grd1">
            <div className='row padlr20'>
              <h3 className="text-primary">Active Task</h3>
              <div className='task'>
                <span className='lightgreencircle'><svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="48px" viewBox="0 0 24 24" width="48px" fill="#66ac49"><rect fill="none" height="24" width="24" /><path d="M22,5.18L10.59,16.6l-4.24-4.24l1.41-1.41l2.83,2.83l10-10L22,5.18z M19.79,10.22C19.92,10.79,20,11.39,20,12 c0,4.42-3.58,8-8,8s-8-3.58-8-8c0-4.42,3.58-8,8-8c1.58,0,3.04,0.46,4.28,1.25l1.44-1.44C16.1,2.67,14.13,2,12,2C6.48,2,2,6.48,2,12 c0,5.52,4.48,10,10,10s10-4.48,10-10c0-1.19-0.22-2.33-0.6-3.39L19.79,10.22z" /></svg></span>
                <div>
                  <h1>{custActiveTask !== null ? custActiveTask.total_task_count : 0}</h1>
                  <p className="text-secondary font1Vew">Task Currently in Process</p>
                </div>
              </div>
            </div>

            <div className="padlr20">
              <ul className="inline-list-bullet">
                <li className="noBullet">
                  <div style={{ width: 60, marginRight: 20 }}>
                    <CircularProgressbar
                      strokeWidth={13}
                      value={custActiveTask !== null ? Math.floor(parseInt(custActiveTask.fte_task_count) / parseInt(custActiveTask.total_task_count) * 100) : 0}
                      text={custActiveTask !== null ? Math.floor(parseInt(custActiveTask.fte_task_count) / parseInt(custActiveTask.total_task_count) * 100) + '%' : '0%'}
                      styles={buildStyles({
                        textColor: "#909090",
                        pathColor: "#72abed",
                        trailColor: "#ededed"
                      })}
                    />
                  </div>
                  <div>FTE <span className="big">{custActiveTask !== null ? custActiveTask.fte_task_count : 0}</span></div>
                </li>
                <li>
                  <div style={{ width: 60, marginRight: 20 }}>
                    <CircularProgressbar
                      strokeWidth={13}
                      value={custActiveTask !== null ? Math.floor(parseInt(custActiveTask.payg_task_count) / parseInt(custActiveTask.total_task_count) * 100) : 0}
                      text={custActiveTask !== null ? Math.floor(parseInt(custActiveTask.payg_task_count) / parseInt(custActiveTask.total_task_count) * 100) + '%' : '0%'}
                      styles={buildStyles({
                        textColor: "#909090",
                        pathColor: "#e372ed",
                        trailColor: "#ededed"
                      })}
                    />
                  </div>
                  <div>
                    PayG <span className="big">{custActiveTask !== null ? custActiveTask.payg_task_count : 0}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='col-12 col-md-6 col-xl-4' style={{ marginBottom: 10 }}>
          <div className=" block grd2 mb10">
            <StatewiseAnalyse />
          </div>
        </div>
        <div className='col-12 col-xl-4' style={{ marginBottom: 10 }}>
          <div className="block mb10">
            <OutStandingBill />
          </div>
        </div>
      </div>
      <div className="row mt40">
        <People />
      </div>
      <div className="row mt20">
        <div className="col-12 col-lg-8 mt10">
          <div className="block padlr50">
            <h3 className="text-primary">Calender</h3>
            {/* <div className="d-flex  justify-content-between align-items-center"> */}
            <div className="row">
              <div className="col-md-6">
                <CalendarView datesData={calendarDates} handleDateChange={(data) => handleDateChange(data)} />
              </div>
              <div className="col">
                <EventsHistory eventList={dateEventList} />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4 mt10">
          <div className="block padlr50">
            <h3 className="text-primary">Recent Activity</h3>
            <RecentActivity />
          </div>
        </div>
      </div>

      <div className="row mt50">
        <div className="col-12 col-md-6 col-lg-4 contactForm mt20">
          <img src="/assets/imgs/support_desk.jpg" alt="Support Desk Image" />
          <h4 className="mt20">Looking for quick Assistance ?</h4>
          {custSupportEmail !== null &&
            custSupportEmail.map((item) =>
              <h3 key={item.id} className="text-primary"> <a href={'mailto:'+item.support_email} style={{color: '#01375d', textDecoration: 'none'}}>{item.support_email}</a> <small className="text-primary">{item.support_name}</small></h3>
            )
          }
        </div>
        <div className="col-12 col-md-6 col-lg-4 mt10">
          <div className="block padlr50">
            <h3 className="text-primary d-flex justify-content-between align-items-center">My People <Link to="/users" className="btn btn-manage">Manage <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v1c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-1c0-2.66-5.33-4-8-4z" /></svg></Link></h3>
            {myPeople !== null ?
              <>
                <ul className="list-view-circle mt50 slim-scroll">
                  {myPeople.length > 0 && myPeople.map(item =>
                    <li key={item}>
                      <div className="d-flex justify-content-start align-items-center">
                        <span>{item.first_name.slice(0, 1)}</span>
                        <h4>{item.first_name + ' ' + item.last_name}<small>{item.user_email}</small></h4>
                      </div>
                      <span>{item.customer_user_type_txt}</span>
                    </li>
                  )}
                </ul>
                {myPeople.length === 0 && <h4 className="text-center mt30">No People Added</h4>}
              </>
              : <h4 className="text-center mt30">Loading ...</h4>}
          </div>
        </div>
        <div className="col-12 col-lg-4 mt10">
          <div className="block padlr50">
            <h3 className="text-primary">Latest Announcement</h3>
            <LatestAnnouncment />
          </div>
        </div>
      </div>
    </div >
  );
}

export default Dashboard;
