import { useContext, useEffect, useState } from "react";
import { checkTokenValidity, MyAccoutDetails } from "../api";
import { UserContext } from "../App";
import { ChangePassword, DialogBox } from "../common/modalView";


function MyAccountDetails() {
  const [selectedTab, setSelectedTab] = useState('My Profile')
  const [myAccount, setMyAccount] = useState(null);
  const [changePassword, setChangePassword] = useState(false);
  const [msgDisplay, setMsgDisplay] = useState('');
  const [profilePic, setProfilePic] = useState('');
  const [userData, setUserData] = useState('');
  const [showDialoge, setShowDialoge] = useState(false);
  const userContext = useContext(UserContext);

  const handleChangePass = () => {
    changePassword ? setChangePassword(false) : setChangePassword(true)
  }

  const handleDialogue = () => {
    showDialoge ? setShowDialoge(false) : setShowDialoge(true)
  }

  // const handleEditUser = (data) => {    
  //   custSingleUserInfo(data).then((response) => {
  //     console.log('custSingleUserInfo : ', response.data.data.resultData)
  //     setEditUser(response.data.data.resultData)
  //     setChangePassword(true);
  //   }).catch((e) => console.log(e))
  // }

  useEffect(() => {
    if (myAccount === null && userContext !== null) {
      MyAccoutDetails().then((response) => {
        if (checkTokenValidity(response.data)) {
          console.log("MyAccoutDetails", response.data.data.resultData);
          setMyAccount(response.data.data.resultData);
        }
      }).catch((e) => console.log(e));
    }

  }, [userContext])

  return (
    <div className='container-fluid mt50 padlr50'>
      <div className='row'>
        <div className='col'>
          <h2 className="text-primary">My Account</h2>
        </div>
        <div className='col-sm-6 text-right'>
          <button type='button' className='btn btn-primary-outline' onClick={() => setChangePassword(true)}>Change Password</button>
        </div>
      </div>
      {myAccount !== null &&
        <div className='row mt50 align-items-center'>
          <div className='col-12 col-md-12 block'>
            <div className='row padlr20'>
              <div className="col-12 col-md-3">
                <div className="mypic">
                  <img src="/assets/imgs/profilepic.jpg" alt="Profile Picture" />
                  <div className="fileuploadBtn" style={{ marginRight: 20 }}>
                    <input type="file" onChange={(e) => setProfilePic(e.target.files)} />
                    <button type="button" className="btn btn-secondary btn-lg"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                  </div>
                  {/* <span>
                    <input type="file" />
                    <button>Edit</button>
                  </span> */}
                </div>
              </div>
              <div className="col-12 col-md-9">
                <div className='row padlr20'>
                  <div className="col-12">
                    <h2 className="text-primary">{myAccount.account_info.name}</h2>
                    <h3 className="text-gray">@{myAccount.account_info.user_name}</h3>
                  </div>
                </div>
                <div className='row padlr20 mt10'>
                  <div className="col-12 col-md-6">
                    <label className="text-gray">Phone Number</label>
                    <h4>{myAccount.account_info.phone}</h4>
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="text-gray">Email Id</label>
                    <h4>{myAccount.account_info.email}</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className='row padlr20 borderTop gray-border mt20'>
              <nav className="profile-nav">
                <li className={selectedTab === 'My Profile' ? 'active' : ''} onClick={() => setSelectedTab('My Profile')}>My Profile</li>
                <li className={selectedTab === 'Assigned Users' ? 'active' : ''} onClick={() => setSelectedTab('Assigned Users')}>Assigned Users</li>
                <li className={selectedTab === 'Service Engagement' ? 'active' : ''} onClick={() => setSelectedTab('Service Engagement')}>Service Engagement</li>
                {/* <li className={selectedTab === 'My Team' ? 'active' : ''} onClick={() => setSelectedTab('My Team')}>My Team</li> */}
              </nav>
            </div>
          </div>
        </div>
      }

      {selectedTab === 'My Profile' &&
        <div className='row mt50'>
          <div className='col-12 col-md-12 block'>
            <div className="row padlr20">
              <div className="col-6">
                <h3 className="text-primary">Profile Details</h3>
              </div>
              {/* <div className='col-sm-6 text-right'>
                <button type='button' className='btn btn-primary'>Edit Profile</button>
              </div> */}
            </div>
            <hr />
            {myAccount !== null &&
              <div className="padlr20">
                <div className="row">
                  <div className="col-12 col-md-2">
                    <label>Name of Business</label>
                  </div>
                  <div className="col-12 col-md-7"><strong>{myAccount.profile_info.name_of_business ? myAccount.profile_info.name_of_business : 'Not Available'}</strong></div>
                </div>
                <div className="row mt20">
                  <div className="col-12 col-md-2">
                    <label>NCS Company</label>
                  </div>
                  <div className="col-12 col-md-7"><strong>{myAccount.profile_info.ncs_company ? myAccount.profile_info.ncs_company : 'Not Available'}</strong></div>
                </div>
                <div className="row mt20">
                  <div className="col-12 col-md-2">
                    <label>Address</label>
                  </div>
                  <div className="col-12 col-md-7"><strong>{myAccount.profile_info.address ? myAccount.profile_info.address : 'Not Available'}</strong></div>
                </div>
                <div className="row mt20">
                  <div className="col-12 col-md-2">
                    <label>Contact No.</label>
                  </div>
                  <div className="col-12 col-md-7"><strong>{myAccount.profile_info.contact_no ? myAccount.profile_info.contact_no : 'Not Available'}</strong></div>
                </div>
                <div className="row mt20">
                  <div className="col-12 col-md-2">
                    <label>Alternative Contact No.</label>
                  </div>
                  <div className="col-12 col-md-7"><strong>{myAccount.profile_info.alt_contact_no ? myAccount.profile_info.alt_contact_no : 'Not Available'}</strong></div>
                </div>
                <div className="row mt20">
                  <div className="col-12 col-md-2">
                    <label>Email</label>
                  </div>
                  <div className="col-12 col-md-7"><strong>{myAccount.profile_info.email ? myAccount.profile_info.email : 'Not Available'}</strong></div>
                </div>
                <div className="row mt20">
                  <div className="col-12 col-md-2">
                    <label>Alternate Email Id</label>
                  </div>
                  <div className="col-12 col-md-7"><strong>{myAccount.profile_info.alt_email ? myAccount.profile_info.alt_email : 'Not Available'}</strong></div>
                </div>
                <div className="row mt20">
                  <div className="col-12 col-md-2">
                    <label>Contact Person</label>
                  </div>
                  <div className="col-12 col-md-7"><strong>{myAccount.profile_info.contact_person ? myAccount.profile_info.contact_person : 'Not Available'}</strong></div>
                </div>
                <div className="row mt20">
                  <div className="col-12 col-md-2">
                    <label>Credit Period</label>
                  </div>
                  <div className="col-12 col-md-7"><strong>{myAccount.profile_info.credit_period ? myAccount.profile_info.credit_period : 'Not Available'}</strong></div>
                </div>
              </div>
            }
          </div>
        </div>
      }
      {selectedTab === 'Assigned Users' &&
        <div className='row mt50'>
          <div className='col-12 col-md-12 block'>
            <div className="row padlr20">
              <div className="col-6">
                <h3 className="text-primary">Assigned Users</h3>
              </div>
              {/* <div className='col-sm-6 text-right'>
                <button type='button' className='btn btn-primary'>Manage</button>
              </div> */}
            </div>
            <hr />
            {myAccount !== null &&
              <div className="row padlr20">
                <div className="col-12 col-md-12">
                  <ul className="list-view-circle mt10 slim-scroll">
                    {myAccount.assigned_team.map((item, index) =>
                      <li key={index}>
                        <div className="d-flex justify-content-start align-items-center">
                          <span>{item.member_name.slice(0, 1)}</span>
                          <h4>{item.member_name} <small>{item.designation_name}</small></h4>
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            }
          </div>
        </div>
      }
      {selectedTab === 'Service Engagement' &&
        <div className='row mt50'>
          <div className='col-12 col-md-12 block'>
            <div className="row padlr20">
              <div className="col-6">
                <h3 className="text-primary">Service Engagement</h3>
              </div>
            </div>
            <hr />
            {myAccount !== null &&
              <div className="row padlr20">
                <div className="col-12 col-md-12">
                  <div className="noborder-table table-response slim-scroll">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Sr. No</th>
                          <th>Service Name</th>
                          <th>Mode</th>
                          <th>Billing Mode</th>
                          <th>Rate</th>
                        </tr>
                      </thead>
                      <tbody>
                        {myAccount.service_enagagment.map((item, index) =>
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.service_name}</td>
                            <td>{item.mode}</td>
                            <td>{item.billing_mode}</td>
                            <td>{item.rate}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      }
      {/* {selectedTab === 'My Team' &&
        <div className='row mt50'>
          <div className='col-12 col-md-12 block'>
            <div className="row padlr20">
              <div className="col-6">
                <h3 className="text-primary">My Team</h3>
              </div>
            </div>
            <hr />
            {myAccount !== null &&
              <div className="row padlr20">
                <div className="col-12 col-md-12">
                  <div className="noborder-table slim-scroll table-response">
                    <table className="table">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Team Name</th>
                          <th>Role</th>
                          <th>Phone</th>
                        </tr>
                      </thead>
                      <tbody>
                        {myAccount.myteam.map((item, index) =>
                          <tr key={index}>
                            <td><span className="thumb">{item.team_name.slice(0, 1)}</span></td>
                            <td>{item.team_name} <br /><small>{item.email}</small></td>
                            <td>{item.role}</td>
                            <td>{item.phone}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      } */}
      {changePassword && <ChangePassword data={userData} handleChangePass={handleChangePass} />}
      {showDialoge && <DialogBox data={msgDisplay} handleDialogue={handleDialogue} />}
    </div>
  );
}

export default MyAccountDetails;