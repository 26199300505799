import { useState, useCallback, useContext, useEffect } from 'react';
import { Calendar } from '@natscale/react-calendar';
import '@natscale/react-calendar/dist/main.css';
import { UserContext } from '../App';
import { checkTokenValidity, custLatestAnnoucement } from '../api';

function LatestAnnouncment() {
    const [userAnnouncement, setUserAnnouncement] = useState(null);
    const userContext = useContext(UserContext);

    useEffect(() => {
        if (userAnnouncement === null && userContext !== null) {
            custLatestAnnoucement().then((response) => {                
                if (checkTokenValidity(response.data)) {
                    console.log("userAnnouncement", response.data.data.resultData);
                    setUserAnnouncement(response.data.data.resultData);
                }
            }).catch((e) => console.log(e));
        }
    }, [userContext])

    return (
        <>
            {userAnnouncement !== null && userAnnouncement.length > 0 ?
                <ul className='announcementlist mt20 slim-scroll'>
                    {userAnnouncement.map((item, index) =>
                        <li key={index}>
                            <div className='d-flex justify-content-between'>
                                <div style={{ width: '100%' }}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h3>{item.subject}</h3>
                                        <span className='text-gray'>{item.date}</span>
                                    </div>
                                    <p>{item.body}</p>
                                </div>
                            </div>
                        </li>
                    )}
                </ul>
                : "No Announcement Available"
            }
        </>
    )
}

export default LatestAnnouncment;
