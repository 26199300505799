import { useState, useEffect, createContext } from "react";
import { Route, Routes, Navigate, BrowserRouter as Router } from "react-router-dom";
import logo from './logo.svg';
import Header from "./common/header";
import Footer from "./common/footer";
import Dashboard from "./home/dashboard";
import Projects from "./projects/projects";
import ProjectMoreInfo from "./projects/projectmoreInfo";
// import ProjectMoreInfo1 from "./jobs-request/jobs_request_more";
import Billings from "./billing/billing";
import JobsRequest from "./jobs-request/jobs-request";
import Users from "./users/users";
import Inbox from "./inbox/inbox";
import ModalView from "./common/modalView";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { login } from "./api";
import LoginPage from "./login";
import MyAccountDetails from "./myaccount/myaccount";
import PageNotFound from "./common/notFound";
import DocumentsView from "./common/documentsView";
// import JobsDocument from "./jobs-request/jobsDocument";

export const UserContext = createContext(null);

function App() {
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem('userInfo')));
  const [isLoggedIn, setIsLoggedIn] = useState((sessionStorage.getItem('userInfo')));
  const [showJobReq, setShowJobReq] = useState(false);
  const [showJobReq2, setShowJobReq2] = useState(false);
  // const [showDocReq, setShowDocReq] = useState(false);   //added by me

  const [isLoginError, setIsLoginError] = useState(null);

  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'));

  const handleJobRequest = () => {
    showJobReq ? setShowJobReq(false) : setShowJobReq(true)
    // setShowJobReq(true);
  setShowJobReq2(false);
  }
  const handleJobRequest2 = () => {
    showJobReq2 ? setShowJobReq2(false) : setShowJobReq2(true)
    // setShowJobReq2(true);
  setShowJobReq(false);
  }

  const doLogin = (data) => {
    login(data).then((response) => {
      console.log('response from app.js: ', response)
      if (response.request_status === 200) {
        sessionStorage.setItem('userInfo', JSON.stringify(response.data))
        setUser(response.data)
        setIsLoggedIn(true)
        const pathToGo = response.data.menu_access[0].menu;
        console.log('pathToGo: ', pathToGo)
        return <Navigate replace to={pathToGo === 'Dashboard' ? "/home" : pathToGo === 'Projects' ? "/projects" : pathToGo === 'Billing' ? "/billing" : pathToGo === 'Job Request' ? "/job-request" : pathToGo === 'Users' ? "/users" : pathToGo === 'Inbox' && "/inbox"} />     
        //window.location = '/home';
      } else {
        setIsLoginError(response.message)
        setUser(null)
        setIsLoggedIn(false)
        sessionStorage.removeItem('userInfo')
      }
    }).catch((e) => console.log(e))

  }

  const doLogout = () => {
    sessionStorage.removeItem('userInfo');    
    return window.location = '/'
    
  }

  console.log('sessionStorage isLogged: ', isLoggedIn)    

  return (
    // <Router basename="/dashboard">
    <Router>
      <UserContext.Provider value={user}>
        {isLoggedIn ?
          <>
            <Header doLogout={doLogout} />
            <div className="marginTop">
              {userInformation !== null && userInformation.menu_access.length > 0 ?
                <Routes>
                  <Route exact path="/" element={<Navigate replace to={userInformation.menu_access[0].menu === 'Dashboard' ? "/home" : userInformation.menu_access[0].menu === 'Projects' ? "/projects" : userInformation.menu_access[0].menu === 'Billing' ? "/billing" : userInformation.menu_access[0].menu === 'Job Request' ? "/job-request"  : userInformation.menu_access[0].menu === 'Users' ? "/users" : userInformation.menu_access[0].menu === 'Job Document' ? "/jobsDocument" : userInformation.menu_access[0].menu === 'Inbox' && "/inbox" } />} />}
                  {userInformation.menu_access.findIndex((x) => x.menu === 'Dashboard') !== -1 && <Route exact path="/home" element={<Dashboard handleJobRequest={handleJobRequest} />} />}
                  {userInformation.menu_access.findIndex((x) => x.menu === 'Projects') !== -1 && <Route exact path="/projects" element={<Projects handleJobRequest={handleJobRequest} />} />}
                  {userInformation.menu_access.findIndex((x) => x.menu === 'Projects') !== -1 && <Route path="/projects/:id" element={<ProjectMoreInfo />} />}
                  {userInformation.menu_access.findIndex((x) => x.menu === 'Billing') !== -1 && <Route path="/billing" element={<Billings />} />}
                  {userInformation.menu_access.findIndex((x) => x.menu === 'Job Request') !== -1 && <Route path="/job-request" element={<JobsRequest handleJobRequest={handleJobRequest} />} />}
                  {/* {userInformation.menu_access.findIndex((x) => x.menu === 'Job Document') !== -1 && <Route path="/jobsDocument" element={<DocumentsView handleJobRequest2={handleJobRequest2}/>} />} */}
                  {userInformation.menu_access.findIndex((x) => x.menu === 'Job Request') !== -1 && <Route path="/job-request/:id" element={<DocumentsView/>} />}
                  {/* {userInformation.menu_access.findIndex((x) => x.menu === 'Job Request') !== -1 && <Route path="/job-request/:id" element={<DocumentsView />} />} */}
                  {userInformation.menu_access.findIndex((x) => x.menu === 'Users') !== -1 && <Route path="/users" element={<Users />} />}
                  {userInformation.menu_access.findIndex((x) => x.menu === 'Inbox') !== -1 && <Route path="/inbox" element={<Inbox />} />}
                  <Route path="/myaccount" element={<MyAccountDetails />} />
                  <Route path="/login" element={<Navigate replace to="/" />} />
                  <Route path="*" element={<PageNotFound />} />
                  {/* <Route path="/job-details/:jobId" element={<JobDetailsPage />} /> */}

                </Routes>
                : ''}
            </div>
            {showJobReq && <ModalView handleJobRequest={handleJobRequest} />}
            {/* {showJobReq && <DocumentsView handleJobRequest2={handleJobRequest2} />}  */}
            
            
          </>
          :
          <>
            <Header />
            <div className="marginTop">
              <Routes>
                <Route path="/login" element={<LoginPage doLogin={doLogin} isLoginError={isLoginError} />} />
                <Route path="*" element={<Navigate replace to="/login" />} />
              </Routes>
            </div>
          </>
        }
      </UserContext.Provider>
      <Footer />
    </Router>
  )
}

export default App;
