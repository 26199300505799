import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { useState, useContext, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { custBillList, custBillingStatementLedgerFiles, custBillingSummary, custBillDownload, custBillDataDownload, checkTokenValidity, customerUnpaidBill } from '../api';
import { UserContext } from '../App';
import '../App.css';

function Billings() {
  const [balance, setBalance] = useState(25);
  const [statementLedger, setStatementLedger] = useState(null);
  const [billingSummary, setBillingSummary] = useState(null);
  const [billType, setBillType] = useState('Unpaid');
  const [viewType, setViewType] = useState('grid');
  const [billList, setBillList] = useState(null);
  const [unbillData, setUnbillData] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(8)
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [items, setItems] = useState([]);
  const [originalData, setOriginalData] = useState([])
  const [searchValue, setSearchValue] = useState('');
  const [isSearchFilter, setIsSearchFilter] = useState(false);
  const [searchArray, setSearchArray] = useState([])
  const [filterShow, setFilterShow] = useState(false);
  const [filterValue, setFilterValue] = useState({
    from_date: '',
    to_date: ''
  })
  const userContext = useContext(UserContext);

  const handleFilter = () => {
    filterShow ? setFilterShow(false) : setFilterShow(true)
  }

  const handleSearchFilter = () => {
    setFilterShow(false);
    console.log('handleSearchFilter: ', filterValue);
    custBillList(filterValue).then((response) => {
      //console.log('handleSearchFilter fetch response: ', response.data.data.resultData)
      setIsSearchFilter(true);
      setSearchArray(response.data.data.resultData)
    })
  }

  const downloadCustBill = (data) => {
    let file = ''
    const link = document.createElement('a');
    // Append to html link element page
    document.body.appendChild(link);
    custBillDownload(data).then((response) => {
      //console.log(response);
      file = response.data.data.file;
      link.href = response.data.data.file;
      link.setAttribute('download', `${file}`);
      link.setAttribute('target', '_blank');
      link.click();
    }).catch((e) => console.log(e));
    link.parentNode.removeChild(link);
  }

  const downloadCustBillData = (data) => {
    let file = ''
    const link = document.createElement('a');
    // Append to html link element page
    document.body.appendChild(link);
    custBillDataDownload(data).then((response) => {
      //console.log(response);
      file = response.data.data.file;
      link.href = response.data.data.file;
      link.setAttribute('download', `${file}`);
      link.setAttribute('target', '_blank');
      link.click();
    }).catch((e) => console.log(e));
    link.parentNode.removeChild(link);
  }

  useEffect(() => {
    if (billList === null && userContext !== null) {
      let tempData = userContext;
      //console.log('userContext: ', tempData);
      custBillList(filterValue).then((response) => {
        //console.log("custAllTaskList", response.data.data.resultData);
        if (checkTokenValidity(response.data)) {
          setBillList(response.data.data.resultData);
          setPageCount(response.data.data.resultData.length / itemsPerPage);
          setItems(response.data.data.resultData.filter((temp) => temp.bill_status === billType).slice(0, itemsPerPage));
          custBillingStatementLedgerFiles().then((response) => {
            console.log('Cust Download Statement and Ledger', response.data.data.resultData)
            setStatementLedger(response.data.data.resultData)
          }).catch((e) => console.log(e))
          customerUnpaidBill().then((response) => {
            console.log('customerUnpaidBill: ', response.data.data.resultData)
            setUnbillData(response.data.data.resultData)
          }).catch((e) => console.log(e))
          custBillingSummary().then((response) => {
            console.log('customer billing summary: ', response.data.data.resultData);
            setBillingSummary(response.data.data.resultData);
          })
        }
      }).catch((e) => console.log(e));
    }
  }, [userContext])

  const handleItemList = (selectedPage) => {
    const tempData = billList
    console.log("tempData",tempData)
    if (tempData !== null) {
      const dataShow = tempData.slice((itemsPerPage * selectedPage) - itemsPerPage, itemsPerPage * selectedPage);
      setItems(dataShow)
    }
  }

  const handleSearch = (event) => {
    const tempData = billList;
    setSearchValue(event.target.value);
    event.target.value !== '' ? setIsSearchFilter(true) : setIsSearchFilter(false);
    let searchArrayData = tempData.filter((item) => item.bill_no.toUpperCase().includes(event.target.value.toUpperCase()) || item.bill_status.toUpperCase().includes(event.target.value.toUpperCase()) || item.bill_date.toUpperCase().includes(event.target.value.toUpperCase()) || item.billing_mode.toUpperCase().includes(event.target.value.toUpperCase()));
    setSearchArray(searchArrayData);
    console.log('searchArray: ', searchArray);
    console.log('handle search: ', event.target.value);
  }

  const handlePageClick = (event) => {
    console.log(event);
    const selectedNumber = event.selected + 1;
    console.log("selectedNumber", selectedNumber)
    handleItemList(selectedNumber);
  };

  const handlebillType = (type) => {    
    setBillType(type)
    const temp = billList;
    const filterData = type !== '' ? temp.filter((data) => data.bill_status === type) : temp;    
    setItems(filterData.slice(0, itemsPerPage));
  }

  console.log("items.length", items.length)
  console.log("itemsPerPage", itemsPerPage)
  console.log("custBillList", billList)

  return (
    <div className='container-fluid mt50 padlr50'>
      <div className='row'>
        <div className='col col-md-4 col-lg-8'>
          <h2 className="text-primary">Billing</h2>
        </div>
        <div className='col-lg-4 col-md-8 col-12 col-lg-4 text-right inline-btn'>
          {/* <a href={statementLedger !== null ? statementLedger.statement : ''} target="_blank" className='btn btn-primary-outline mr20' download>Download Statement</a> */}
          <a href={statementLedger !== null ? statementLedger.ledgerfile : ''} target="_blank" className='btn btn-primary-outline' download>Download Ledger</a>
        </div>
      </div>

      <div className='row mt50'>
        <div className='col-md-12'>
          <div className='row padlr20 align-items-center'>
            <div className='col-12 col-md-5 col-lg-5 col-xl-4 d-flex justify-conter-between align-items-center'>
              <div className='row align-items-center' style={{ width: '100%' }}>
                <div className='col-12 col-md-12 col-lg-7 col-xl-5 mt10' style={{ maxWidth: "286px", width: 100, minWidth: "250px" }}>
                  <CircularProgressbarWithChildren value={unbillData !== null ? Math.floor(unbillData.payment_overdue / unbillData.total_remaining_balance * 100) : 0} strokeWidth={5} styles={buildStyles({
                    textColor: "#909090",
                    pathColor: "#ff0000",
                    trailColor: "#ededed"
                  })}>
                    {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                    <small style={{ fontSize: 14, color: '#a8a8a8' }}>Remaining Balance</small>
                    <div style={{ fontSize: 24, marginTop: 2, color: '#000000' }}>
                      <strong>${unbillData !== null && unbillData.total_remaining_balance}</strong>
                    </div>
                  </CircularProgressbarWithChildren>
                </div>
                <div className='mt10 text-center-md col-lg-5 col-xl-5'>
                  <p className="text-secondary">Payment Amount Due</p>
                  <h2>${unbillData !== null && unbillData.payment_overdue}</h2>
                  <p className="text-secondary">{unbillData !== null && unbillData.payment_overdue_date}</p>
                  {/* <p><svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#f88d6c"><path d="M4.47 21h15.06c1.54 0 2.5-1.67 1.73-3L13.73 4.99c-.77-1.33-2.69-1.33-3.46 0L2.74 18c-.77 1.33.19 3 1.73 3zM12 14c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1s1 .45 1 1v2c0 .55-.45 1-1 1zm1 4h-2v-2h2v2z" /></svg>&nbsp;Past Due</p> */}
                  {/* <button type="button" className="btn btn-primary mt20">Pay</button> */}
                </div>
              </div>
            </div>
            {/* 
            <div className='col-12 col-md-7 col-lg-5 col-xl-8'>
              <ul className='paymentdescrlist'>
                <li className='grosbill'>
                  <h3$ {billingSummary !== null && billingSummary.gross_bill_amount}</h3>
                  <p className='text-secondary'>Gross Bill Amount</p>
                </li>
                <li className='newbill'>
                  <h3>$ {billingSummary !== null && billingSummary.net_bill_amount}</h3>
                  <p className='text-secondary'>Net Bill Amount</p>
                </li>
                <li className='lastpayment'>
                  <h3>$ {billingSummary !== null && billingSummary.last_payment_made}</h3>
                  <p className='text-secondary'>Last Payment made</p>
                </li>
                <li className='taxAmt'>
                  <h3>$ {billingSummary !== null && billingSummary.tax_amount}</h3>
                  <p className='text-secondary'>Tax Amount</p>
                </li>
                <li className='amtRec'>
                  <h3>$ {billingSummary !== null && billingSummary.amount_received}</h3>
                  <p className='text-secondary'>Amount Received</p>
                </li>
                <li className='lastPaymentDate'>
                  <h3>{billingSummary !== null && billingSummary.last_payment_date}</h3>
                  <p className='text-secondary'>Last Payment Date</p>
                </li>
              </ul>
            </div> 
            */}
          </div>
        </div>
      </div>

      <div className='row justify-content-between align-items-center mt30'>
        <div className='col-12 col-md-4 col-xl-5'>
          <h2 className="text-primary">All Bills</h2>
        </div>
        <div className='row col-12 col-md-10 col-lg-12 col-xl-7 text-right d-flex searchfilter justify-content-end'>
          <div className='mob-hidden col-12 col-md-6 col-lg-7 col-xl-4' style={{ position: 'relative' }}>
            <input type="text" className='form-control' value={searchValue} placeholder='Search' onChange={handleSearch} />
            <span className='searchicon2'></span>
          </div>
          <div className="mob-hidden hidden-md d-flex align-items-center col-xl-3">
            <ul className="gridlisticon">
              <li className={billType === 'Unpaid' ? 'active' : ''} onClick={() => handlebillType('Unpaid')} >Unpaid</li>
              <li className={billType === 'Paid' ? 'active' : ''} onClick={() => handlebillType('Paid')} >Paid</li>
              <li className={billType === '' ? 'active' : ''} onClick={() => handlebillType('')}>All</li>
            </ul>
          </div>
          <div className='col-12 col-md-6 col-lg-3  col-xl-4 d-flex justify-content-between align-items-center max-width290'>
            {/* &nbsp;View as: */}
            <ul>
              <li className={viewType === 'grid' ? 'active' : ''} onClick={() => setViewType('grid')}><svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24" /></g><g><g><path d="M5,11h4c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2H5C3.9,3,3,3.9,3,5v4C3,10.1,3.9,11,5,11z" /><path d="M5,21h4c1.1,0,2-0.9,2-2v-4c0-1.1-0.9-2-2-2H5c-1.1,0-2,0.9-2,2v4C3,20.1,3.9,21,5,21z" /><path d="M13,5v4c0,1.1,0.9,2,2,2h4c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2h-4C13.9,3,13,3.9,13,5z" /><path d="M15,21h4c1.1,0,2-0.9,2-2v-4c0-1.1-0.9-2-2-2h-4c-1.1,0-2,0.9-2,2v4C13,20.1,13.9,21,15,21z" /></g></g></svg></li>
              <li className={viewType === 'list' ? 'active' : ''} onClick={() => setViewType('list')}><svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><rect fill="none" height="24" width="24" /><path d="M4,14h2c0.55,0,1-0.45,1-1v-2c0-0.55-0.45-1-1-1H4c-0.55,0-1,0.45-1,1v2C3,13.55,3.45,14,4,14z M4,19h2c0.55,0,1-0.45,1-1 v-2c0-0.55-0.45-1-1-1H4c-0.55,0-1,0.45-1,1v2C3,18.55,3.45,19,4,19z M4,9h2c0.55,0,1-0.45,1-1V6c0-0.55-0.45-1-1-1H4 C3.45,5,3,5.45,3,6v2C3,8.55,3.45,9,4,9z M9,14h11c0.55,0,1-0.45,1-1v-2c0-0.55-0.45-1-1-1H9c-0.55,0-1,0.45-1,1v2 C8,13.55,8.45,14,9,14z M9,19h11c0.55,0,1-0.45,1-1v-2c0-0.55-0.45-1-1-1H9c-0.55,0-1,0.45-1,1v2C8,18.55,8.45,19,9,19z M8,6v2 c0,0.55,0.45,1,1,1h11c0.55,0,1-0.45,1-1V6c0-0.55-0.45-1-1-1H9C8.45,5,8,5.45,8,6z" /></svg></li>
            </ul>
            <div style={{ position: "relative", textAlign: "left" }}>
              <span className='filter' onClick={() => handleFilter()}><svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><path d="M0,0h24 M24,24H0" fill="none" /><path d="M7,6h10l-5.01,6.3L7,6z M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6 c0,0,3.72-4.8,5.74-7.39C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z" /><path d="M0,0h24v24H0V0z" fill="none" /></g></svg> Filter</span>
              {filterShow &&
                <div className="filterOptions">
                  <div className="d-flex justify-content-between align-items-center">
                    <h4>Filter</h4>
                    <span style={{ cursor: 'pointer', display: 'inline-block', width: '25px', height: '25px', lineHeight: '25px', textAlign: 'center', background: '#ddd', borderRadius: '100px' }} onClick={() => handleFilter()}>x</span>
                  </div>
                  <label>From Date</label>
                  <input type="date" name="from_date" className='form-control' value={filterValue.from_date} onChange={(e) => setFilterValue({ ...filterValue, [e.target.name]: e.target.value })} />
                  <label className="mt10">To Date</label>
                  <input type="date" name="to_date" className='form-control' value={filterValue.to_date} onChange={(e) => setFilterValue({ ...filterValue, [e.target.name]: e.target.value })} />
                  <div className="text-right mt10">
                    <button type="button" className="btn btn-primary" onClick={() => handleSearchFilter()}>Search</button>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className='row mt20' id='bills-list'>
        {billList !== null && !isSearchFilter ?
          viewType === 'grid' ?
            <ul className='grid'>
              {items.map((item, index) =>
                <li key={index}>
                  <div className='d-flex justify-content-between'>
                    <div>
                      <h3>{item.bill_no}</h3>
                      <span className='text-blue'><strong>{item.billing_mode}</strong> <button type='button' className='plain-btn text-blue' onClick={() => downloadCustBill(item.billing_id)}><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none" /><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" /></svg></button>
                        {/* <button type='button' className='plain-btn text-blue' onClick={() => downloadCustBillData(item.bill_no)}><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M8 16h8v2H8zm0-4h8v2H8zm6-10H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11z" /></svg></button> */}
                      </span>
                    </div>
                    <div >
                      <span className={item.bill_status !== 'Paid' ? 'warning-icon payment-status' : 'paid-icon payment-status'} style={{ fontSize: 20 }}>{item.bill_status}</span>
                    </div>
                  </div>
                  <div className='row mt30'>
                    <div className='col'>
                      <small>Bill Date</small>
                      <h3>{item.bill_date}</h3>
                    </div>
                    <div className='col'>
                      <small>Due Date</small>
                      <h3>{item.bill_due_date}</h3>
                    </div>
                  </div>
                  <div className='row mt20'>
                    <div className='col'>
                      <small>Bill Amount</small>
                      <h3>${item.net_amount}</h3>
                    </div>
                    <div className='col'>
                      <small>Paid Amount</small>
                      <h3>${item.amount_received !== null ? item.amount_received : '0'}</h3>
                    </div>
                  </div>
                  <hr />
                  <div className='d-flex justify-content-between align-items-center mt20'>
                    <div className='col'>
                      <small>Amount Balance</small>
                      {item.balance !== '0.00' ?
                        <h3>${item.balance < 0 ? parseFloat(item.balance) * -1 : item.balance}</h3>
                        :
                        <h3 className='no-dues'>No Dues</h3>
                      }
                    </div>
                    {/* <div style={{ visibility: item.balance !== '0.00' ? 'visible' : 'hidden' }}>
                      <button className='btn btn-primary'>Pay Now</button>
                    </div> */}
                  </div>
                  {/* <p className='mt20 text-pink' style={{ fontSize: 18, fontWeight: 500, visibility: item.balance !== '0.00' ? 'visible' : 'hidden' }}><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#dc5050"><path d="M4.47 21h15.06c1.54 0 2.5-1.67 1.73-3L13.73 4.99c-.77-1.33-2.69-1.33-3.46 0L2.74 18c-.77 1.33.19 3 1.73 3zM12 14c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1s1 .45 1 1v2c0 .55-.45 1-1 1zm1 4h-2v-2h2v2z" /></svg> Raise a Dispute</p> */}

                </li>
              )}
            </ul>
            :
            <ul className='listView'>
              {items.map((item, index) =>
                <li key={index}>
                  <div className='row'>
                    <div className='col'>
                      <h3>{item.bill_no}</h3>
                      <span className='text-blue'><strong>{item.billing_mode}</strong> <button type='button' className='plain-btn text-blue' onClick={() => downloadCustBill(item.billing_id)}><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none" /><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" /></svg></button>
                        {/* <button type='button' className='plain-btn text-blue'><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M8 16h8v2H8zm0-4h8v2H8zm6-10H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11z" /></svg></button> */}
                      </span>
                    </div>
                    <div className='col' style={{ marginTop: 20 }}>
                      <span className={item.bill_status !== 'Paid' ? 'warning-icon payment-status' : 'paid-icon payment-status'} style={{ fontSize: 24 }}>{item.bill_status}</span>
                    </div>
                  </div>
                  <div className='col'>
                    <small>Bill Date</small>
                    <h3>{item.bill_date}</h3>
                  </div>
                  <div className='col'>
                    <small>Due Date</small>
                    <h3>{item.bill_due_date}</h3>
                  </div>

                  <div className='col'>
                    <small>Bill Amount</small>
                    <h3>${item.net_amount}</h3>
                  </div>
                  <div className='col'>
                    <small>Paid Amount</small>
                    <h3>${item.amount_received !== null ? item.amount_received : '0'}</h3>
                  </div>

                  <div className='col'>
                    <small>Amount Balance</small>
                    {item.balance !== '0.00' ?
                      <h3>${item.balance < 0 ? parseFloat(item.balance) * -1 : item.balance}</h3>
                      :
                      <h3 className='no-dues'>No Dues</h3>
                    }
                  </div>
                  {/* <div className='col' style={{ visibility: item.balance !== '0.00' ? 'visible' : 'hidden' }}>
                    <button className='btn btn-primary'>Pay Now</button>
                  </div> */}

                  {/* <p className='col mt20 text-pink' style={{ fontSize: 18, fontWeight: 500, visibility: item.balance !== '0.00' ? 'visible' : 'hidden' }}><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#dc5050"><path d="M4.47 21h15.06c1.54 0 2.5-1.67 1.73-3L13.73 4.99c-.77-1.33-2.69-1.33-3.46 0L2.74 18c-.77 1.33.19 3 1.73 3zM12 14c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1s1 .45 1 1v2c0 .55-.45 1-1 1zm1 4h-2v-2h2v2z" /></svg> Raise a Dispute</p> */}

                </li>
              )}
            </ul>
          :
          isSearchFilter && searchArray.length > 0 ?
            viewType === 'grid' ?
              <ul className='grid'>
                {searchArray.map((item, index) =>
                  <li key={index}>
                    <div className='d-flex justify-content-between'>
                      <div>
                        <h3>{item.bill_no}</h3>
                        <span className='text-blue'><strong>{item.billing_mode}</strong> <button type='button' className='plain-btn text-blue' onClick={() => downloadCustBill(item.billing_id)}><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none" /><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" /></svg></button>
                          {/* <button type='button' className='plain-btn text-blue'><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M8 16h8v2H8zm0-4h8v2H8zm6-10H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11z" /></svg></button> */}
                        </span>
                      </div>
                      <div>
                        <span className={item.bill_status !== 'Paid' ? 'warning-icon payment-status' : 'paid-icon payment-status'} style={{ fontSize: 24 }}>{item.bill_status}</span>
                      </div>
                    </div>
                    <div className='row mt30'>
                      <div className='col'>
                        <small>Bill Date</small>
                        <h3>{item.bill_date}</h3>
                      </div>
                      <div className='col'>
                        <small>Due Date</small>
                        <h3>{item.bill_due_date}</h3>
                      </div>
                    </div>
                    <div className='row mt20'>
                      <div className='col'>
                        <small>Bill Amount</small>
                        <h3>${item.net_amount}</h3>
                      </div>
                      <div className='col'>
                        <small>Paid Amount</small>
                        <h3>${item.amount_received !== null ? item.amount_received : '0'}</h3>
                      </div>
                    </div>
                    <hr />
                    <div className='d-flex justify-content-between align-items-center mt20'>
                      <div className='col'>
                        <small>Amount Balance</small>
                        {item.balance !== '0.00' ?
                          <h3>${item.balance < 0 ? parseFloat(item.balance) * -1 : item.balance}</h3>
                          :
                          <h3 className='no-dues'>No Dues</h3>
                        }
                      </div>
                      {/* <div style={{ visibility: item.balance !== '0.00' ? 'visible' : 'hidden' }}>
                        <button className='btn btn-primary'>Pay Now</button>
                      </div> */}
                    </div>
                    {/* <p className='mt20 text-pink' style={{ fontSize: 18, fontWeight: 500, visibility: item.balance !== '0.00' ? 'visible' : 'hidden' }}><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#dc5050"><path d="M4.47 21h15.06c1.54 0 2.5-1.67 1.73-3L13.73 4.99c-.77-1.33-2.69-1.33-3.46 0L2.74 18c-.77 1.33.19 3 1.73 3zM12 14c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1s1 .45 1 1v2c0 .55-.45 1-1 1zm1 4h-2v-2h2v2z" /></svg> Raise a Dispute</p> */}

                  </li>
                )}
              </ul>
              :
              <ul className='listView'>
                {searchArray.map((item, index) =>
                  <li key={index}>
                    <div className='row'>
                      <div className='col'>
                        <h3>{item.bill_no}</h3>
                        <span className='text-blue'><strong>{item.billing_mode}</strong> <button type='button' className='plain-btn text-blue' onClick={() => downloadCustBill(item.billing_id)}><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none" /><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" /></svg></button>
                          {/* <button type='button' className='plain-btn text-blue'><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M8 16h8v2H8zm0-4h8v2H8zm6-10H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11z" /></svg></button> */}
                        </span>
                      </div>
                      <div className='col'>
                        <span className={item.bill_status !== 'Paid' ? 'warning-icon payment-status' : 'paid-icon payment-status'} style={{ fontSize: 24 }}>{item.bill_status}</span>
                      </div>
                    </div>
                    <div className='col'>
                      <small>Bill Date</small>
                      <h3>{item.bill_date}</h3>
                    </div>
                    <div className='col'>
                      <small>Due Date</small>
                      <h3>{item.bill_due_date}</h3>
                    </div>

                    <div className='col'>
                      <small>Bill Amount</small>
                      <h3>${item.net_amount}</h3>
                    </div>
                    <div className='col'>
                      <small>Paid Amount</small>
                      <h3>${item.amount_received !== null ? item.amount_received : '0'}</h3>
                    </div>

                    <div className='col'>
                      <small>Amount Balance</small>
                      {item.balance !== '0.00' ?
                        <h3>${item.balance < 0 ? parseFloat(item.balance) * -1 : item.balance}</h3>
                        :
                        <h3 className='no-dues'>No Dues</h3>
                      }
                    </div>
                    {/* <div className='col' style={{ visibility: item.balance !== '0.00' ? 'visible' : 'hidden' }}>
                      <button className='btn btn-primary'>Pay Now</button>
                    </div> */}

                    {/* <p className='col mt20 text-pink' style={{ fontSize: 18, fontWeight: 500, visibility: item.balance !== '0.00' ? 'visible' : 'hidden' }}><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#dc5050"><path d="M4.47 21h15.06c1.54 0 2.5-1.67 1.73-3L13.73 4.99c-.77-1.33-2.69-1.33-3.46 0L2.74 18c-.77 1.33.19 3 1.73 3zM12 14c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1s1 .45 1 1v2c0 .55-.45 1-1 1zm1 4h-2v-2h2v2z" /></svg> Raise a Dispute</p> */}

                  </li>
                )}
              </ul>
            // : "No Data Available"
           : <p className='text-center mt20'>No Data Available</p>
        }
      </div> 
      {!isSearchFilter &&   billList?.length > itemsPerPage  &&
        <div className="mt20">
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next Page"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={Math.ceil(pageCount)}
            previousLabel="Previous Page"
            renderOnZeroPageCount={null}
            containerClassName="pagination justify-content-end"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            activeClassName="active"
            previousClassName="page-item"
            previousLinkClassName="page-link actbtn"
            nextClassName="page-item"
            nextLinkClassName="page-link actbtn"
            disabledClassName="disabled"
            disabledLinkClassName="disabled"
          />
        </div>
      }
    </div>
  );
}

export default Billings;
