import { useState, useEffect, useContext } from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { UserContext } from '../App';
import { checkTokenValidity, customerUnpaidBill } from "../api";

function OutStandingBill() {
    const [balance, setBalance] = useState(25);
    const [custUnpaidData, setCustUnpaidData] = useState(null);
    const userContext = useContext(UserContext);

    useEffect(() => {
        if (custUnpaidData === null && userContext !== null) {
            let tempData = userContext.data;
            customerUnpaidBill(tempData).then((response) => {
                if (checkTokenValidity(response.data)) {
                    console.log("custUnpaidData", response.data.data.resultData);
                    setCustUnpaidData(response.data.data.resultData);
                } 
            }).catch((e) => console.log(e));
        }
    }, [userContext])

    return (
        <>
            <div className='d-flex justify-content-between padlr20'>
                <h3 className="text-primary">Outstanding Bill(s)</h3>
                <p className="d-flex align-items-center text-warning"><svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#f88d6c"><path d="M4.47 21h15.06c1.54 0 2.5-1.67 1.73-3L13.73 4.99c-.77-1.33-2.69-1.33-3.46 0L2.74 18c-.77 1.33.19 3 1.73 3zM12 14c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1s1 .45 1 1v2c0 .55-.45 1-1 1zm1 4h-2v-2h2v2z" /></svg>&nbsp;Past Due</p>
            </div>
            <div className="row justify-content-between align-items-center padlr20 mt20 mob-center">
                <div className='col-12 col-md-6 col-xl-6 mob-align-center' style={{ width: 150, marginRight: 0, marginBottom: 10 }}>
                    <CircularProgressbarWithChildren value={custUnpaidData !== null ? parseInt(custUnpaidData.total_remaining_balance) / parseInt(custUnpaidData.payment_overdue) * 100 : 0} strokeWidth={3} styles={buildStyles({
                        textColor: "#909090",
                        pathColor: "#ff0000",
                        trailColor: "#ededed"
                    })}>
                        {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                        <small style={{ fontSize: 11, color: '#a8a8a8' }}>Remaining Balance</small>
                        <div style={{ fontSize: 18, marginTop: 2, color: '#000000' }}>
                            <strong>${custUnpaidData !== null ? custUnpaidData.total_remaining_balance : ''}</strong>
                        </div>
                    </CircularProgressbarWithChildren>
                </div>
                <div className='col-12 col-md-6 col-xl-6 nopadleft'>
                    <p className="text-secondary font1Vew">Payment Amount Due</p>
                    <h3>${custUnpaidData !== null ? custUnpaidData.payment_overdue : ''}</h3>
                    <p className="text-secondary font1Vew">{custUnpaidData !== null ? custUnpaidData.payment_overdue_date : ''}</p>
                </div>
                {/* <button type="button" className="green-btn col-12 col-md-3 col-xl-3 mt10" style={{ fontSize: '14px' }}>Make a Payment</button> */}
            </div>
            <div className="padlr20 mt20">
                <div className="d-flex justify-content-between align-items-center borderTop padt10">
                    <div>
                        <small className="text-secondary">Last Payment made</small>
                        <h3>{custUnpaidData !== null ? custUnpaidData.last_payment_amount : ''}</h3>
                    </div>
                    <div>
                        <small className="text-secondary">Last Payment Date</small>
                        <h3>{custUnpaidData !== null ? custUnpaidData.last_payment_date : ''}</h3>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OutStandingBill;
