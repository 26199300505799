

function Footer() {
  return (
    <footer className="padlr50">
        <ul>
            <li>{new Date(). getFullYear()}</li>
            {/* Footer date year Changed, to update the year automatically on 31 oct 2023  */}
            <li>Powered by ERPCA</li>
        </ul>
    </footer>
  );
}

export default Footer;