import { useState, useEffect, useContext,useRef  } from "react";
import { changePasswordAPI, checkTokenValidity, createJobRequestAPI, createUserAPI, custJobPriority, updateSingleUserAPI } from '../api';
import { UserContext } from '../App';
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

function ModalView(props) {
    const [jobPriority, setJobPriority] = useState(null);
    const [formData, setFormData] = useState({
        jobTitle: '',
        jobType: '',
        job_priority: '',
        description: '',
        year: '',
        filename: '',
        fileSize: '',
        files: []
    });
    const [fileList, setFileList] = useState([]);
    const [shoDialog, setShoDialog] = useState(false);
    const [msgDisplay, setMsgDisplay] = useState('');
    const navigate = useNavigate();
    const filesformData = new FormData();

    const [hovered, setHovered] = useState(false)
    const hoverAlertRef = useRef(null);

  const hoverAlert = () => {
    hoverAlertRef.current = Swal.fire({
      icon: 'info',
      title: 'Note:',
      text: 'Special characters like  % # \' / & ^ () etc are not allowed in file name.',
      // showConfirmButton: false,
    });

    // Manually close the alert on mouse leave
    document.addEventListener('mouseleave', handleCloseAlert);
  };

  const handleCloseAlert = () => {
    if (hoverAlertRef.current) {
      hoverAlertRef.current.close();
      document.removeEventListener('mouseleave', handleCloseAlert);
    }
  };


    const handleDialogue = () => {
        setFormData({ jobTitle: '', jobType: '', job_priority: '', description: '', year: '', files: [], filename: '' })
        setFileList([])
        shoDialog ? setShoDialog(false) : setShoDialog(true);
    }

    const userContext = useContext(UserContext);
    console.log('userContext from Modal: ', userContext)

    const handleFileUpload = () => {
        if (formData.files[0] !== undefined) {            
            //setFormData({ ...formData, filename: formData.files[0].target.files[0].name, fileSize: formData.files[0].target.files[0].size })
            //console.log('filesformData: ', filesformData.values)
            //console.log('before setting filelist: ', formData.files[0].target.files)
            let tempFileName = [];
            let files = formData.files[0].target.files
            for (let i = 0; i < files.length; i++) {
                tempFileName.push({name: files[i].name, size: files[i].size})
            }
            //console.log('tempFileName: ', tempFileName)
            setFileList(tempFileName)
            // setFileList((prevFileList) => [...prevFileList, ...tempFileName]);
        }
    }
    const handleFileChange = (e) => {
        const files = e.target.files;
        const disallowedSpecialCharsRegex = /[^a-zA-Z0-9_.\- ]/; // Allow only alphanumeric characters, underscores, and dots
    
        // Check if any file name contains disallowed special characters
        const isInvalidFileNames = Array.from(files).some((file) =>
          disallowedSpecialCharsRegex.test(file.name)
        );
    
        if (!isInvalidFileNames) {
          // Extract file names
          const fileNames = Array.from(files).map((file) => file.name);
    
          // Update state with file names
          setFormData({ ...formData, files: [e] });
        } else {
          const invalidFileNames = Array.from(files)
          .filter((file) => disallowedSpecialCharsRegex.test(file.name))
          .map((file) => file.name)
          .join(', ');
        //   alert(`(${invalidFileNames}) contain special characters. Please use only alphanumeric characters, underscores, hyphens, dots.`);
          Swal.fire({
            icon: 'error',
            title: 'Invalid File Names',
            text: `${invalidFileNames} may contain one of the special characters. Please use only alphanumeric characters, underscores, hyphens, dots and spaces.`,
          });
          e.target.value = null; // Change to e.target.files = null;
        }
      };
    const handleSubmit = () => {
        Swal.fire({
            title: 'Creating New Job Request...',
            allowOutsideClick: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
            showConfirmButton: false,
          });
        console.log('formData.files: ', formData.files)
        //setDisplayUpldList(tempData);
        let attachedFiles = [];
        let files = formData.files.length > 0 && formData.files[0].target.files;
        console.log('files from handleSubmit: ', files)
        for (let i = 0; i < files.length; i++) {
            if (formData.files.length > 0) {
                let reader = new FileReader();
                reader.readAsDataURL(files[i]);
                reader.onload = (e) => {
                    let tempfile = e.target.result.split(',');
                    //console.log('tempfile: ', tempfile)
                    attachedFiles.push({file: tempfile[1], filename: files[i].name});
                    //console.log('attachedFiles: ', attachedFiles);
                }
            }
        }
        console.log('formData.files: ', formData.files);

       

        console.log('attachedFile: ', attachedFiles)
        setTimeout(function () {
            createJobRequestAPI({ job_title: formData.jobTitle, attached_file_name: '', job_type: formData.jobType, job_description: formData.description, priority_id: formData.job_priority, year: formData.year, document_files: '', attached_file_name: '' }).then((response) => {
                console.log(response)
                let jreq_id = response.job_request_id;
                if (response.request_status === 200) {
                    for (let i = 0; i < attachedFiles.length; i++) {
                        createJobRequestAPI({ job_title: '', attached_file_name: formData.filename, job_type: '', job_description: '', priority_id: '', year: '', document_files: attachedFiles[i].file, attached_file_name: attachedFiles[i].filename, job_request_id: jreq_id }).then((response) => {
                            console.log(response)
                            if (response.request_status === 200) {
                                //setMsgDisplay(response)
                                //setShoDialog(true);
                            }
                        }).catch((e) => console.log(e))
                    }
                    setMsgDisplay(response)
                    Swal.close();
                    setShoDialog(true);
                    setFormData({
                        jobTitle: '',
                                jobType: '',
                                job_priority: '',
                                description: '',
                                year: '',
                                filename: '',
                                fileSize: '',
                                files: []
                        })
                        setFileList([])
                }
                console.log(shoDialog)
                if (shoDialog === false) {
                    setTimeout(() => {
                        window.location.href = 'https://client.ncserp.com/job-request';
                        // window.location.href = 'http://localhost:3000/job-request';
                        // navigate(`/job-request`, { replace: true });
                    }, 1000);
                }
            }).catch((e) => console.log(e))
        }, 500)

    }

    useEffect(() => {
        handleFileUpload()
        console.log('fileList from useEffect: ', fileList)
        if (jobPriority === null) {
            custJobPriority().then((response) => {
                if (checkTokenValidity(response.data)) {
                    setJobPriority(response.data.data)
                }
            }).catch((e) => console.log(e));
        }
    }, [formData.files])

    console.log('formData: ', formData);
    return (
        <div className="modal d-block" id="commonModal" aria-hidden="true" aria-labelledby="commonModalLabel" tabIndex="-1">
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="commonModalLabel"><svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 0 24 24" width="40px" fill="#023e62"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M13 11h-2v3H8v2h3v3h2v-3h3v-2h-3zm1-9H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11z" /></svg> &nbsp;Create New Job Request</h3>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.handleJobRequest} style={{ marginRight: 20 }}></button>
                    </div>
                    <div className="modal-body">
                        <div className="row noleftRight justify-content-evenly">
                            <div className="col-sm-6 border-blue ">
                                <div className="row mb-3">
                                    <div className="col-sm-6">
                                        <label htmlFor="jobtitle" className="form-label">Job Title*</label>
                                        <input type="email" className="form-control" id="jobtitle" value={formData.jobTitle} onChange={(e) => setFormData({ ...formData, jobTitle: e.target.value })} />
                                    </div>
                                    <div className="col-sm-6">
                                        <label htmlFor="jobtype" className="form-label">Job Type*</label>
                                        <input type="email" className="form-control" id="jobtype" value={formData.jobType} onChange={(e) => setFormData({ ...formData, jobType: e.target.value })} />
                                    </div>
                                    <div className="col-sm-6 mt10">
                                        <label htmlFor="jobtype" className="form-label">Job Priority*</label>
                                        {jobPriority !== null ?
                                            <select className="form-control" value={formData.job_priority} onChange={(e) => setFormData({ ...formData, job_priority: e.target.value })}>
                                                <option>--Select--</option>
                                                {jobPriority.map((item, index) =>
                                                    <option key={index} value={item.priority_id}>{item.priority_text}</option>
                                                )}
                                            </select>
                                            :
                                            <select className="form-control">
                                                <option>--Select--</option>
                                            </select>
                                        }
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="descrp" className="form-label">Additional instructions*</label>
                                    <textarea className="form-control" id="descrp" rows="3" value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })}></textarea>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-sm-6">
                                        <label htmlFor="year" className="form-label">Year*</label>
                                        <input type="email" className="form-control" id="year" value={formData.year} onChange={(e) => setFormData({ ...formData, year: e.target.value })} />
                                    </div>
                                    <div className="col-sm-6 d-flex justify-content-end align-items-end">
                                    <button
                                        style={{
                                            marginRight: 10,
                                            float: 'right',
                                            backgroundColor: 'transparent',
                                            border: 'none',
                                            padding: 10,
                                        }}
                                        type="button"
                                        className="btn btn-info btn-md"
                                        title="Note : Special characters like SPACE % # ' / & ^ () etc are not allowed in file name."
                                        onMouseEnter={() => { setHovered(true); hoverAlert(); }}
                                        onMouseLeave={() => setHovered(false)}
                                        >
                                        <FontAwesomeIcon icon={faQuestionCircle} />
                                        </button>
                                        <div className="fileuploadBtn" style={{ marginRight: 20 }}>
                                            {/* <input type="file" id="formFileMultiple" onChange={(e) => setFormData({ ...formData, files: [e] })} multiple /> */}
                                            <input 
                                            type="file" id="formFileMultiple" 
                                            multiple 
                                            // onChange={(e) => setFormData({ ...formData, files: [e] })} 
                                            onChange={handleFileChange} 
                                            />
                                            <button type="button" className="btn btn-secondary btn-lg">Attach Document</button>
                                        </div>
                                        <button type="button" className="btn btn-primary btn-lg" onClick={handleSubmit}>Submit</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-5 border-blue">
                                <div className="row mb-3">
                                <div className="col-sm-12  " style={{ marginRight: 25 }}>
                        <div
                          className="gray-text-box"
                          style={{
                            // backgroundColor : "#e5cfcf",
                            padding: "10px",
                            borderRadius: "5px",
                            height: "100%",
                            color: "GrayText",
                          }}
                        >
                          <b>Note :
                            </b> <br/>  Upload multiple documents at once, and trying
                          to add documents again will clear the previous
                          documents. You can also upload documents after
                          submitting job requests.
                        </div>
                      </div>
                                    <div className="col-sm-12">
                                        <label for="exampleFormControlInput1" className="form-label" style={{ padding: "10px" }}><h3>Attached Files</h3></label>
                                        {fileList.length > 0  ?
                                        <div className="fileAttachedContainer" style={{ maxHeight : '300px',overflowY : 'auto' }}>
                                            <ul className="fileAttachedList">
                                                {/* <li><div className="d-flex justify-content-between"><div>{formData.filename.split('.', 3).pop() === 'docx' ? <img src="/assets/imgs/wordIcon.png" alt="fileicon" width="35px" /> : formData.filename.split('.', 3).pop() === 'xlsx' ? <img src="/assets/imgs/excelIcon.png" width="35px" /> : formData.filename.split('.', 3).pop() === 'pptx' ? <img src="/assets/imgs/powerpointIcon.png" width="35px" /> : formData.filename.split('.', 3).pop() === 'gif' ? <img src="/assets/imgs/gifIcon.png" width="35px" /> : formData.filename.split('.', 3).pop() === 'jpg' ? <img src="/assets/imgs/jpgIcon.png" width="35px" /> : formData.filename.split('.', 3).pop() === 'png' ? <img src="/assets/imgs/pngIcon.png" width="35px" /> : formData.filename.split('.', 3).pop() === 'pdf' ? <img src="/assets/imgs/pdfIcon.png" width="35px" /> : <img src="/assets/imgs/file.png" width="35px" />} {formData.filename}</div><div>{parseFloat(formData.fileSize / 1048576).toFixed(2)} MB</div></div></li> */}
                                                {fileList.map((item, index) =>
                                                    <li key={index}><div className="d-flex justify-content-between"><div>{item.name.split('.', 3).pop() === 'docx' ? <img src="/assets/imgs/wordIcon.png" alt="fileicon" width="35px" /> : item.name.split('.', 3).pop() === 'xlsx' ? <img src="/assets/imgs/excelIcon.png" width="35px" alt="Excel" /> : item.name.split('.', 3).pop() === 'pptx' ? <img src="/assets/imgs/powerpointIcon.png" width="35px" alt="Power Point" /> : item.name.split('.', 3).pop() === 'gif' ? <img src="/assets/imgs/gifIcon.png" width="35px" alt="gif" /> : item.name.split('.', 3).pop() === 'jpg' ? <img src="/assets/imgs/jpgIcon.png" width="35px" alt="jpg" /> : item.name.split('.', 3).pop() === 'png' ? <img src="/assets/imgs/pngIcon.png" width="35px" alt="png" /> : item.name.split('.', 3).pop() === 'pdf' ? <img src="/assets/imgs/pdfIcon.png" width="35px" alt="pdf" /> : <img src="/assets/imgs/file.png" width="35px" alt="file" />} {item.name}</div><div>{parseFloat(item.size / 1048576).toFixed(2)} MB</div></div></li>
                                                )}
                                            </ul>
                                            </div>
                                            :
                                            <div className="d-flex justify-content-center align-items-center">
                                                <img src="/assets/imgs/fileupload.jpg" alt="No files selected" />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {shoDialog && <DialogBox data={msgDisplay} handleDialogue={handleDialogue} />}
        </div>
    );
}

export default ModalView;


export const DialogBox = (props) => {
    return (
        <div className="modal d-block" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header dark-bg">
                        <h5 className="modal-title">{props.data.status ? 'Success' : 'Failed'}</h5>
                    </div>
                    <div className="modal-body">
                        {props.data.status ? <p className="green-text">{props.data.message}</p> : <p className="text-red">{props.data.message}</p>}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={props.handleDialogue}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const AddUser = (props) => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        user_email: '',
        contact_phone_1: '',
        user_name: '',
        password: '',
        customer_user_type: '1'
    });

    const [shoDialog, setShoDialog] = useState(false);
    const [msgDisplay, setMsgDisplay] = useState('');


    const handleDialogue = () => {
        setFormData({ first_name: '', last_name: '', user_email: '', contact_phone_1: '', user_name: '', password: '', customer_user_type: '1' })
        shoDialog ? setShoDialog(false) : setShoDialog(true);
    }

    const userContext = useContext(UserContext);
    console.log('userContext from Modal: ', userContext)

    const handleSubmit = () => {
        if (props.data) {
            updateSingleUserAPI(formData).then((response) => {
                console.log(response)
                if (response.request_status === 200) {
                    setMsgDisplay(response)
                    setShoDialog(true);
                }
            }).catch((e) => console.log(e))
        } else {
            createUserAPI(formData).then((response) => {
                console.log(response)
                if (response.request_status === 200) {
                    setMsgDisplay(response)
                    setShoDialog(true);
                }
            }).catch((e) => console.log(e))
        }

    }
    useEffect(() => {
        if (props.data) {
            setFormData(props.data);
        }
    }, [props.data])

    return (
        <div className="modal d-block" id="commonModal" aria-hidden="true" aria-labelledby="commonModalLabel" tabIndex="-1">
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="commonModalLabel">Create New User</h3>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.handleAddUser} style={{ marginRight: 20 }}></button>
                    </div>
                    <div className="modal-body">
                        <div className="row noleftRight justify-content-evenly align-items-center">
                            <div className="col-sm-6 box-shadow pad30" style={{ borderRadius: '5px' }}>
                                <div className="row mb-3" style={{ paddingLeft: '30px', paddingRight: '30px' }}>
                                    <div className="col-sm-6">
                                        <label htmlFor="first_name" className="form-label">First Name</label>
                                        <input type="text" className="form-control" name="first_name" id="first_name" value={formData.first_name} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
                                    </div>
                                    <div className="col-sm-6">
                                        <label htmlFor="last_name" className="form-label">Last Name</label>
                                        <input type="text" className="form-control" name="last_name" id="last_name" value={formData.last_name} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
                                    </div>
                                </div>
                                <div className="row mb-3" style={{ paddingLeft: '30px', paddingRight: '30px' }}>
                                    <div className="col-sm-6">
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <input type="email" className="form-control" name="user_email" id="email" value={formData.user_email} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
                                    </div>
                                    <div className="col-sm-6">
                                        <label htmlFor="phone_no" className="form-label">Phone No.</label>
                                        <input type="tel" className="form-control" name="contact_phone_1" id="phone_no" value={formData.contact_phone_1} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
                                    </div>
                                </div>
                                <div className="row mb-3" style={{ paddingLeft: '30px', paddingRight: '30px' }}>
                                    <div className="col-sm-6">
                                        <label htmlFor="username" className="form-label">Username</label>
                                        <input type="email" className="form-control" name="user_name" id="username" value={formData.user_name} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
                                    </div>
                                    <div className="col-sm-6">
                                        <label htmlFor="password" className="form-label">Password</label>
                                        <input type="password" className="form-control" name="password" id="password" value={formData.password} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
                                    </div>
                                </div>
                                <div className="row mb-3" style={{ paddingLeft: '30px', paddingRight: '30px' }}>
                                    <label htmlFor="role" className="form-label">Role</label>
                                    <div className="col-sm-12">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="userrole" value="1" id="administrator" onChange={(e) => setFormData({ ...formData, customer_user_type: e.target.value })} checked={formData.customer_user_type === '1'} />
                                            <label className="form-check-label" htmlFor="administrator">
                                                Adminstrator <br />
                                                <small>This Role will have access to all modules</small>
                                            </label>
                                        </div>
                                        <div className="form-check mt10">
                                            <input className="form-check-input" type="radio" name="userrole" value="2" id="projectmanager" onChange={(e) => setFormData({ ...formData, customer_user_type: e.target.value })} checked={formData.customer_user_type === '2'} />
                                            <label className="form-check-label" htmlFor="projectmanager">
                                                Project Manager <br />
                                                <small>This Role will have access to Project module and enable feature to communicate with assignee of the Project</small>
                                            </label>
                                        </div>
                                        <div className="form-check mt10">
                                            <input className="form-check-input" type="radio" name="userrole" value="3" id="accountant" onChange={(e) => setFormData({ ...formData, customer_user_type: e.target.value })} checked={formData.customer_user_type === '3'} />
                                            <label className="form-check-label" htmlFor="accountant">
                                                Accountant <br />
                                                <small>This Role will have access to Billing module</small>
                                            </label>
                                        </div>
                                        <div className="form-check mt10">
                                            <input className="form-check-input" type="radio" name="userrole" value="4" id="hr" onChange={(e) => setFormData({ ...formData, customer_user_type: e.target.value })} checked={formData.customer_user_type === '4'} />
                                            <label className="form-check-label" htmlFor="hr">
                                                HR <br /><small>This role will have access to User Management</small>
                                            </label>

                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3 text-right" style={{ paddingLeft: '30px', paddingRight: '30px' }}>
                                    <div className="col">
                                        <button type="button" className="btn btn-primary btn-lg" onClick={handleSubmit}>Submit</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-5">
                                <div className="row mb-3">
                                
                                    <div className="col-sm-12 text-center">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <img src="/assets/imgs/adduser.jpg" alt="No files selected" />
                                        </div>
                                        <h2 className="mt20">How it Work?</h2>
                                        <h3 className="text-gray font-light">We will send an email inviting team members to join your team</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {shoDialog && <DialogBox data={msgDisplay} handleDialogue={handleDialogue} />}
        </div>
    );
}


export const ChangePassword = (props) => {
    const [shoDialog, setShoDialog] = useState(false);
    const [msgDisplay, setMsgDisplay] = useState('');
    const [formData, setFormData] = useState({
        old_password: '',
        new_password: '',
        confirm_password: ''
    });
    const userContext = useContext(UserContext);

    const [oldShwPass, setOldShwPass] = useState(false);
    const [newShwPass, setNewShwPass] = useState(false);
    const [confShwPass, setConfShwPass] = useState(false);

    const handleDialogue = () => {
        shoDialog ? setShoDialog(false) : setShoDialog(true);
    }


    const handleSubmit = () => {
        if (formData.old_password !== '' && formData.new_password !== '' && formData.confirm_password !== '') {
            if (formData.new_password.length >= 8 && formData.confirm_password.length >= 8) {
                if (formData.new_password === formData.confirm_password) {
                    let regex = new RegExp('(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])')
                    if (regex.test(formData.new_password)) {
                        //final api call
                        changePasswordAPI(formData).then((response) => {
                            console.log('changePasswordAPI: ', response.status);
                            if (response.status) {
                                setMsgDisplay({ status: true, message: response.message })
                                shoDialog ? setShoDialog(false) : setShoDialog(true);
                                setFormData({
                                    old_password: '',
                                    new_password: '',
                                    confirm_password: ''
                                })
                            } else {
                                setMsgDisplay({ status: false, message: response.message })
                                shoDialog ? setShoDialog(false) : setShoDialog(true);
                            }
                        }).catch((e) => console.log(e));
                    } else {
                        setMsgDisplay({ status: false, message: 'password contains a combination of uppercase and lowercase letter and number are required' })
                        shoDialog ? setShoDialog(false) : setShoDialog(true);
                    }
                } else {
                    setMsgDisplay({ status: false, message: 'New Password and Confirm Password are not matching' })
                    shoDialog ? setShoDialog(false) : setShoDialog(true);
                }
            } else {
                setMsgDisplay({ status: false, message: 'Password must be atleast 8 characters' })
                shoDialog ? setShoDialog(false) : setShoDialog(true);
            }
        } else {
            setMsgDisplay({ status: false, message: 'All Fields are mandatory' })
            shoDialog ? setShoDialog(false) : setShoDialog(true);
        }
    }

    return (
        <div className="modal d-block" id="commonModal" aria-hidden="true" aria-labelledby="commonModalLabel" tabIndex="-1">
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="commonModalLabel">Change Password</h3>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.handleChangePass} style={{ marginRight: 20 }}></button>
                    </div>
                    <div className="modal-body">
                        <div className="row noleftRight justify-content-evenly align-items-center">
                            <div className="col-sm-5 box-shadow pad30" style={{ borderRadius: '5px' }}>
                                <div className="row mb-3" style={{ paddingLeft: '30px', paddingRight: '30px' }}>
                                    <div className="col-sm-12 posrel">
                                        <label htmlFor="old_password" className="form-label">Old Password</label>
                                        <input type={oldShwPass ? 'text' : 'password'} className="form-control" name="old_password" id="old_password" value={formData.old_password} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} autoComplete="off" />
                                        <div className="shwpass" onClick={() => setOldShwPass(oldShwPass ? false : true)}><i className={oldShwPass ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i></div>
                                    </div>
                                </div>
                                <div className="row mb-3" style={{ paddingLeft: '30px', paddingRight: '30px' }}>
                                    <div className="col-sm-12 posrel">
                                        <label htmlFor="new_password" className="form-label">New Password</label>
                                        <input type={newShwPass ? 'text' : 'password'} minLength="8" className="form-control" name="new_password" id="new_password" value={formData.new_password} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} autoComplete="off" />
                                        <div className="shwpass" onClick={() => setNewShwPass(newShwPass ? false : true)}><i className={newShwPass ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i></div>
                                    </div>
                                </div>
                                <div className="row mb-3" style={{ paddingLeft: '30px', paddingRight: '30px' }}>
                                    <div className="col-sm-12 posrel">
                                        <label htmlFor="confirm_password" className="form-label">Confirm Password</label>
                                        <input type={confShwPass ? 'text' : 'password'} minLength="8" className="form-control" name="confirm_password" id="confirm_password" value={formData.confirm_password} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} autoComplete="off" />
                                        <div className="shwpass" onClick={() => setConfShwPass(confShwPass ? false : true)}><i className={confShwPass ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i></div>
                                    </div>
                                </div>
                                <div className="row mb-3 text-right mt50" style={{ paddingLeft: '30px', paddingRight: '30px' }}>
                                    <div className="d-flex justify-content-between">
                                        <button type="button" className="btn btn-link" onClick={handleSubmit}>Forgot Password</button>
                                        <button type="button" className="btn btn-primary btn-lg" onClick={handleSubmit}>Submit</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-7">
                                <div className="row mb-3">
                                    <div className="col-sm-12 text-center">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <img src="/assets/imgs/changePassBg.jpg" alt="Change Password Image" />
                                        </div>
                                        <h2 className="mt50 text-primary">Note</h2>
                                        <h3 className="text-gray font-light">A minimum 8 charaters password contains a combination of uppercase and lowercase letter and number are required..</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {shoDialog && <DialogBox data={msgDisplay} handleDialogue={handleDialogue} />}
        </div>
    );
}




