import { useState, useCallback, useContext, useEffect } from 'react';
import { Calendar } from '@natscale/react-calendar';
import '@natscale/react-calendar/dist/main.css';
import { UserContext } from '../App';
import { UserRecentActivity } from '../api';

function RecentActivity() {
    const [recenActList, setRecenActList] = useState(null);
    const userContext = useContext(UserContext);

    useEffect(() => {
        if (recenActList === null && userContext !== null) {
            UserRecentActivity().then((response) => {
                console.log("UserRecentActivity", response.data.data.resultData);
                setRecenActList(response.data.data.resultData);
            }).catch((e) => console.log(e));
        }
    }, [userContext])

    return (
        <>
            {recenActList !== null && recenActList.length > 0 ?
                <ul className='recentActivity'>
                    {recenActList.map((item, index) =>
                        <li key={index} className={item.type === 'Project' ? 'project' : item.type === 'Bills' ? 'bill' : item.type === 'Message' ? 'msg' : ''}>
                            <h4><strong>{item.type}: </strong><span>{item.desc}</span></h4>
                            <p>{item.name}</p>
                            <small>{item.date}</small>
                        </li>
                    )}                   
                </ul>
                : "No Data Available"
            }
        </>
    )
}

export default RecentActivity;
