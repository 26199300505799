import { useState, useRef, useEffect, useContext } from "react";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import ProjectDueThisWeek from './projectthisweek'
import ProjectCompletedThisWeek from './projectcompletedweek';
import { custTaskStats, custAllTaskList, custTaskListHead, custAllTaskFilters, custFteMainTaskList, checkTokenValidity } from "../api";
import ReactPaginate from 'react-paginate';
import ProjectSummaryChart from "./projectsummarychart";

import { UserContext } from '../App';

import 'react-circular-progressbar/dist/styles.css';

function Projects(props) {
  const [custTaskStatsData, setCustTaskStatsData] = useState(null);
  const [custFilterData, setCustFilterData] = useState(null);
  const [custTaskList, setCustTaskList] = useState(null);
  const [projectType, setProjectType] = useState("ALL");
  const [viewType, setViewType] = useState('grid');
  const [custTaskListHeadData, setCustTaskListHeadData] = useState(null)
  const [itemsPerPage, setItemsPerPage] = useState(6)
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [items, setItems] = useState([]);
  const [fteMainItems, setFteMainItems] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [isSearchFilter, setIsSearchFilter] = useState(false);
  const [searchArray, setSearchArray] = useState([]);
  const [fteMainSearchArray, setFteMainSearchArray] = useState([]);
  const [filterShow, setFilterShow] = useState(false);
  const [filterValue, setFilterValue] = useState({
    work_category: '',
    project_status: '',
    project_type: '',
    task_open_close: 'opened'
  })

  const [projectOptionsShow, setProjectOptionsShow] = useState([]);

  const token = sessionStorage.getItem('token');
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const allowJobRequest = JSON.parse(sessionStorage.getItem('userInfo'))

  const handleFilter = () => {
    filterShow ? setFilterShow(false) : setFilterShow(true)
  }
  const handleProjOptions = (item) => {
    let temp = [...projectOptionsShow];
    let index = temp.findIndex((x) => x.task_id === item.task_id);
    temp.forEach((x) => {
      if (!temp[index]) x.moreoption = false
    }
    );
    temp[index].moreoption = projectOptionsShow[index].moreoption ? false : true;
    console.log('temp: ', temp)
    setProjectOptionsShow(temp)
  }

  const handleFilterSearch = () => {
    setFilterShow(false);
    setIsSearchFilter(true);
    setProjectType("")
    console.log("filterValue--------->",filterValue)
    custAllTaskList(filterValue).then((response) => {
      setSearchArray(response.data.data.resultData);
      setPageCount(response.data.data.resultData.length / itemsPerPage);
      setItems(response.data.data.resultData.slice(0, itemsPerPage))
      let projectOptions = [];
      response.data.data.resultData.forEach(element => {
        projectOptions.push({ task_id: element.task_id, moreoption: false });
      });
    }).catch((e) => console.log(e));
  }

  
  const [loading, setLoading] = useState(false);
  const handleTaskOpenClose = (data) => {
    setLoading(true)
    console.log(loading,"loading")
    setFilterValue({ ...filterValue, task_open_close: data });
    let temp = { ...filterValue, task_open_close: data }
    console.log('temp: ', temp);
    setFilterShow(false);
    setIsSearchFilter(true);
    console.log("projeeeeeect",custAllTaskList)
    custAllTaskList(temp).then((response) => {
      setSearchArray(response.data.data.resultData);
      console.log("setSearchArray",searchArray)
      setPageCount(response.data.data.resultData.length / itemsPerPage);
      console.log("setPageCount",pageCount)
      setItems(response.data.data.resultData.slice(0, itemsPerPage))
      let projectOptions = [];
      response.data.data.resultData.forEach(element => {
        projectOptions.push({ task_id: element.task_id, moreoption: false });
      });
      console.log("projectOptions",projectOptions)
    }).catch((e) => console.log(e)).finally(() => {
      setLoading(false)
    });
  }


  useEffect(() => {
    console.log('useEffect called');
    if (custTaskStatsData === null && token !== undefined && userContext !== null) {
      custTaskStats().then((response) => {
        if (checkTokenValidity(response.data)) {
          console.log("custTaskStats", response.data.data);
          setCustTaskStatsData(response.data.data);
        }
      }).catch((e) => console.log(e));
    }
    if (custTaskList === null && token !== undefined && userContext !== null) {
      let tempData = userContext;
      console.log('userContext: ', tempData);
      custAllTaskList(filterValue).then((response) => {
        console.log("custAllTaskList", response.data.data.resultData);
        setCustTaskList(response.data.data.resultData);
        setPageCount(response.data.data.resultData.length / itemsPerPage);
        setItems(response.data.data.resultData.slice(0, itemsPerPage))
        let projectOptions = [];
        response.data.data.resultData.forEach(element => {
          projectOptions.push({ task_id: element.task_id, moreoption: false });
        });
        custAllTaskFilters().then((response) => {
          console.log('Project Filters Data: ', response.data.data.resultData);
          setCustFilterData(response.data.data.resultData)
        })
        setProjectOptionsShow(projectOptions)
      }).catch((e) => console.log(e));
    }
    if (custTaskListHeadData === null && token !== undefined && userContext !== null) {
      let tempData = userContext;
      console.log('userContext: ', tempData);
      custTaskListHead(tempData).then((response) => {
        console.log("custTaskListHead", response.data.data.resultData);
        setCustTaskListHeadData(response.data.data.resultData);
      }).catch((e) => console.log(e));
    }
  }, [userContext])

  const handleItemList = (selectedPage) => {
    const tempData = custTaskList
    if (tempData !== null) {
      const dataShow = tempData.slice((itemsPerPage * selectedPage) - itemsPerPage, itemsPerPage * selectedPage);
      setItems(dataShow)
    }
  }

  const handleSearch = (event) => {
    if (projectType !== 'fteMain') {
      const tempData = custTaskList;
      setSearchValue(event.target.value);
      event.target.value !== '' ? setIsSearchFilter(true) : setIsSearchFilter(false);
      let searchArrayData = tempData.filter((item) => item.task_code.toUpperCase().includes(event.target.value.toUpperCase()) || item.task_id.toUpperCase().includes(event.target.value.toUpperCase()) || item.task_title.toUpperCase().includes(event.target.value.toUpperCase()));
      setSearchArray(searchArrayData);
      console.log('searchArray: ', searchArray);
      console.log('handle search: ', event.target.value);
    } else if (projectType === 'fteMain') {
      const tempData = fteMainItems;
      setSearchValue(event.target.value);
      event.target.value !== '' ? setIsSearchFilter(true) : setIsSearchFilter(false);
      let searchArrayData = tempData.filter((item) => item.task_code.toUpperCase().includes(event.target.value.toUpperCase()) || item.task_id.toUpperCase().includes(event.target.value.toUpperCase()) || item.task_title.toUpperCase().includes(event.target.value.toUpperCase()));
      setFteMainSearchArray(searchArrayData);
      console.log('searchArray: ', searchArray);
      console.log('handle search: ', event.target.value);
    }
  }

  const handleProjectType = async (selectedType) => {
    console.log('selectedType : ', selectedType)
    let tempData = userContext;
    setProjectType(selectedType);
    setFilterValue({ ...filterValue, project_type: selectedType })
    const finalData = {
      ...tempData,
      project_type: selectedType
    }
    if (selectedType !== 'fteMain') {
      await custAllTaskList(finalData).then((response) => {
        console.log("custAllTaskList", response.data.data.resultData);
        setIsSearchFilter(false);
        setCustTaskList(response.data.data.resultData);
        setPageCount(response.data.data.resultData.length / itemsPerPage);
        handlePageClick({ selected: 0 })
        setItems(response.data.data.resultData.slice(0, itemsPerPage))
      }).catch((e) => console.log(e));
    } else if (selectedType === 'fteMain') {
      await custFteMainTaskList().then((response) => {
        console.log("cust Fte Main AllTaskList", response.data.data.resultData);
        setIsSearchFilter(false);
        setCustTaskList(response.data.data.resultData);
        setPageCount(response.data.data.resultData.length / itemsPerPage);
        handlePageClick({ selected: 0 })
        setFteMainItems(response.data.data.resultData.slice(0, itemsPerPage))
      }).catch((e) => console.log(e));
    }
  }

  const handlePageClick = (event) => {
    console.log(event);
    const selectedNumber = event.selected + 1;
    handleItemList(selectedNumber);
  };
  console.log('items: ', items);
  // console.log('filterValue.project_type: ', filterValue.project_type);
  // console.log("custFilterData.type_data",custFilterData.type_data)

  return (
    <div className='container-fluid mt50 padlr50'>
      <div className='row'>
        <div className='col'>
          <h2>Project</h2>
        </div>
        {allowJobRequest !== null && allowJobRequest.allow_job_request === "1" &&
          <div className='col-lg-2 col-md-4 text-right'>
            <button type='button' className='btn btn-primary' onClick={props.handleJobRequest}><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#ffffff"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm4 11h-3v3c0 .55-.45 1-1 1s-1-.45-1-1v-3H8c-.55 0-1-.45-1-1s.45-1 1-1h3V8c0-.55.45-1 1-1s1 .45 1 1v3h3c.55 0 1 .45 1 1s-.45 1-1 1z" /></svg>&nbsp;&nbsp;New Job Request</button>
          </div>
        }
      </div>
      <div className='row mt50'>
        <div className='col-12 col-md-12 col-lg-4 mt10'>
          <div className="block">
            <div className='row padlr20'>
              <h3 className="text-primary">Project Summary</h3>
              <div className='task'>
                <ProjectSummaryChart data={custTaskStatsData} />
              </div>
            </div>
          </div>
        </div>
        <div className='col col-md-6 col-lg-4 mt10'>
          <div className="block">
            <ProjectDueThisWeek data={custTaskStatsData} />
          </div>
        </div>
        <div className='col col-md-6 col-lg-4 mt10'>
          <div className="block">
            <ProjectCompletedThisWeek data={custTaskStatsData} />
          </div>
        </div>
      </div>
      <div className='row justify-content-between align-items-center mt30'>
        <div className='row  justify-content-between align-items-center searchfilter'>
          <div className="col-12 col-md-12 col-lg-12 mt10">
            <div className="btn-group">
              <button type="button" className={projectType === "ALL" ? "btn btn-primary active" : "btn btn-primary"} aria-current="page" onClick={() => handleProjectType('ALL')}>{custTaskListHeadData !== null ? custTaskListHeadData.total_task_count : ''} All Projects</button>
              <button type="button" className={projectType === "FTE" ? "btn btn-primary active" : "btn btn-primary"} onClick={() => handleProjectType('FTE')}>{custTaskListHeadData !== null ? custTaskListHeadData.fte_task_count : ''} FTE</button>
              <button type="button" className={projectType === "PAYG" ? "btn btn-primary active" : "btn btn-primary"} onClick={() => handleProjectType('PAYG')}>{custTaskListHeadData !== null ? custTaskListHeadData.payg_task_count : ''} PayG</button>
              <button type="button" className={projectType === "fteMain" ? "btn btn-primary active" : "btn btn-primary"} onClick={() => handleProjectType('fteMain')}>FTE Main Projects</button>
            </div>
          </div>
          <div className='col-12 col-md-12 col-lg-12 mt10'>
            <div className='d-flex justify-content-end align-items-center'>
              <div className="mob-hidden" style={{ marginRight: 20, position: 'relative' }}>
                <input type="text" className='form-control' value={searchValue} placeholder='Search' onChange={handleSearch} />
                <span className='searchicon'></span>
              </div>
              {projectType !== 'fteMain' &&
                <span className="mob-hidden hidden-md d-flex align-items-center">
                  Projects: &nbsp;
                  <ul className="gridlisticon">

                    <li className={filterValue.task_open_close === 'opened' ? 'active' : ''} onClick={() => handleTaskOpenClose('opened')} >Open</li>
                    <li className={filterValue.task_open_close === 'closed' ? 'active' : ''} onClick={() => handleTaskOpenClose('closed')}>Closed</li>
                  </ul>
                </span>
              }
              &nbsp;&nbsp; View as: &nbsp;&nbsp;
              <ul className="gridlisticon">
                <li className={viewType === 'grid' ? 'active' : ''} onClick={() => setViewType('grid')}><svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24" /></g><g><g><path d="M5,11h4c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2H5C3.9,3,3,3.9,3,5v4C3,10.1,3.9,11,5,11z" /><path d="M5,21h4c1.1,0,2-0.9,2-2v-4c0-1.1-0.9-2-2-2H5c-1.1,0-2,0.9-2,2v4C3,20.1,3.9,21,5,21z" /><path d="M13,5v4c0,1.1,0.9,2,2,2h4c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2h-4C13.9,3,13,3.9,13,5z" /><path d="M15,21h4c1.1,0,2-0.9,2-2v-4c0-1.1-0.9-2-2-2h-4c-1.1,0-2,0.9-2,2v4C13,20.1,13.9,21,15,21z" /></g></g></svg></li>
                <li className={viewType === 'list' ? 'active' : ''} onClick={() => setViewType('list')}><svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><rect fill="none" height="24" width="24" /><path d="M4,14h2c0.55,0,1-0.45,1-1v-2c0-0.55-0.45-1-1-1H4c-0.55,0-1,0.45-1,1v2C3,13.55,3.45,14,4,14z M4,19h2c0.55,0,1-0.45,1-1 v-2c0-0.55-0.45-1-1-1H4c-0.55,0-1,0.45-1,1v2C3,18.55,3.45,19,4,19z M4,9h2c0.55,0,1-0.45,1-1V6c0-0.55-0.45-1-1-1H4 C3.45,5,3,5.45,3,6v2C3,8.55,3.45,9,4,9z M9,14h11c0.55,0,1-0.45,1-1v-2c0-0.55-0.45-1-1-1H9c-0.55,0-1,0.45-1,1v2 C8,13.55,8.45,14,9,14z M9,19h11c0.55,0,1-0.45,1-1v-2c0-0.55-0.45-1-1-1H9c-0.55,0-1,0.45-1,1v2C8,18.55,8.45,19,9,19z M8,6v2 c0,0.55,0.45,1,1,1h11c0.55,0,1-0.45,1-1V6c0-0.55-0.45-1-1-1H9C8.45,5,8,5.45,8,6z" /></svg></li>
              </ul>
              {custFilterData !== null && projectType !== 'fteMain' ?
                <div style={{ position: "relative", marginLeft: 10 }}>
                  <span className='filter' onClick={() => handleFilter()}><svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><path d="M0,0h24 M24,24H0" fill="none" /><path d="M7,6h10l-5.01,6.3L7,6z M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6 c0,0,3.72-4.8,5.74-7.39C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z" /><path d="M0,0h24v24H0V0z" fill="none" /></g></svg> <span className="mob-hidden">Filter</span></span>
                  {filterShow &&
                    <div className="filterOptions">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4>Filter</h4>
                        <span style={{ cursor: 'pointer', display: 'inline-block', width: '25px', height: '25px', lineHeight: '25px', textAlign: 'center', background: '#ddd', borderRadius: '100px' }} onClick={() => handleFilter()}>x</span>
                      </div>
                      <label>Work Catergory</label>
                      <select className="form-select" aria-label="Default select example" name="work_category" value={filterValue.work_category} onChange={(e) => setFilterValue({ ...filterValue, [e.target.name]: e.target.value })}>
                        {custFilterData.workcategory_data.length > 0 ?
                          <>
                            <option>select</option>
                            {custFilterData.workcategory_data.map((item, index) =>
                              <option key={index} value={item.workcategory_id}>{item.workcategory}</option>
                            )}
                          </>
                          : <option>select</option>
                        }
                      </select>
                      <label className="mt10">Project Status</label>
                      <select className="form-select" aria-label="Default select example" name="project_status" value={filterValue.project_status} onChange={(e) => setFilterValue({ ...filterValue, [e.target.name]: e.target.value })}>
                        {custFilterData.status_data.length > 0 ?
                          <>
                            <option selected>select</option>
                            {custFilterData.status_data.map((item, index) =>
                              <option key={index} value={item.status_id}>{item.status_title}</option>
                            )}
                          </>
                          : <option selected>select</option>
                        }
                      </select>
                      
                      <label className="mt10">Project Type</label>
                      {custFilterData.type_data.length > 0 ?
                        <div className="btn-toolbar" role="toolbar" ariaLabel="Toolbar with button groups">
                          {custFilterData.type_data.map((item, index) =>
                            item.type === 'FTE' ?
                              <div className="btn-group me-2" role="group" aria-label="First group">
                                <button type="button" className={filterValue.project_type === 'FTE' ? 'btn btn-primary active' : 'btn btn-primary'} name="project_type" onClick={(e) => setFilterValue({ ...filterValue, [e.target.name]: 'FTE' })}>FTE</button>
                              </div>
                              : item.type === 'PAYG' &&
                              <div className="btn-group me-2" role="group" aria-label="First group">
                                <button type="button" className={filterValue.project_type === 'PAYG' ? 'btn btn-primary active' : 'btn btn-primary'} name="project_type" onClick={(e) => setFilterValue({ ...filterValue, [e.target.name]: 'PAYG' })}>PayG</button>
                              </div>
                          )}
                        </div>
                        : ''
                      }
                      <div className="text-right mt10">
                        <button type="button" className="btn btn-primary" onClick={() => handleFilterSearch()}>Search</button>
                      </div>
                    </div>
                  }
                </div>
                : ''}

            </div>
          </div>

        </div>
      </div>
      <div className='row mt20' id='job-list'>
        {custTaskList !== null && !isSearchFilter && projectType !== 'fteMain' ?
          <ul className={viewType === 'grid' ? '' : 'listView'}>
            {/* {custTaskList.map((item, index) => */}
            {items.map((item, index) =>
              <li key={index}>
                {viewType === 'grid' ?
                  <>
                    <div className='d-flex justify-content-between'>
                      <div>
                        <h4>{item.task_title}</h4>
                        <small>{item.billing_mode + '-' + item.task_code}</small>
                      </div>
                      <div>
                        <span className={
                          item.status_title === 'Rejected' && 'warning-box' || item.status_title === 'No Updates' && 'warning-box' || item.status_title === 'Waiting on Info' && 'warning-box' ||
                          item.status_title === 'Finalised' && 'text-green success-box' ||
                          item.status_title === '2nd Stage Queries Sent' && 'querysent2-box' ||
                          item.status_title === 'Sent for Audit' && 'sentforAudit-box'
                        } style={{ fontSize: 16, fontWeight: 700 }}>
                          {item.status_title === 'Finalised' && <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#66ac49"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" /></svg>}
                          {item.status_title === 'Waiting on Info' && <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#f88d6c"><path d="M4.47 21h15.06c1.54 0 2.5-1.67 1.73-3L13.73 4.99c-.77-1.33-2.69-1.33-3.46 0L2.74 18c-.77 1.33.19 3 1.73 3zM12 14c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1s1 .45 1 1v2c0 .55-.45 1-1 1zm1 4h-2v-2h2v2z" /></svg>}
                          {item.status_title === 'No Updates' && <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#f88d6c"><path d="M4.47 21h15.06c1.54 0 2.5-1.67 1.73-3L13.73 4.99c-.77-1.33-2.69-1.33-3.46 0L2.74 18c-.77 1.33.19 3 1.73 3zM12 14c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1s1 .45 1 1v2c0 .55-.45 1-1 1zm1 4h-2v-2h2v2z" /></svg>}
                          {item.status_title === '2nd Stage Queries Sent' && <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="20px" viewBox="0 0 24 24" width="20px" fill="#4f2bdc"><g><path d="M0,0h24v24H0V0z" fill="none" /></g><g><path d="M19.41,7.41l-4.83-4.83C14.21,2.21,13.7,2,13.17,2H6C4.9,2,4.01,2.9,4.01,4L4,20c0,1.1,0.89,2,1.99,2H18c1.1,0,2-0.9,2-2 V8.83C20,8.3,19.79,7.79,19.41,7.41z M10.23,17.29l-2.12-2.12c-0.39-0.39-0.39-1.02,0-1.41l0,0c0.39-0.39,1.02-0.39,1.41,0 l1.41,1.41l3.54-3.54c0.39-0.39,1.02-0.39,1.41,0l0,0c0.39,0.39,0.39,1.02,0,1.41l-4.24,4.24C11.26,17.68,10.62,17.68,10.23,17.29z M14,9c-0.55,0-1-0.45-1-1V3.5L18.5,9H14z" /></g></svg>}
                          {item.status_title === 'Sent for Audit' && <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="20px" viewBox="0 0 24 24" width="20px" fill="#023e62"><rect fill="none" height="24" width="24" /><path d="M15.29,17.29c0.39,0.39,1.02,0.39,1.41,0l4.59-4.59c0.39-0.39,0.39-1.02,0-1.41L16.7,6.7c-0.39-0.39-1.02-0.39-1.41,0 c-0.38,0.39-0.39,1.03,0,1.42L18.17,11H7c-0.55,0-1,0.45-1,1s0.45,1,1,1h11.17l-2.88,2.88C14.9,16.27,14.9,16.9,15.29,17.29z M3,18 c0.55,0,1-0.45,1-1V7c0-0.55-0.45-1-1-1S2,6.45,2,7v10C2,17.55,2.45,18,3,18z" /></svg>}
                          &nbsp;&nbsp;{item.status_title}
                        </span>
                        {/* <span style={{ position: "relative" }}>
                          <span style={{ marginLeft: 10, cursor: 'pointer', position: 'absolute', right: '-20px' }} onClick={() => handleProjOptions(item)}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20px" width="20px" fill="#a7a7a7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-more-vertical"><circle cx="12" cy="12" r="1" /><circle cx="12" cy="5" r="1" /><circle cx="12" cy="19" r="1" /></svg></span>
                          {projectOptionsShow.length !== 0 &&
                            projectOptionsShow[index].moreoption &&
                            <ul className="filterOptions">
                              <li><svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#2a9bdb"><path d="M0 0h24v24H0z" fill="none"></path><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path></svg>&nbsp;&nbsp;<span>Download Log Hrs</span></li>
                              <li><svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#2a9bdb"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-.4 4.25l-7.07 4.42c-.32.2-.74.2-1.06 0L4.4 8.25c-.25-.16-.4-.43-.4-.72 0-.67.73-1.07 1.3-.72L12 11l6.7-4.19c.57-.35 1.3.05 1.3.72 0 .29-.15.56-.4.72z" /></svg>&nbsp;&nbsp;<span>Email</span></li>
                              <li><svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#2a9bdb"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z" /></svg>&nbsp;&nbsp;<span>Ask Query</span></li>
                            </ul>
                          }
                        </span> */}
                      </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center  mt20'>
                      <div className="col-sm-7 border-dotted">
                        <p className="text-gray">Date</p>
                        <div className="d-flex justify-content-between">
                          <h5 style={{ fontSize: '13px', fontWeight: 700 }}>{item.start_date} <span className="text-gray"><br />Start Date</span></h5>
                          <h5 style={{ fontSize: '13px', fontWeight: 700 }}>{item.end_date} <span className="text-gray"><br />End Date</span></h5>
                        </div>
                      </div>
                      {item.billing_mode === 'PAYG' &&
                        <div className="col-sm-4 border-dotted">
                          <p className="text-gray">Fees</p>
                          <div className="d-flex">
                            <h5 style={{ fontSize: '13px', fontWeight: 700 }}>${item.task_fees}</h5>
                          </div>
                        </div>
                      }
                    </div>

                    <div className="progress mt10" style={{ height: 10 }}>
                      <div className="progress-bar" role="progressbar" style={{ width: `${item.task_percentange}%` }} aria-valuenow={25} aria-valuemin="0" aria-valuemax='100'></div>
                    </div>

                    <div className='row mt30'>
                      <div className='col'>
                        <h3>{item.year}</h3>
                      </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center mt10'>
                      <div>
                        <div className={`circleName thumb${index} d-flex align-items-center`}><span style={{ marginRight: 10 }}>{item.assigned_to !== undefined && item.assigned_to.slice(0, 1)}</span>{item.assigned_to}</div>
                      </div>
                      <div>
                        <button className='btn btn-primary' onClick={() => { navigate(`/projects/${item.task_id}`, { replace: true }) }}>Click for more</button>
                      </div>
                    </div>
                  </>
                  :
                  <>
                    <div className='row d-flex justify-content-between align-items-center'>
                      <div className="col-10 col-md-2">
                        <h4 className="col-6 col-md-12">{item.task_title}</h4>
                        <small className="col-6 col-md-12">{item.task_code}</small>
                        <h4 className="col-10 col-md-12">{item.billing_mode}</h4>
                      </div>
                      <div className="col-10 col-md-4">
                        <div className="row d-flex justify-content-between">
                          <h4 className="col-6 col-md-6"><span className="text-gray d-block">Start Date</span>{item.start_date}</h4>
                          <h4 className="col-6 col-md-6"><span className="text-gray d-block ">End Date</span>{item.end_date}</h4>
                        </div>
                      </div>
                      {item.billing_mode === 'PAYG' &&
                        <div className="col-10 col-md-2">
                          <div className="d-flex justify-content-between">
                            <h4><span className="text-gray d-block">Fees</span>{item.task_fees}</h4>
                          </div>
                        </div>
                      }
                      <div className="col-10 col-md-2">
                        <span className={
                          item.status_title === 'Rejected' && 'warning-text' || item.status_title === 'No Updates' && 'warning-text' || item.status_title === 'Waiting on Info' && 'warning-text' ||
                          item.status_title === 'Finalised' && 'text-green success-text' ||
                          item.status_title === '2nd Stage Queries Sent' && 'querysent2-text' ||
                          item.status_title === 'Sent for Audit' && 'sentforAudit-text'
                        } style={{ fontSize: 16, fontWeight: 700 }}>
                          {item.status_title === 'Finalised' && <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#66ac49"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" /></svg>}
                          {item.status_title === 'Waiting on Info' && <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#f88d6c"><path d="M4.47 21h15.06c1.54 0 2.5-1.67 1.73-3L13.73 4.99c-.77-1.33-2.69-1.33-3.46 0L2.74 18c-.77 1.33.19 3 1.73 3zM12 14c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1s1 .45 1 1v2c0 .55-.45 1-1 1zm1 4h-2v-2h2v2z" /></svg>}
                          {item.status_title === 'No Updates' && <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#f88d6c"><path d="M4.47 21h15.06c1.54 0 2.5-1.67 1.73-3L13.73 4.99c-.77-1.33-2.69-1.33-3.46 0L2.74 18c-.77 1.33.19 3 1.73 3zM12 14c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1s1 .45 1 1v2c0 .55-.45 1-1 1zm1 4h-2v-2h2v2z" /></svg>}
                          {item.status_title === '2nd Stage Queries Sent' && <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="20px" viewBox="0 0 24 24" width="20px" fill="#4f2bdc"><g><path d="M0,0h24v24H0V0z" fill="none" /></g><g><path d="M19.41,7.41l-4.83-4.83C14.21,2.21,13.7,2,13.17,2H6C4.9,2,4.01,2.9,4.01,4L4,20c0,1.1,0.89,2,1.99,2H18c1.1,0,2-0.9,2-2 V8.83C20,8.3,19.79,7.79,19.41,7.41z M10.23,17.29l-2.12-2.12c-0.39-0.39-0.39-1.02,0-1.41l0,0c0.39-0.39,1.02-0.39,1.41,0 l1.41,1.41l3.54-3.54c0.39-0.39,1.02-0.39,1.41,0l0,0c0.39,0.39,0.39,1.02,0,1.41l-4.24,4.24C11.26,17.68,10.62,17.68,10.23,17.29z M14,9c-0.55,0-1-0.45-1-1V3.5L18.5,9H14z" /></g></svg>}
                          {item.status_title === 'Sent for Audit' && <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="20px" viewBox="0 0 24 24" width="20px" fill="#023e62"><rect fill="none" height="24" width="24" /><path d="M15.29,17.29c0.39,0.39,1.02,0.39,1.41,0l4.59-4.59c0.39-0.39,0.39-1.02,0-1.41L16.7,6.7c-0.39-0.39-1.02-0.39-1.41,0 c-0.38,0.39-0.39,1.03,0,1.42L18.17,11H7c-0.55,0-1,0.45-1,1s0.45,1,1,1h11.17l-2.88,2.88C14.9,16.27,14.9,16.9,15.29,17.29z M3,18 c0.55,0,1-0.45,1-1V7c0-0.55-0.45-1-1-1S2,6.45,2,7v10C2,17.55,2.45,18,3,18z" /></svg>}
                          &nbsp;&nbsp;{item.status_title}
                        </span>
                        <div className="progress mt10" style={{ height: 10 }}>
                          <div className="progress-bar" role="progressbar" style={{ width: `${25}%` }} aria-valuenow={25} aria-valuemin="0" aria-valuemax='100'></div>
                        </div>
                      </div>
                      {/* <div className='col-10 col-md-1'>
                        <h3>{item.year}</h3>
                      </div> */}
                      <div className="col-10 col-md-2 hidden-md">
                        <div className={`circleName thumb${index} d-flex align-items-center`}><span style={{ marginRight: 10 }}>{item.assigned_to.slice(0, 1)}</span>{item.assigned_to}</div>
                      </div>
                      <div className="col-10 col-md-2 mt10">
                        <button className='btn btn-primary' onClick={() => { navigate(`/projects/${item.task_id}`, { replace: true }) }}>Click for more</button>
                      </div>
                    </div>
                  </>
                }
              </li>
            )}
          </ul>
          :
          isSearchFilter && searchArray.length > 0 && projectType !== 'fteMain' ?
            <ul className={viewType === 'grid' ? '' : 'listView'}>
              {/* {custTaskList.map((item, index) => */}
              {searchArray.map((item, index) =>
                <li key={index}>
                  {viewType === 'grid' ?
                    <>
                      <div className='d-flex justify-content-between'>
                        <div>
                          <h4>{item.task_title}</h4>
                          <small>{item.billing_mode + '-' + item.task_code}</small>
                        </div>
                        <div>
                          <span className={
                            item.status_title === 'Rejected' && 'warning-box' || item.status_title === 'No Updates' && 'warning-box' || item.status_title === 'Waiting on Info' && 'warning-box' ||
                            item.status_title === 'Finalised' && 'text-green success-box' ||
                            item.status_title === '2nd Stage Queries Sent' && 'querysent2-box' ||
                            item.status_title === 'Sent for Audit' && 'sentforAudit-box'
                          } style={{ fontSize: 16, fontWeight: 700 }}>
                            {item.status_title === 'Finalised' && <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#66ac49"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" /></svg>}
                            {item.status_title === 'Waiting on Info' && <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#f88d6c"><path d="M4.47 21h15.06c1.54 0 2.5-1.67 1.73-3L13.73 4.99c-.77-1.33-2.69-1.33-3.46 0L2.74 18c-.77 1.33.19 3 1.73 3zM12 14c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1s1 .45 1 1v2c0 .55-.45 1-1 1zm1 4h-2v-2h2v2z" /></svg>}
                            {item.status_title === 'No Updates' && <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#f88d6c"><path d="M4.47 21h15.06c1.54 0 2.5-1.67 1.73-3L13.73 4.99c-.77-1.33-2.69-1.33-3.46 0L2.74 18c-.77 1.33.19 3 1.73 3zM12 14c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1s1 .45 1 1v2c0 .55-.45 1-1 1zm1 4h-2v-2h2v2z" /></svg>}
                            {item.status_title === '2nd Stage Queries Sent' && <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="20px" viewBox="0 0 24 24" width="20px" fill="#4f2bdc"><g><path d="M0,0h24v24H0V0z" fill="none" /></g><g><path d="M19.41,7.41l-4.83-4.83C14.21,2.21,13.7,2,13.17,2H6C4.9,2,4.01,2.9,4.01,4L4,20c0,1.1,0.89,2,1.99,2H18c1.1,0,2-0.9,2-2 V8.83C20,8.3,19.79,7.79,19.41,7.41z M10.23,17.29l-2.12-2.12c-0.39-0.39-0.39-1.02,0-1.41l0,0c0.39-0.39,1.02-0.39,1.41,0 l1.41,1.41l3.54-3.54c0.39-0.39,1.02-0.39,1.41,0l0,0c0.39,0.39,0.39,1.02,0,1.41l-4.24,4.24C11.26,17.68,10.62,17.68,10.23,17.29z M14,9c-0.55,0-1-0.45-1-1V3.5L18.5,9H14z" /></g></svg>}
                            {item.status_title === 'Sent for Audit' && <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="20px" viewBox="0 0 24 24" width="20px" fill="#023e62"><rect fill="none" height="24" width="24" /><path d="M15.29,17.29c0.39,0.39,1.02,0.39,1.41,0l4.59-4.59c0.39-0.39,0.39-1.02,0-1.41L16.7,6.7c-0.39-0.39-1.02-0.39-1.41,0 c-0.38,0.39-0.39,1.03,0,1.42L18.17,11H7c-0.55,0-1,0.45-1,1s0.45,1,1,1h11.17l-2.88,2.88C14.9,16.27,14.9,16.9,15.29,17.29z M3,18 c0.55,0,1-0.45,1-1V7c0-0.55-0.45-1-1-1S2,6.45,2,7v10C2,17.55,2.45,18,3,18z" /></svg>}
                            &nbsp;&nbsp;{item.status_title}
                          </span>
                          {/* <span style={{ position: "relative" }}>
                          <span style={{ marginLeft: 10, cursor: 'pointer', position: 'absolute', right: '-20px' }} onClick={() => handleProjOptions(item)}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20px" width="20px" fill="#a7a7a7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-more-vertical"><circle cx="12" cy="12" r="1" /><circle cx="12" cy="5" r="1" /><circle cx="12" cy="19" r="1" /></svg></span>
                          {projectOptionsShow.length !== 0 &&
                            projectOptionsShow[index].moreoption &&
                            <ul className="filterOptions">
                              <li><svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#2a9bdb"><path d="M0 0h24v24H0z" fill="none"></path><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path></svg>&nbsp;&nbsp;<span>Download Log Hrs</span></li>
                              <li><svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#2a9bdb"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-.4 4.25l-7.07 4.42c-.32.2-.74.2-1.06 0L4.4 8.25c-.25-.16-.4-.43-.4-.72 0-.67.73-1.07 1.3-.72L12 11l6.7-4.19c.57-.35 1.3.05 1.3.72 0 .29-.15.56-.4.72z" /></svg>&nbsp;&nbsp;<span>Email</span></li>
                              <li><svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#2a9bdb"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z" /></svg>&nbsp;&nbsp;<span>Ask Query</span></li>
                            </ul>
                          }
                        </span> */}
                        </div>
                      </div>
                      <div className='d-flex justify-content-between align-items-center  mt20'>
                        <div className="col-sm-7 border-dotted">
                          <p className="text-gray">Date</p>
                          <div className="d-flex justify-content-between">
                            <h5 style={{ fontSize: '13px', fontWeight: 700 }}>{item.start_date} <span className="text-gray"><br />Start Date</span></h5>
                            <h5 style={{ fontSize: '13px', fontWeight: 700 }}>{item.end_date} <span className="text-gray"><br />End Date</span></h5>
                          </div>
                        </div>
                        {item.billing_mode === 'PAYG' &&
                          <div className="col-sm-4 border-dotted">
                            <p className="text-gray">Fees</p>
                            <div className="d-flex">
                              <h5 style={{ fontSize: '13px', fontWeight: 700 }}>${item.task_fees}</h5>
                            </div>
                          </div>
                        }
                      </div>

                      <div className="progress mt10" style={{ height: 10 }}>
                        <div className="progress-bar" role="progressbar" style={{ width: `${item.task_percentange}%` }} aria-valuenow={25} aria-valuemin="0" aria-valuemax='100'></div>
                      </div>
                      <div className='row mt30'>
                        <div className='col'>
                          <h3>{item.year}</h3>
                        </div>
                      </div>
                      <div className='d-flex justify-content-between align-items-center mt10'>
                        <div>
                          <div className={`circleName thumb${index} d-flex align-items-center`}><span style={{ marginRight: 10 }}>{item.assigned_to.slice(0, 1)}</span>{item.assigned_to}</div>
                        </div>
                        <div>
                          <button className='btn btn-primary' onClick={() => { navigate(`/projects/${item.task_id}`, { replace: true }) }}>Click for more</button>
                        </div>
                      </div>
                    </>
                    :
                    <>
                      <div className='row d-flex justify-content-between align-items-center'>
                        <div className="col-10 col-md-2">
                          <h4 className="col-6 col-md-12">{item.task_title}</h4>
                          <small className="col-6 col-md-12">{item.task_code}</small>
                          <h4 className="col-10 col-md-12">{item.billing_mode}</h4>
                        </div>
                        <div className="col-10 col-md-4">
                          <div className="row d-flex justify-content-between">
                            <h4 className="col-6 col-md-6"><span className="text-gray d-block">Start Date</span>{item.start_date}</h4>
                            <h4 className="col-6 col-md-6"><span className="text-gray d-block ">End Date</span>{item.end_date}</h4>
                          </div>
                        </div>
                        {item.billing_mode === 'PAYG' &&
                          <div className="col-10 col-md-2">
                            <div className="d-flex justify-content-between">
                              <h4><span className="text-gray d-block">Fees</span>{item.task_fees}</h4>
                            </div>
                          </div>
                        }
                        <div className="col-10 col-md-2">
                          <span className={
                            item.status_title === 'Rejected' && 'warning-text' || item.status_title === 'No Updates' && 'warning-text' || item.status_title === 'Waiting on Info' && 'warning-text' ||
                            item.status_title === 'Finalised' && 'text-green success-text' ||
                            item.status_title === '2nd Stage Queries Sent' && 'querysent2-text' ||
                            item.status_title === 'Sent for Audit' && 'sentforAudit-text'
                          } style={{ fontSize: 16, fontWeight: 700 }}>
                            {item.status_title === 'Finalised' && <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#66ac49"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" /></svg>}
                            {item.status_title === 'Waiting on Info' && <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#f88d6c"><path d="M4.47 21h15.06c1.54 0 2.5-1.67 1.73-3L13.73 4.99c-.77-1.33-2.69-1.33-3.46 0L2.74 18c-.77 1.33.19 3 1.73 3zM12 14c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1s1 .45 1 1v2c0 .55-.45 1-1 1zm1 4h-2v-2h2v2z" /></svg>}
                            {item.status_title === 'No Updates' && <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#f88d6c"><path d="M4.47 21h15.06c1.54 0 2.5-1.67 1.73-3L13.73 4.99c-.77-1.33-2.69-1.33-3.46 0L2.74 18c-.77 1.33.19 3 1.73 3zM12 14c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1s1 .45 1 1v2c0 .55-.45 1-1 1zm1 4h-2v-2h2v2z" /></svg>}
                            {item.status_title === '2nd Stage Queries Sent' && <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="20px" viewBox="0 0 24 24" width="20px" fill="#4f2bdc"><g><path d="M0,0h24v24H0V0z" fill="none" /></g><g><path d="M19.41,7.41l-4.83-4.83C14.21,2.21,13.7,2,13.17,2H6C4.9,2,4.01,2.9,4.01,4L4,20c0,1.1,0.89,2,1.99,2H18c1.1,0,2-0.9,2-2 V8.83C20,8.3,19.79,7.79,19.41,7.41z M10.23,17.29l-2.12-2.12c-0.39-0.39-0.39-1.02,0-1.41l0,0c0.39-0.39,1.02-0.39,1.41,0 l1.41,1.41l3.54-3.54c0.39-0.39,1.02-0.39,1.41,0l0,0c0.39,0.39,0.39,1.02,0,1.41l-4.24,4.24C11.26,17.68,10.62,17.68,10.23,17.29z M14,9c-0.55,0-1-0.45-1-1V3.5L18.5,9H14z" /></g></svg>}
                            {item.status_title === 'Sent for Audit' && <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="20px" viewBox="0 0 24 24" width="20px" fill="#023e62"><rect fill="none" height="24" width="24" /><path d="M15.29,17.29c0.39,0.39,1.02,0.39,1.41,0l4.59-4.59c0.39-0.39,0.39-1.02,0-1.41L16.7,6.7c-0.39-0.39-1.02-0.39-1.41,0 c-0.38,0.39-0.39,1.03,0,1.42L18.17,11H7c-0.55,0-1,0.45-1,1s0.45,1,1,1h11.17l-2.88,2.88C14.9,16.27,14.9,16.9,15.29,17.29z M3,18 c0.55,0,1-0.45,1-1V7c0-0.55-0.45-1-1-1S2,6.45,2,7v10C2,17.55,2.45,18,3,18z" /></svg>}
                            &nbsp;&nbsp;{item.status_title}
                          </span>
                          <div className="progress mt10" style={{ height: 10 }}>
                            <div className="progress-bar" role="progressbar" style={{ width: `${25}%` }} aria-valuenow={25} aria-valuemin="0" aria-valuemax='100'></div>
                          </div>
                        </div>
                        {/* <div className='col-10 col-md-1'>
                        <h3>{item.year}</h3>
                      </div> */}
                        <div className="col-10 col-md-2 hidden-md">
                          <div className={`circleName thumb${index} d-flex align-items-center`}><span style={{ marginRight: 10 }}>{item.assigned_to.slice(0, 1)}</span>{item.assigned_to}</div>
                        </div>
                        <div className="col-10 col-md-2">
                          <button className='btn btn-primary' onClick={() => { navigate(`/projects/${item.task_id}`, { replace: true }) }}>Click for more</button>
                        </div>
                      </div>
                    </>
                  }
                </li>
              )}
            </ul>
            : projectType !== 'fteMain' && <div className="text-center" >No Data Available</div>
        }

        {projectType === 'fteMain' && fteMainItems !== null && !isSearchFilter ?
          <ul className={viewType === 'grid' ? '' : 'listView'}>
            {/* {custTaskList.map((item, index) => */}
            {fteMainItems.map((item, index) =>
              <li key={index}>
                {viewType === 'grid' ?
                  <>
                    <div className='d-flex justify-content-between'>
                      <div>
                        <h4>{item.task_title}</h4>
                        <small>{item.task_code}</small>
                      </div>
                    </div>
                    <div className="row col-sm-6 border-dotted mt10">
                      <p className="text-gray">Fees</p>
                      <div className="d-flex">
                        <h5 style={{ fontSize: '13px', fontWeight: 700 }}>${item.task_fees}</h5>
                      </div>
                    </div>
                    <div className='row mt10'>
                      <div className='col'>
                        <h3>{item.month + ' ' + item.year}</h3>
                      </div>
                    </div>
                  </>
                  :
                  <>
                    <div className='row d-flex justify-content-between align-items-center'>
                      <div className="col-10 col-md-2">
                        <h4 className="col-6 col-md-12">{item.task_title}</h4>
                        <small className="col-6 col-md-12">{item.task_code}</small>
                      </div>
                      <div className="col-sm-6 border-dotted">
                        <p className="text-gray">Fees</p>
                        <div className="d-flex">
                          <h5 style={{ fontSize: '13px', fontWeight: 700 }}>${item.task_fees}</h5>
                        </div>
                      </div>
                      <div className='col-10 col-md-1'>
                        <h3>{item.month + ' ' + item.year}</h3>
                      </div>
                    </div>
                  </>
                }
              </li>
            )}
          </ul>
          :
          <ul className={viewType === 'grid' ? '' : 'listView'}>
            {/* {custTaskList.map((item, index) => */}
            {fteMainSearchArray.map((item, index) =>
              <li key={index}>
                {viewType === 'grid' ?
                  <>
                    <div className='d-flex justify-content-between'>
                      <div>
                        <h4>{item.task_title}</h4>
                        <small>{item.task_code}</small>
                      </div>
                    </div>
                    <div className='row mt30'>
                      <div className='col'>
                        <h3>{item.month + ' ' + item.year}</h3>
                      </div>
                    </div>
                  </>
                  :
                  <>
                    <div className='row d-flex justify-content-between align-items-center'>
                      <div className="col-10 col-md-2">
                        <h4 className="col-6 col-md-12">{item.task_title}</h4>
                        <small className="col-6 col-md-12">{item.task_code}</small>
                      </div>
                      <div className='col-10 col-md-1'>
                        <h3>{item.month + ' ' + item.year}</h3>
                      </div>
                    </div>
                  </>
                }
              </li>
            )}
          </ul>
        }
      </div>
      {!isSearchFilter &&
        <div className="mt20">
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next Page"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={Math.ceil(pageCount)}
            previousLabel="Previous Page"
            renderOnZeroPageCount={null}
            containerClassName="pagination justify-content-end"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            activeClassName="active"
            previousClassName="page-item"
            previousLinkClassName="page-link actbtn"
            nextClassName="page-item"
            nextLinkClassName="page-link actbtn"
            disabledClassName="disabled"
            disabledLinkClassName="disabled"
          />
        </div>
      }
    </div>
  );
}

export default Projects;
