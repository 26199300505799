import { useState, useContext, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { Link, Link as RouterLink, useNavigate } from "react-router-dom";
import { checkTokenValidity, custJobReqCancel, custJobReqList } from "../api";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { UserContext } from "../App";
import "../App.css";
import ModalView, { DialogBox } from "../common/modalView";
import DocumentsView, { DialogBox2 } from "../common/documentsView";
// import JobsDocument from './jobsDocument';
import Swal from "sweetalert2";

function JobsRequest(props) {
  const [viewType, setViewType] = useState("grid");
  const [shoDialog, setShoDialog] = useState(false);
  const [shoDialog2, setShoDialog2] = useState(false); //added by me
  const [msgDisplay, setMsgDisplay] = useState("");
  const [custJobList, setCustJobList] = useState(null);
  const [isSearchFilter, setIsSearchFilter] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchArray, setSearchArray] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [items, setItems] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  // const [pageCount, setPageCount] = useState(6);
  const userContext = useContext(UserContext);
  const [currentPage, setCurrentPage] = useState(1); //added by me
  const [projectType, setProjectType] = useState("ALL"); //added by me
  const navigate = useNavigate();
  const [filterValue, setFilterValue] = useState({
    //added by me
    work_category: "",
    project_status: "",
    project_type: "",
    job_status: "Requested",
  });

  const [filterShow, setFilterShow] = useState(false);

  // const [projectOptionsShow, setProjectOptionsShow] = useState([]);
  const allowJobRequest = JSON.parse(sessionStorage.getItem("userInfo"));

  useEffect(() => {
    if (custJobList === null && userContext !== null) {
      //let tempData = userContext.data;
      custJobReqList()
        .then((response) => {
          if (checkTokenValidity(response.data)) {
            console.log("setCustJobList", response.data.data.resultData);
            setCustJobList(response.data.data.resultData);
            setPageCount(response.data.data.resultData.length / itemsPerPage);
            setItems(response.data.data.resultData.slice(0, itemsPerPage));
          }
        })
        .catch((e) => console.log(e));
    }
  }, [userContext]);

  const handleItemList = (selectedPage) => {
    const tempData = custJobList;
    if (tempData !== null) {
      const dataShow = tempData.slice(
        itemsPerPage * selectedPage - itemsPerPage,
        itemsPerPage * selectedPage
      );
      setItems(dataShow);
    }
  };

  const handleDialogue = () => {
    shoDialog ? setShoDialog(false) : setShoDialog(true);
  };
  const handleDialogue2 = () => {
    ///added by me
    shoDialog2 ? setShoDialog2(false) : setShoDialog2(true);
  };

  const handleJobReqCancel = (id) => {
    console.log("job cancel request id: ", id);
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure want to cancel Job Request.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            Swal.fire({
              title: "Cancelling...",
              allowOutsideClick: false,
              onBeforeOpen: () => {
                Swal.showLoading();
              },
              showConfirmButton: false,
            });
            custJobReqCancel(id).then((response) => {
              if (response.data.status) {
                setMsgDisplay(response.data);
                // setShoDialog(true);
                console.log(response.data.message);
                custJobReqList()
                  .then((response) => {
                    console.log(
                      "setCustJobList",
                      response.data.data.resultData
                    );
                    setCustJobList(response.data.data.resultData);
                    setPageCount(
                      response.data.data.resultData.length / itemsPerPage
                    );
                    setItems(
                      response.data.data.resultData.slice(0, itemsPerPage)
                    );
                    Swal.close();
                    window.location.reload(); //page refresh on Job cancellation error solved on 31 oct 2023
                  })
                  .catch((e) => console.log(e));
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => {
            console.log("no clicked");
          },
        },
      ],
    });
  };

  const handleSearch = (event) => {
    const tempData = custJobList;
    setSearchValue(event.target.value);
    event.target.value !== ""
      ? setIsSearchFilter(true)
      : setIsSearchFilter(false);
    let searchArrayData = tempData.filter(
      (item) =>
        item.acc_id.toUpperCase().includes(event.target.value.toUpperCase()) ||
        item.customer_id
          .toUpperCase()
          .includes(event.target.value.toUpperCase()) ||
        item.job_request_id
          .toUpperCase()
          .includes(event.target.value.toUpperCase()) ||
        item.job_title.toUpperCase().includes(event.target.value.toUpperCase())
    );
    setSearchArray(searchArrayData);
    console.log("searchArray: ", searchArray);
    console.log("handle search: ", event.target.value);
  };

  const handleRefresh = (event) => {
    custJobReqList()
      .then((response) => {
        if (checkTokenValidity(response.data)) {
          console.log("setCustJobList", response.data.data.resultData);
          setCustJobList(response.data.data.resultData);
          setPageCount(response.data.data.resultData.length / itemsPerPage);
          setItems(response.data.data.resultData.slice(0, itemsPerPage));
        }
      })
      .catch((e) => console.log(e));
  };

  const handlePageClick = (event) => {
    console.log(event);
    const selectedNumber = event.selected + 1;
    handleItemList(selectedNumber);
    // setCurrentPage(selectedNumber)
  };

  const handleItemsPerPage = (data) => {
    const temp = custJobList;
    // const temp = searchArray
    console.log("temp 128", temp);
    console.log("custJobList 129", custJobList);
    console.log("data 130", data);
    setItemsPerPage(data);
    if (data !== "all") {
      console.log("data", data);
      setPageCount(temp.length / data);
      setItems(temp.slice(0, data));
      console.log("pageCount: ", pageCount);
      console.log("items: ", items);
    } else {
      setPageCount(temp.length);
      setItems(temp);
    }
  };

  useEffect(() => {
    // Initialize the component with a default value for data.
    const initialData = "Requested"; // Change this to the initial value you want
    handleTaskOpenClose(initialData);
  }, []);

  const [loading, setLoading] = useState(false);
  // Job Requests new tab created to view jobs as Open and Closed on 31 oct 2023
  // Loader added on click of open and closed task view on 31 oct 2023
  const handleTaskOpenClose = (data) => {
    setLoading(true);
    console.log(loading, "loading");
    setFilterValue({ ...filterValue, job_status: data });
    let temp = { ...filterValue, job_status: data };
    console.log("response", custJobReqList);
    console.log("temp: ", temp);
    setFilterShow(false);
    setIsSearchFilter(true);
    custJobReqList(temp.job_status)
      .then((response) => {
        const filteredData = response.data.data.resultData;
        console.log(filteredData);
        // Filter the data based on job_status
        let filteredByJobStatus;

        if (data === "Converted To Job") {
          filteredByJobStatus = filteredData.filter(
            (item) => item.job_status !== "Requested"
          );
        } else {
          filteredByJobStatus = filteredData.filter(
            (item) => item.job_status === data
          );
        }
        console.log(filteredByJobStatus);
        // Update the component state with the filtered data
        setSearchArray(filteredByJobStatus);
        setPageCount(filteredByJobStatus.length / itemsPerPage);
        setItems(filteredByJobStatus.slice(0, itemsPerPage));
        // let projectOptions = [];
        // response.data.data.resultData.forEach(element => {
        //   projectOptions.push({ job_request_id: element.job_request_id, moreoption: false });
        // });
        console.log("items per page", items);
        console.log("items per page", itemsPerPage);
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };
  //////////All added by me
  const handleAddMoreDocuments = () => {
    console.log("Add documents");
  };
  //   const [showModalDialog, setShowModalDialog] = useState(false);
  // const [showDocumentsDialog, setShowDocumentsDialog] = useState(false);

  // const handleJobRequest = (type) => {
  //   if (type === 'modal') {
  //     // props.handleJobRequest()
  //     setShowModalDialog(true);

  //   } else if (type === 'documents') {
  //     setShowDocumentsDialog(true);
  //     // props.handleJobRequest2()
  //    console.log("type------------------------>",type)
  //   }
  // };

  return (
    <div className="container-fluid mt50 padlr50">
      <div className="row">
        <div className="col">
          <h2 className="text-primary">Job Request</h2>
        </div>
        {allowJobRequest !== null &&
          allowJobRequest.allow_job_request === "1" && (
            <div className="col-sm-6 text-right">
              <button
                type="button"
                className="btn-plain"
                onClick={handleRefresh}
                style={{ marginRight: "10px" }}
              >
                <i
                  className="fa fa-refresh"
                  aria-hidden="true"
                  title="Refresh"
                ></i>
              </button>
              <button
                type="button"
                className="btn btn-primary"
                // onClick={() => handleJobRequest('modal')}
                onClick={props.handleJobRequest}
              >
                New Job Request
              </button>
            </div>
          )}
      </div>
      <div className="row justify-content-between align-items-center mt30">
        <div className="col-12 text-right searchfilter">
          <div className="row ">
            <div
              className="col-12 col-md-5 col-lg-9 col-xl-8 mt10"
              style={{ position: "relative" }}
            >
              <input
                type="text"
                className="form-control"
                value={searchValue}
                placeholder="Search"
                onChange={handleSearch}
              />
              <span className="searchicon2"></span>
            </div>

            {/* {custJobList !== null &&  */}
            <div className="col-12 col-md-5 col-lg-9 col-xl-8 mt10">
              <span className="mob-hidden hidden-md d-flex align-items-center">
                Job Requests : &nbsp;
                <ul className="gridlisticon">
                  {/* Job Requests new tab created to view jobs as Open and Closed on 31 oct 2023 */}
                  <li
                    className={
                      filterValue.job_status === "Requested" ? "active" : ""
                    }
                    onClick={() => handleTaskOpenClose("Requested")}
                  >
                    Open
                  </li>
                  <li
                    className={
                      filterValue.job_status === "Converted To Job"
                        ? "active"
                        : ""
                    }
                    onClick={() => handleTaskOpenClose("Converted To Job")}
                  >
                    Closed
                  </li>
                </ul>
              </span>
            </div>
            {/* }  */}
            {/* &nbsp;&nbsp; View as: &nbsp;&nbsp;  */}
            <div className="col-12 col-md-7 col-lg-3 col-xl-4 mt10">
              {/* d-flex justify-content-between align-items-center max-width290 */}
              <div className="d-flex justify-content-end align-items-center">
                View as:&nbsp;&nbsp;
                <ul>
                  <li
                    className={viewType === "grid" ? "active" : ""}
                    onClick={() => setViewType("grid")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      enableBackground="new 0 0 24 24"
                      height="24px"
                      viewBox="0 0 24 24"
                      width="24px"
                      fill="#000000"
                    >
                      <g>
                        <rect fill="none" height="24" width="24" />
                      </g>
                      <g>
                        <g>
                          <path d="M5,11h4c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2H5C3.9,3,3,3.9,3,5v4C3,10.1,3.9,11,5,11z" />
                          <path d="M5,21h4c1.1,0,2-0.9,2-2v-4c0-1.1-0.9-2-2-2H5c-1.1,0-2,0.9-2,2v4C3,20.1,3.9,21,5,21z" />
                          <path d="M13,5v4c0,1.1,0.9,2,2,2h4c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2h-4C13.9,3,13,3.9,13,5z" />
                          <path d="M15,21h4c1.1,0,2-0.9,2-2v-4c0-1.1-0.9-2-2-2h-4c-1.1,0-2,0.9-2,2v4C13,20.1,13.9,21,15,21z" />
                        </g>
                      </g>
                    </svg>
                  </li>
                  <li
                    className={viewType === "list" ? "active" : ""}
                    onClick={() => setViewType("list")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      enableBackground="new 0 0 24 24"
                      height="24px"
                      viewBox="0 0 24 24"
                      width="24px"
                      fill="#000000"
                    >
                      <rect fill="none" height="24" width="24" />
                      <path d="M4,14h2c0.55,0,1-0.45,1-1v-2c0-0.55-0.45-1-1-1H4c-0.55,0-1,0.45-1,1v2C3,13.55,3.45,14,4,14z M4,19h2c0.55,0,1-0.45,1-1 v-2c0-0.55-0.45-1-1-1H4c-0.55,0-1,0.45-1,1v2C3,18.55,3.45,19,4,19z M4,9h2c0.55,0,1-0.45,1-1V6c0-0.55-0.45-1-1-1H4 C3.45,5,3,5.45,3,6v2C3,8.55,3.45,9,4,9z M9,14h11c0.55,0,1-0.45,1-1v-2c0-0.55-0.45-1-1-1H9c-0.55,0-1,0.45-1,1v2 C8,13.55,8.45,14,9,14z M9,19h11c0.55,0,1-0.45,1-1v-2c0-0.55-0.45-1-1-1H9c-0.55,0-1,0.45-1,1v2C8,18.55,8.45,19,9,19z M8,6v2 c0,0.55,0.45,1,1,1h11c0.55,0,1-0.45,1-1V6c0-0.55-0.45-1-1-1H9C8.45,5,8,5.45,8,6z" />
                    </svg>
                  </li>
                </ul>
                <div
                  className="d-flex justify-content-between align-items-center"
                  style={{ maxWidth: 250, minWidth: 180 }}
                >
                  &nbsp;&nbsp;Show:&nbsp;&nbsp;
                  <select
                    className="form-control"
                    style={{ maxWidth: 60 }}
                    value={itemsPerPage}
                    onChange={(e) => handleItemsPerPage(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="all">All</option>
                  </select>
                  Entries
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt20" id="job-list">
        {custJobList !== null && !isSearchFilter ? (
          <ul className={viewType === "grid" ? "" : "listView"}>
            {items.map((item, index) => (
              <li key={index}>
                {viewType === "grid" ? (
                  <>
                    <div className="d-flex justify-content-between">
                      <div>
                        <h4>
                          {item.job_title} | <span>{item.job_type}</span>
                        </h4>
                        <span
                          className="text-gray"
                          style={{color:"hsl(0deg 0% 0% / 60%)", marginRight: "10px" }}
                        >
                          {item.created_on}
                        </span>
                        <br />
                        <span style={{ color: item.priority_color_code }}>
                          {item.priority_text}
                        </span>
                      </div>
                      <div>
                        <span
                          className={
                            item.job_status !== "Rejected"
                              ? "text-green"
                              : "text-red"
                          }
                          style={{ fontSize: 16, fontWeight: 700 }}
                        >
                          {item.job_status}
                        </span>
                      </div>
                    </div>
                    <div className="row mt30">
                      <div className="col">
                        <h3>{item.year}</h3>
                      </div>
                      {/* <div className='col'>
                      {item.job_status === 'Converted To Job' && (
    <div className='btn btn-danger'> <h3>{item.task_code}</h3></div>
  )}
                       
                      </div> */}
                    </div>
                    <div className="row mt10">
                      <div className="col">
                        <small className="text-gray"  style={{color:"hsl(0deg 0% 0% / 60%)"}}>
                          {item.job_description}
                        </small>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt20">
                      <div className="text-left">
                        {item.document_files.length > 0 && (
                          <ul className="inline-list">
                            {item.document_files.map((file) => (
                              <li>
                                <a
                                  href={file.filepath}
                                  className="text-primary"
                                  download
                                  target="_blank"
                                >
                                  <i
                                    className="fa fa-download"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                      {/* Job cancellation  for converted job is still active issue solved now its not showing on 31 oct 2023*/}
                      <div className="text-right">
                        <button
                          className="btn btn-danger"
                          onClick={() =>
                            handleJobReqCancel(item.job_request_id)
                          }
                          disabled={
                            item.job_status === "Cancelled" ||
                            item.job_status === "Converted To Job"
                          }
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex justify-content-start">
                      <div className="d-flex flex-column">
                        <div>
                          <h4>{item.job_title}</h4>
                          <span
                            className="text-gray"
                            style={{color:"hsl(0deg 0% 0% / 60%)", marginRight: "10px" }}
                          >
                            {item.created_on}
                          </span>
                          <span className="text-gray"  style={{color:"hsl(0deg 0% 0% / 60%)"}}>
                            {item.priority_text}
                          </span>
                        </div>
                      </div>
                      <div className="row mt10" style={{ marginLeft: 20 }}>
                        <div>
                          <small className="text-gray"  style={{color:"hsl(0deg 0% 0% / 60%)"}}>
                            {item.job_description}
                          </small>
                        </div>
                      </div>
                    </div>
                    {/* Job cancellation  for converted job is still active issue solved now its not showing on 31 oct 2023 */}
                    <div className="d-flex align-items-center">
                      <div style={{ marginRight: 20 }}>
                        <span
                          className={
                            item.job_status !== "Rejected"
                              ? "text-green"
                              : "text-red"
                          }
                          style={{ fontSize: 16, fontWeight: 700 }}
                        >
                          {item.job_status}
                        </span>
                      </div>
                      <h3 style={{ marginRight: 20 ,width:"50px" }}>{item.year}</h3>

                      <button
                        className="btn btn-danger"
                        onClick={() => handleJobReqCancel(item.job_request_id)}
                        disabled={
                          item.job_status === "Cancelled" ||
                          item.job_status === "Converted To Job"
                        }
                      >
                        Cancel
                      </button>
                      {/* {item.job_status !== 'Cancelled' && item.job_status !== 'Converted To Job' && (
    <button className='btn btn-danger' onClick={() => handleJobReqCancel(item.job_request_id)}>Cancel</button>
  )} */}
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ul>
        ) : isSearchFilter && searchArray.length > 0 ? (
          // isSearchFilter && searchArray.length > 0 && custJobList !== null ?
          <ul className={viewType === "grid" ? "" : "listView"}>
            {searchArray.map((item, index) => (
              <li key={index}>
                {viewType === "grid" ? (
                  <>
                    <div className="d-flex justify-content-between">
                      <div>
                        <h4>
                          {item.job_title} | <span>{item.job_type}</span>
                        </h4>

                        <span
                          className="text-gray"
                          style={{ marginRight: "10px",color:"hsl(0deg 0% 0% / 60%)" }}
                        >
                          {item.created_on}
                        </span>

                        <br />
                        <span className="text-gray" style={{color:"hsl(0deg 0% 0% / 60%)"}}>{item.priority_text}</span>
                      </div>
                      <div>
                        <p
                          className={
                            item.job_status !== "Rejected" &&
                            item.job_status !== "Cancelled"
                              ? "text-green"
                              : "text-red"
                          }
                          style={{
                            fontSize: 16,
                            fontWeight: 700,width:"100%",
                            textAlign:"end"
                          
                          }}
                        >
                          {item.job_status}
                        </p>
                      
                        <span className="text-gray" style={{color:"hsl(0deg 0% 0% / 60%)" ,whiteSpace:"nowrap"}}>
                          {item.created_by_user_name}
                        </span>
                      </div>
                    </div>
                    {/* <div className='d-flex justify-content-between'> */}
                    <div className="row mt30">
                      <div className="d-flex justify-content-between">
                        <div className="text-left">
                          <h3 >{item.year}</h3>
                        </div>
                        {/* <div className='text-right '  >
                          <h4>{item.task_code}</h4>
                      </div> */}
                        {item.job_status === "Converted To Job" && (
                          <div className="text-right ">
                            <h4>
                              Task Code <br />
                            </h4>
                            <a
                              href={`https://client.ncserp.com/projects/${item.task_id}`}
                            >
                              <span>{item.task_code}</span>
                            </a>
                            {/* <button className='btn btn-primary' onClick={() => { navigate(`/projects/${item.task_id}`, { replace: true }) }}>Click for more</button> */}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mt10">
                      <div className="col">
                        <small className="text-gray" style={{color:"hsl(0deg 0% 0% / 60%)"}}>
                          {item.job_description}
                        </small>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt20">
                      {/* <div className='text-left'>
                          {item.document_files.length > 0 &&
                            <ul className='inline-list'>
                            {item.document_files.map((file) => (
                              <li><a href={file.filepath} className="text-primary" download target="_blank"><i className="fa fa-download" aria-hidden="true"></i></a></li>
                              ))}
                              </ul>
                            }
                            </div> */}
                      {/* <div className='text-center'> */}
                      <div className="text-left">
                        {/* <Link to={`/job-details/${item.job_request_id}`}> */}

                        <button
                          className="btn btn-primary btn-sm text-white"
                          // onClick={props.handleJobRequest}
                          // onClick={() => handleJobRequest('documents')}
                          // onClick={props.handleDocRequest}
                          // onClick={handleOpenDocumentsDialog}
                          disabled={
                            item.job_status === "Cancelled" ||
                            item.job_status === "Rejected"
                          }
                          onClick={() => {
                            navigate(`/job-request/${item.job_request_id}`, {
                              replace: true,
                            });
                          }}
                        >
                          {" "}
                          <i class="fa fa-folder"></i> &nbsp; Documents
                        </button>
                      </div>
                      {item.job_status === "Requested" && (
                        <div className="text-right">
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() =>
                              handleJobReqCancel(item.job_request_id)
                            }
                            disabled={
                              item.job_status === "Cancelled" ||
                              item.job_status === "Converted To Job"
                            }
                          >
                            Cancel job request
                          </button>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex justify-content-start">
                      <div className="d-flex flex-column" style={{width:"250px"}}>
                        <div>
                          <h4>{item.job_title}</h4>
                          <span
                            className="text-gray"
                            style={{color:"hsl(0deg 0% 0% / 60%)",marginRight: "10px"}}
                          >
                            {item.created_on}
                          </span>
                          {/* <span className="text-gray"  style={{color:"hsl(0deg 0% 0% / 60%)"}}>
                            {item.priority_text}
                          </span> */}
                        </div>
                      </div>
                      <div className="row " style={{ marginLeft: 20}}>
                        <div style={{display:"flex",flexDirection:"column", justifyContent:"space-between" }}>
                          <div  style={{color:"hsl(0deg 0% 0% / 60%)", fontSize: "12px" }}>
                            {item.job_description}
                          </div>
                          <div  style={{color:"hsl(0deg 0% 0% / 60%)", fontSize: "12px"}}>
                          {item.priority_text}

                          </div>
                        </div>
                      </div>
                    </div>
                    {item.job_status === "Converted To Job" && (
                      <div className="text-right " style={{ marginLeft: 200 }}>
                        <h4>
                          Task Code <br />
                        </h4>
                        <a
                          href={`https://client.ncserp.com/projects/${item.task_id}`}
                        >
                          <span>{item.task_code}</span>
                        </a>
                      </div>
                    )}
                    {/* </div> */}

                    <div className="d-flex align-items-center">
                        <div style={{ marginRight: 20 }}>
                          <span
                            className={"text-gray"}
                            style={{color:"hsl(0deg 0% 0% / 60%)", fontSize: 16, fontWeight: 700 ,whiteSpace:"nowrap"}}
                          >
                             {item.created_by_user_name}
                          </span>
                        </div>
                      <div className="text-left">
                        {/* <Link to={`/job-details/${item.job_request_id}`}> */}
                        <button
                          disabled={
                            item.job_status === "Cancelled" ||
                            item.job_status === "Rejected"
                          }
                          className="btn btn-primary btn-sm"
                          // onClick={props.handleJobRequest}
                          // onClick={() => handleJobRequest('documents')}
                          // onClick={props.handleDocRequest}
                          // onClick={handleOpenDocumentsDialog}
                          onClick={() => {
                            navigate(`/job-request/${item.job_request_id}`, {
                              replace: true,
                            });
                          }}
                          style={{
                            // backgroundColor : "#e5cfcf",
                            // padding: "10px",
                            marginRight: "10px",
                          }}
                        >
                          {" "}
                          <i class="fa fa-folder"></i> &nbsp; Documents
                        </button>
                        {/* </Link> */}
                      </div>
                      <div style={{ marginRight: 20,width:'150px' ,justifyContent:"center" ,display:"flex"}}>
                        <span
                          className={
                            item.job_status !== "Rejected"
                              ? "text-green"
                              : "text-red"
                          }
                          style={{ fontSize: 16, fontWeight: 700 }}
                        >
                          {item.job_status}
                        </span>
                      </div>
                      <h3 style={{ marginRight: 20, width: "50px" }}>
                        {item.year}
                      </h3>
                      {item.job_status === "Requested" && (
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() =>
                            handleJobReqCancel(item.job_request_id)
                          }
                          disabled={
                            item.job_status === "Cancelled" ||
                            item.job_status === "Converted To Job"
                          }
                        >
                          Cancel
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-center mt20">No Data Available</p>
        )}
      </div>
      {!isSearchFilter && (
        <div className="mt20">
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next Page"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={Math.ceil(pageCount)}
            previousLabel="Previous Page"
            renderOnZeroPageCount={null}
            containerClassName="pagination justify-content-end"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            activeClassName="active"
            previousClassName="page-item"
            previousLinkClassName="page-link actbtn"
            nextClassName="page-item"
            nextLinkClassName="page-link actbtn"
            disabledClassName="disabled"
            disabledLinkClassName="disabled"
          />
        </div>
      )}
      {shoDialog && (
        <DialogBox data={msgDisplay} handleDialogue={handleDialogue} />
      )}
      {/* {shoDialog2 && <DialogBox2 data={msgDisplay} handleDialogue={handleDialogue2} />}                 */}
    </div>
  );
}

export default JobsRequest;
