import { useState, useEffect, useContext } from 'react'
import { Carousel } from '@trendyol-js/react-carousel';
import { customerAssignedTeam } from '../api';
import { UserContext } from '../App';


function People() {
    const [items, setItems] = useState(4.5)
    const [team, setTeam] = useState(null);
    const userContext = useContext(UserContext);

    const getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;
        let computedItems = parseFloat(width / 420).toFixed(1)
        console.log('inside getWindowDimension ', width, height, computedItems);
        //setItems(computedItems)
        return computedItems
    }

    async function handleResize() {
        let finalItems = await getWindowDimensions()
        console.log('final items: ', finalItems);
        await setItems(finalItems);
    }

    useEffect(() => {
        window.addEventListener('load', handleResize());
        window.addEventListener('resize', handleResize());
        return (() => window.removeEventListener('resize', handleResize()), window.removeEventListener('load', handleResize()))
    }, [items])

    useEffect(() => {
        if (team === null && userContext !== null) {
            let tempData = userContext.data;
            customerAssignedTeam(tempData).then((response) => {
                console.log("customerAssignedTeam", response.data.data.resultData);
                setTeam(response.data.data.resultData);
            }).catch((e) => console.log(e));
        }
    }, [userContext])

    return (
        <>
            {team !== null && team.length > 0 ?
                <>
                    <h3 className='text-primary'>People on My Project</h3>
                    <Carousel show={items} slide={Math.round(items)} swiping={true} responsive={true}>
                        {team.map((item, index) =>
                            <div className='bg-white-shadow peoples' key={index}>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <div className={`circleName thumb${index} d-flex`}><span style={{ marginRight: 15 }}>{item.member_name.slice(0, 1)}</span> <div><h3 style={{ marginBottom: 0 }}>{item.member_name}</h3><small>{item.designation_name}</small></div></div>
                                    {/* <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="25px" viewBox="0 0 24 24" width="25px" fill="#2a9bdb"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM7 9h10c.55 0 1 .45 1 1s-.45 1-1 1H7c-.55 0-1-.45-1-1s.45-1 1-1zm6 5H7c-.55 0-1-.45-1-1s.45-1 1-1h6c.55 0 1 .45 1 1s-.45 1-1 1zm4-6H7c-.55 0-1-.45-1-1s.45-1 1-1h10c.55 0 1 .45 1 1s-.45 1-1 1z" /></svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="25px" viewBox="0 0 24 24" width="25px" fill="#2a9bdb"><path d="M11.4 1.02C6.62 1.33 3 5.52 3 10.31V17c0 1.66 1.34 3 3 3h1c1.1 0 2-.9 2-2v-4c0-1.1-.9-2-2-2H5v-1.71C5 6.45 7.96 3.11 11.79 3 15.76 2.89 19 6.06 19 10v2h-2c-1.1 0-2 .9-2 2v4c0 1.1.9 2 2 2h2v1h-6c-.55 0-1 .45-1 1s.45 1 1 1h5c1.66 0 3-1.34 3-3V10c0-5.17-4.36-9.32-9.6-8.98z" /></svg>
                                    </div> */}
                                </div>
                            </div>
                        )}
                    </Carousel>
                </>
            : ""}
        </>
    );
}

export default People;
