import { useContext, useEffect, useState } from "react";
import { custTaskMakeComment, taskCommentList } from "../api";
import { UserContext } from "../App";


function Comments(props) {
    const [comment, setComment] = useState('');
    const [userCommentList, setUserCommentList] = useState(null)

    const userContext = useContext(UserContext);

    const handleSubmitComment = () => {
        props.handleComment(comment);
        setComment('');
    }

    return (
        <>
            {props.data !== null &&
                <ul className='comments'>
                    {props.data.map((item, index) =>
                        <li key={index}>
                            <div className=''>
                                <div className='d-flex align-items-center'>
                                    <h4>{item.user_fullname}</h4>
                                    <span className='col-hidden col-md-4 ml-3 text-gray'>{item.datetime}</span>
                                </div>
                                <p>{item.comment}</p>
                            </div>
                        </li>
                    )}
                </ul>
            }
            <div className="commentbox mt20">
                <div className="mb-3 posrel">
                    <input type="text" className="form-control form-control-lg" value={comment} onChange={(e) => setComment(e.target.value)} placeholder="add your comments" aria-describedby="basic-addon1" />
                    <button type="button" className="plainbtn" onClick={() => handleSubmitComment()}><img src="/assets/imgs/sendbutton.jpg" alt="button image" /></button>
                </div>
            </div>
        </>
    );
}

export default Comments;