import queryString from "query-string";
import axios from "axios";
import moment from 'moment';


const baseURL = 'https://client.ncserp.com/customerportal-apis/Api'
//  const baseURL = '/customerportal-apis/Api'
// const baseURL = '/api'


export const checkTokenValidity = (data) => {
  console.log('data from checkTokenValidity', data.status);
  if (data.status) {
    return true
  } else {
    sessionStorage.removeItem('userInfo');
    return window.location = '/login';
  }
}

export const customerActiveTask = async () => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';

  const response = await fetch(`${baseURL}/customer_active_task_count?token=${token}&acc_id=${accId}&customer_id=${customerId}&logged_in_user_id=${loggedUser}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      
    }
  });
  return response.json()

}

export const customerStagewiseTask = () => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';
  let config = {
    method: "get",
    url: `${baseURL}/customer_stagewise_tasks_count?token=${token}&acc_id=${accId}&customer_id=${customerId}&logged_in_user_id=${loggedUser}`,
    headers: {      
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Accept: "application/json"
    },
  };

  return axios(config);
}

export const customerAssignedTeam = () => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';
  let config = {
    method: "get",
    url: `${baseURL}/customer_assigned_team?token=${token}&acc_id=${accId}&customer_id=${customerId}&logged_in_user_id=${loggedUser}`,
    headers: {
      //Authorization: CONSTANTS.getAuthorization(),
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
  };

  return axios(config);
}

export const customerUnpaidBill = () => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';

  let config = {
    method: "get",
    url: `${baseURL}/customer_unpaid_bill?token=${token}&acc_id=${accId}&customer_id=${customerId}&logged_in_user_id=${loggedUser}`,
    headers: {
      //Authorization: CONSTANTS.getAuthorization(),
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
  };

  return axios(config);
}

export const custJobReqList = () => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';

  let config = {
    method: "get",
    url: `${baseURL}/customer_job_request_list?token=${token}&acc_id=${accId}&customer_id=${customerId}&logged_in_user_id=${loggedUser}`,
    headers: {
      //Authorization: CONSTANTS.getAuthorization(),
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
  };

  return axios(config);
}

export const custBillList = (data) => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';

  let frmDate = data.from_date ? moment(data.from_date).format('YYYY-MM-DD') : moment().subtract(1, 'years').format('YYYY-MM-DD');
  let toDate = data.to_date ? moment(data.to_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
  let config = {
    method: "get",
    url: `${baseURL}/customer_bills_list?token=${token}&acc_id=${accId}&customer_id=${customerId}&logged_in_user_id=${loggedUser}&from_date=${frmDate}&to_date=${toDate}`,
    headers: {
      //Authorization: CONSTANTS.getAuthorization(),
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
  };

  return axios(config);
}

export const custTaskStats = () => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';

  let config = {
    method: "get",
    url: `${baseURL}/customer_tasks_stats?token=${token}&acc_id=${accId}&customer_id=${customerId}&logged_in_user_id=${loggedUser}`,
    headers: {
      //Authorization: CONSTANTS.getAuthorization(),
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
  };

  return axios(config);
}

export const custAllTaskList = (data) => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';

  let workspaceID = data.work_category !== undefined ? data.work_category : "";
  let statusID = data.project_status !== undefined ? data.project_status : "";
  let taskType = data.project_type !== undefined ? data.project_type : "";
  let taskOpenClose = data.task_open_close !== undefined ? data.task_open_close : "opened";
  let config = {
    method: "get",
    url: `${baseURL}/customer_all_tasks_list?token=${token}&acc_id=${accId}&customer_id=${customerId}&logged_in_user_id=${loggedUser}&workcategory_id=${workspaceID}&status_id=${statusID}&task_type=${taskType}&task_open_close=${taskOpenClose}`,
    headers: {
      //Authorization: CONSTANTS.getAuthorization(),
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
  };

  return axios(config);
}

export const custFteMainTaskList = () => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';

  let config = {
    method: "get",
    url: `${baseURL}/customer_fte_main_tasks_list?token=${token}&acc_id=${accId}&customer_id=${customerId}&logged_in_user_id=${loggedUser}`,
    headers: {
      //Authorization: CONSTANTS.getAuthorization(),
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
  };

  return axios(config);
}

export const custUserList = () => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';

  let config = {
    method: "get",
    url: `${baseURL}/customer_user_list?token=${token}&acc_id=${accId}&customer_id=${customerId}&logged_in_user_id=${loggedUser}`,
    headers: {
      //Authorization: CONSTANTS.getAuthorization(),
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
  };

  return axios(config);
}

export const UserInbox = () => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';

  let config = {
    method: "get",
    url: `${baseURL}/customer_inbox_list?token=${token}&acc_id=${accId}&customer_id=${customerId}&logged_in_user_id=${loggedUser}&user_id=${customerId}`,
    headers: {
      //Authorization: CONSTANTS.getAuthorization(),
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
  };

  return axios(config);
}

export const UserSendbox = () => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';

  let config = {
    method: "get",
    url: `${baseURL}/customer_sentbox_list?token=${token}&acc_id=${accId}&customer_id=${customerId}&logged_in_user_id=${loggedUser}`,
    headers: {
      //Authorization: CONSTANTS.getAuthorization(),
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
  };

  return axios(config);
}

export const UserRecentActivity = () => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';

  let config = {
    method: "get",
    url: `${baseURL}/customer_recent_activity?token=${token}&acc_id=${accId}&customer_id=${customerId}&logged_in_user_id=${loggedUser}&user_id=${customerId}`,
    headers: {
      //Authorization: CONSTANTS.getAuthorization(),
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
  };

  return axios(config);
}

export const MyAccoutDetails = () => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';

  let config = {
    method: "get",
    url: `${baseURL}/customer_my_account_info?token=${token}&acc_id=${accId}&customer_id=${customerId}&logged_in_user_id=${loggedUser}`,
    headers: {
      //Authorization: CONSTANTS.getAuthorization(),
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
  };

  return axios(config);
}

export const custTaskListHead = (data) => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';

  let config = {
    method: "get",
    url: `${baseURL}/customer_tasks_list_head?token=${token}&acc_id=${accId}&customer_id=${customerId}&logged_in_user_id=${loggedUser}`,
    headers: {
      //Authorization: CONSTANTS.getAuthorization(),
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
  };

  return axios(config);
}

export const custTaskDetails = (data) => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';
  const tastId = data;
  //customer_task_detail?token=8dnxL9EUjzQ5sLRCQZQH9UASdnIqcq14IZ5WX99sU4QW3HWJ14&acc_id=787&customer_id=72663&logged_in_user_id=73017&task_id=7647
  let config = {
    method: "get",
    url: `${baseURL}/customer_task_detail?token=${token}&acc_id=${accId}&customer_id=${customerId}&logged_in_user_id=${loggedUser}&task_id=${tastId}`,
    headers: {
      //Authorization: CONSTANTS.getAuthorization(),
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
  };

  return axios(config);
}

export const custCalendarData = (data) => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';
  const monthDigit = data.month_digit ? data.month_digit : '07' //moment().format('YYYY-MM-DD');
  const yearDigit = data.year_digit ? data.year_digit : '2022' //moment().format('YYYY-MM-DD');
  //customer_calendar_data?token=HbzuHKtUgQbc92upIZQjFWanD47t5pA2vDr1bzP8bRTzpjRsWU&acc_id=787&customer_id=73077&logged_in_user_id=73077&month_digit=04&year_digit=2022
  let config = {
    method: "get",
    url: `${baseURL}/customer_calendar_data?token=${token}&acc_id=${accId}&customer_id=${customerId}&logged_in_user_id=${loggedUser}&month_digit=${monthDigit}&year_digit=${yearDigit}`,
    headers: {
      //Authorization: CONSTANTS.getAuthorization(),
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
  };

  return axios(config);
}

export const custCalendarDateHighlightsData = (data) => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';
  const monthDigit = data.month_digit ? data.month_digit : '07' //moment().format('YYYY-MM-DD');
  const yearDigit = data.year_digit ? data.year_digit : '2022' //moment().format('YYYY-MM-DD');

  let config = {
    method: "get",
    url: `${baseURL}/customer_calendar_date_hightlight?token=${token}&acc_id=${accId}&customer_id=${customerId}&logged_in_user_id=${loggedUser}&month_digit=${monthDigit}&year_digit=${yearDigit}`,
    headers: {
      //Authorization: CONSTANTS.getAuthorization(),
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
  };

  return axios(config);
}

export const custJobPriority = (data) => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = '72663' //userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';

  let config = {
    method: "get",
    url: `${baseURL}/customer_job_priority?token=${token}&acc_id=${accId}&customer_id=${customerId}&logged_in_user_id=${loggedUser}`,
    headers: {
      //Authorization: CONSTANTS.getAuthorization(),
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
  };

  return axios(config);
}

export const custSingleUserInfo = (data) => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';
  const userId = data;
  let config = {
    method: "get",
    url: `${baseURL}/customer_single_user_info?token=${token}&acc_id=${accId}&customer_id=${customerId}&logged_in_user_id=${loggedUser}&user_id=${userId}`,
    headers: {
      //Authorization: CONSTANTS.getAuthorization(),
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
  };

  return axios(config);
}

export const custJobReqCancel = (data) => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';
  const jobReqId = data;

  let config = {
    method: "get",
    url: `${baseURL}/customer_single_job_request_cancel?token=${token}&acc_id=${accId}&customer_id=${customerId}&logged_in_user_id=${loggedUser}&job_request_id=${jobReqId}`,
    headers: {
      //Authorization: CONSTANTS.getAuthorization(),
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
  };

  return axios(config);
}

export const custTaskDocsList = (data) => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';
  const taskId = data;

  let config = {
    method: "get",
    url: `${baseURL}/customer_task_document_list?token=${token}&acc_id=${accId}&customer_id=${customerId}&logged_in_user_id=${loggedUser}&task_id=${taskId}`,
    headers: {
      //Authorization: CONSTANTS.getAuthorization(),
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
  };

  return axios(config);
}


export const createJobRequestAPI = async (datainfo) => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';


  let job_title = datainfo.job_title;
  let job_type = datainfo.job_type;
  let job_description = datainfo.job_description;
  let year = datainfo.year;
  let priorityID = datainfo.priority_id;
  let document_files = datainfo.document_files
  let fileName = datainfo.attached_file_name;
  let jobReqId = datainfo.job_request_id;
  console.log('data from api : ', document_files);

  const response = await fetch(`${baseURL}/create_job_request`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    },


    body: queryString.stringify({
      token: token,
      acc_id: accId,
      customer_id: customerId,
      logged_in_user_id: loggedUser,
      job_title,
      job_type,
      job_description,
      year: year,
      priority_id: priorityID,
      file_to_be_uploaded: document_files,
      original_filename: fileName,
      job_request_id: jobReqId
    })
  })

  return response.json()

}



export const createUserAPI = async (datainfo) => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';


  let firstName = datainfo.first_name;
  let lastName = datainfo.last_name;
  let email = datainfo.user_email;
  let phone = datainfo.contact_phone_1;
  let username = datainfo.user_name;
  let password = datainfo.password;
  let customerUserType = datainfo.customer_user_type;

  const response = await fetch(`${baseURL}/customer_create_new_user`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    },

    body: queryString.stringify({
      token: token,
      acc_id: accId,
      customer_id: customerId,
      logged_in_user_id: loggedUser,
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      username: username,
      password: password,
      customer_user_type: customerUserType
    })
  })

  return response.json()

}

export const updateSingleUserAPI = async (datainfo) => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';
  const userId = datainfo.user_id;

  let firstName = datainfo.first_name;
  let lastName = datainfo.last_name;
  let email = datainfo.user_email;
  let phone = datainfo.contact_phone_1;
  let username = datainfo.user_name;
  let password = datainfo.password ? datainfo.password : '';
  let customerUserType = datainfo.customer_user_type;
  //customer_update_user
  const response = await fetch(`${baseURL}/customer_update_user`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    },

    body: queryString.stringify({
      token: token,
      acc_id: accId,
      customer_id: customerId,
      logged_in_user_id: loggedUser,
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      username: username,
      password: password,
      customer_user_type: customerUserType,
      user_id: userId
    })
  })

  return response.json()

}

export const deleteSingleUserAPI = async (user) => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';


  let userId = user;
  //token=GYllK8FeWNphKwd1tKRCUPeFNRYORpGIL2juu9XjV2pAdlf58V&acc_id=787&customer_id=72663&logged_in_user_id=73017&user_id=73050
  const response = await fetch(`${baseURL}/customer__delete_single_user?token=${token}&acc_id=${accId}&customer_id=${customerId}&logged_in_user_id=${loggedUser}&user_id=${userId}`, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Accept': '*/*'
    },
  })

  return response.json()

}

export const login = async (data) => {
  console.log(data);
  const response = await fetch(`${baseURL}/user_login_pst`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: queryString.stringify(data)
  })

  return response.json()
}

export const taskCommentList = (data) => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';
  const taskId = data;
  let config = {
    method: "get",
    //customer_task_comment_list?token=w2AGSs1BpwWJb2MI3NP4IsZfT0hkdX9PtZj9mgArkHPwawoTdI&acc_id=787&customer_id=72663&logged_in_user_id=73017&task_id=7647
    url: `${baseURL}/customer_task_comment_list?token=${token}&acc_id=${accId}&customer_id=${customerId}&logged_in_user_id=${loggedUser}&task_id=${taskId}`,
    headers: {
      //Authorization: CONSTANTS.getAuthorization(),
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
  };

  return axios(config);
}

export const custLatestAnnoucement = (data) => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';

  let config = {
    method: "get",
    url: `${baseURL}/customer_latest_announcment?token=${token}&acc_id=${accId}&customer_id=${customerId}&logged_in_user_id=${loggedUser}&user_id=${loggedUser}`,
    headers: {
      //Authorization: CONSTANTS.getAuthorization(),
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
  };

  return axios(config);
}

export const custBillingStatementLedgerFiles = (data) => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';

  let config = {
    method: "get",
    url: `${baseURL}/customer_billing_statement_ledger_files?token=${token}&acc_id=${accId}&customer_id=${customerId}&logged_in_user_id=${loggedUser}`,
    headers: {
      //Authorization: CONSTANTS.getAuthorization(),
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
  };

  return axios(config);
}

export const custAllTaskFilters = (data) => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';

  let config = {
    method: "get",
    url: `${baseURL}/customer_all_tasks_filters?token=${token}&acc_id=${accId}&customer_id=${customerId}&logged_in_user_id=${loggedUser}`,
    headers: {
      //Authorization: CONSTANTS.getAuthorization(),
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
  };

  return axios(config);
}

export const custBillingSummary = (data) => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';

  let config = {
    method: "get",
    url: `${baseURL}/customer_billing_summary?token=${token}&acc_id=${accId}&customer_id=${customerId}&logged_in_user_id=${loggedUser}`,
    headers: {
      //Authorization: CONSTANTS.getAuthorization(),
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
  };

  return axios(config);
}

export const custMessageDetail = (data) => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';
  const messageId = data;

  let config = {
    method: "get",
    url: `${baseURL}/customer_message_detail?token=${token}&acc_id=${accId}&customer_id=${customerId}&logged_in_user_id=${loggedUser}&id=${messageId}`,
    headers: {
      //Authorization: CONSTANTS.getAuthorization(),
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
  };

  return axios(config);
}

export const custComposeGetUsers = (data) => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';
  const messageId = data;

  let config = {
    method: "get",
    url: `${baseURL}/customer_compose_message_users?token=${token}&acc_id=${accId}&customer_id=${customerId}&logged_in_user_id=${loggedUser}`,
    headers: {
      //Authorization: CONSTANTS.getAuthorization(),
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
  };

  return axios(config);
}


export const custBillDownload = (data) => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';
  const billingId = data;
  let config = {
    method: "get",
    url: `${baseURL}/customer_bill_download?token=${token}&acc_id=${accId}&customer_id=${customerId}&logged_in_user_id=${loggedUser}&billing_id=${billingId}`,
    headers: {
      //Authorization: CONSTANTS.getAuthorization(),
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
  };

  return axios(config);
}

export const custBillDataDownload = (data) => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';
  const billingId = data;
  let config = {
    method: "get",
    url: `${baseURL}/customer_bill_data_download?token=${token}&acc_id=${accId}&customer_id=${customerId}&logged_in_user_id=${loggedUser}&billing_id=${billingId}`,
    headers: {
      //Authorization: CONSTANTS.getAuthorization(),
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
  };

  return axios(config);
}

export const custSupportEmails = (data) => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';
  const billingId = data;
  let config = {
    method: "get",
    url: `${baseURL}/customer_support_emails?token=${token}&acc_id=${accId}&customer_id=${customerId}&logged_in_user_id=${loggedUser}`,
    headers: {
      //Authorization: CONSTANTS.getAuthorization(),
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
  };

  return axios(config);
}

//////////////////added on 4th nov 2023
// https://client.ncserp.com/customerportal-apis/Api/customer_single_job_request_detail?
export const customerJobRequestDetail = (data) => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';
  const job_request_id = data;
  let config = {
    method: "get",
    url: `${baseURL}/customer_single_job_request_detail?token=${token}&acc_id=${accId}&customer_id=${customerId}&logged_in_user_id=${loggedUser}&job_request_id=${job_request_id}`,
    headers: {
      
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
  };

  return axios(config);
}

export const changePasswordAPI = async (data) => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';

  let oldPass = data.old_password;
  let newPass = data.new_password;
  let confirmPass = data.confirm_password;

  //customer_update_user
  const response = await fetch(`${baseURL}/customer_user_update_password`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    },

    body: queryString.stringify({
      token: token,
      acc_id: accId,
      customer_id: customerId,
      logged_in_user_id: loggedUser,
      old_password: oldPass,
      new_password: newPass,
      confirm_password: confirmPass
    })
  })

  return response.json()

}

export const composeEmailApi = async (data) => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';

  let toUsers = data.userId;
  let subject = data.subject;
  let message = data.message;
  let document_files = data.document_files
  let fileName = data.attached_file_name;


  //customer_update_user
  const response = await fetch(`${baseURL}/customer_message_compose`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    },

    body: queryString.stringify({
      token: token,
      acc_id: accId,
      customer_id: customerId,
      logged_in_user_id: loggedUser,
      to_user_id: toUsers,
      subject: subject,
      message: message,
      file_to_be_uploaded: document_files,
      original_filename: fileName
    })
  })

  return response.json()

}


export const custTaskMakeComment = async (data) => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';

  let taskId = data.task_id;
  let comment = data.comments;

  //customer_task_make_comment
  const response = await fetch(`${baseURL}/customer_task_make_comment`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    },

    body: queryString.stringify({
      token: token,
      acc_id: accId,
      customer_id: customerId,
      logged_in_user_id: loggedUser,
      task_id: taskId,
      comment: comment
    })
  })

  return response.json()

}


export const custTaskDocUpload = async (data) => {
  const userInformation = JSON.parse(sessionStorage.getItem('userInfo'))
  const token = userInformation !== null ? userInformation.token : '';
  const accId = userInformation !== null ? userInformation.acc_id : '';
  const customerId = userInformation !== null ? userInformation.customer_user_id : '';
  const loggedUser = userInformation !== null ? userInformation.user_id : '';

  let taskId = data.taskId;
  let attachedFiles = data.attachedFiles;
  let fileName = data.attached_file_name;

  //customer_update_user
  const response = await fetch(`${baseURL}/customer_task_document_upload`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    },

    body: queryString.stringify({
      token: token,
      acc_id: accId,
      customer_id: customerId,
      logged_in_user_id: loggedUser,
      task_id: taskId,
      file_to_be_uploaded: attachedFiles,
      original_filename: fileName
    })
  })

  return response.json()

}


