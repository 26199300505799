
function ProjectCompletedThisWeek(props) {
    console.log('props: ', props)
    return (
        <div className='row padlr20'>
            <h3 className="text-primary">Projects completed this Week</h3>
            <div className="row maxHeight250 slim-scroll">
                <table className='table' id="projectsTable">
                    <thead>
                        <tr>
                            <th width="35%">Project</th>
                            <th width="40%">Status</th>
                            <th width="25%">Due Date</th>
                        </tr>
                    </thead>
                    {props.data !== null && props.data.taskCompletedThisWeek.length > 0 ?
                        <tbody>
                            {props.data.taskCompletedThisWeek.map((item, index) =>
                                <tr key={index}>
                                    <td>
                                        <h4>{item.task_code}</h4>
                                        <small className="text-gray">{item.task_title}</small>
                                    </td>
                                    <td>
                                        <div>
                                            <span className="text-gray" style={{ fontSize: 14 }}>{item.status_title}</span>
                                        </div>
                                        <div className="progress mt10">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${item.progress_percentage}%` }} aria-valuenow={item.progress_percentage} aria-valuemin="0" aria-valuemax='100'></div>
                                        </div>
                                    </td>
                                    <td className="text-primary"><strong>{item.end_date}</strong></td>
                                </tr>
                            )}
                            {/* <tr>
                                <td>
                                    <h4>AF0045GHDF</h4>
                                    <small className="text-gray">Lorem Ipsum Task</small>
                                </td>
                                <td>
                                    <div>
                                        <span className="text-gray" style={{ fontSize: 14 }}>Waiting for Info</span>
                                    </div>
                                    <div className="progress mt10">
                                        <div className="progress-bar" role="progressbar" style={{ width: `${25}%` }} aria-valuenow={25} aria-valuemin="0" aria-valuemax='100'></div>
                                    </div>
                                </td>
                                <td className="text-primary"><strong>27 Dec, 2021</strong></td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>AF0045GHDF</h4>
                                    <small className="text-gray">Lorem Ipsum Task</small>
                                </td>
                                <td>
                                    <div>
                                        <span className="text-gray" style={{ fontSize: 14 }}>Waiting for Info</span>
                                    </div>
                                    <div className="progress mt10">
                                        <div className="progress-bar" role="progressbar" style={{ width: `${25}%` }} aria-valuenow={25} aria-valuemin="0" aria-valuemax='100'></div>
                                    </div>
                                </td>
                                <td className="text-primary"><strong>27 Dec, 2021</strong></td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>AF0045GHDF</h4>
                                    <small className="text-gray">Lorem Ipsum Task</small>
                                </td>
                                <td>
                                    <div>
                                        <span className="text-gray" style={{ fontSize: 14 }}>Waiting for Info</span>
                                    </div>
                                    <div className="progress mt10">
                                        <div className="progress-bar" role="progressbar" style={{ width: `${25}%` }} aria-valuenow={25} aria-valuemin="0" aria-valuemax='100'></div>
                                    </div>
                                </td>
                                <td className="text-primary"><strong>27 Dec, 2021</strong></td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>AF0045GHDF</h4>
                                    <small className="text-gray">Lorem Ipsum Task</small>
                                </td>
                                <td>
                                    <div>
                                        <span className="text-gray" style={{ fontSize: 14 }}>Waiting for Info</span>
                                    </div>
                                    <div className="progress mt10">
                                        <div className="progress-bar" role="progressbar" style={{ width: `${25}%` }} aria-valuenow={25} aria-valuemin="0" aria-valuemax='100'></div>
                                    </div>
                                </td>
                                <td className="text-primary"><strong>27 Dec, 2021</strong></td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>AF0045GHDF</h4>
                                    <small className="text-gray">Lorem Ipsum Task</small>
                                </td>
                                <td>
                                    <div>
                                        <span className="text-gray" style={{ fontSize: 14 }}>Waiting for Info</span>
                                    </div>
                                    <div className="progress mt10">
                                        <div className="progress-bar" role="progressbar" style={{ width: `${25}%` }} aria-valuenow={25} aria-valuemin="0" aria-valuemax='100'></div>
                                    </div>
                                </td>
                                <td className="text-primary"><strong>27 Dec, 2021</strong></td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>AF0045GHDF</h4>
                                    <small className="text-gray">Lorem Ipsum Task</small>
                                </td>
                                <td>
                                    <div>
                                        <span className="text-gray" style={{ fontSize: 14 }}>Waiting for Info</span>
                                    </div>
                                    <div className="progress mt10">
                                        <div className="progress-bar" role="progressbar" style={{ width: `${25}%` }} aria-valuenow={25} aria-valuemin="0" aria-valuemax='100'></div>
                                    </div>
                                </td>
                                <td className="text-primary"><strong>27 Dec, 2021</strong></td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>AF0045GHDF</h4>
                                    <small className="text-gray">Lorem Ipsum Task</small>
                                </td>
                                <td>
                                    <div>
                                        <span className="text-gray" style={{ fontSize: 14 }}>Waiting for Info</span>
                                    </div>
                                    <div className="progress mt10">
                                        <div className="progress-bar" role="progressbar" style={{ width: `${25}%` }} aria-valuenow={25} aria-valuemin="0" aria-valuemax='100'></div>
                                    </div>
                                </td>
                                <td className="text-primary"><strong>27 Dec, 2021</strong></td>
                            </tr> */}
                        </tbody>
                        : ''}
                    {props.data !== null && props.data.taskDueThisWeek.length === 0 &&
                        <tbody>
                            <tr>
                                <td colSpan={3}>No Data Available</td>
                            </tr>
                        </tbody>
                    }
                </table>
                {/* <ul className="stagewiselist">
                    {custStagewiseTask !== null ?
                        custStagewiseTask.task_data.map((item, index) =>
                            <li key={index}>
                                <div className="d-flex justify-content-between mt20">
                                    <span style={{ fontWeight: 500 }}>{item.status_title}</span>
                                    <span className="text-secondary">{item.task_count}</span>
                                </div>
                                <div className="progress mt10">
                                    <div className="progress-bar" role="progressbar" style={{ width: `${item.task_count / custStagewiseTask.total_task_count * 100}%` }} aria-valuenow={item.task_count} aria-valuemin="0" aria-valuemax={custStagewiseTask.total_task_count}></div>
                                </div>
                            </li>
                        )
                        : 'Loading...'
                    }
                </ul> */}
            </div>
        </div>
    )
}

export default ProjectCompletedThisWeek;
