import { useEffect, useState } from 'react';
import DonutChart from 'react-donut-chart';


function ProjectSummaryChart(props) {
    const [graphData, setGraphData] = useState(null);
    console.log('props: ', props.data)

    useEffect(() => {
        if (graphData === null && props.data !== null) {
            console.log('props.data: ', props)
            let tempData = []
            props.data.taskSummaryCountData.forEach(element => {
                tempData.push({ label: element.title, value: parseInt(element.count) })
            });
            console.log('tempData: ', tempData)
            setGraphData(tempData)
        }
    }, [props.data])




    return (
        <div className='row padlr20'>
            {graphData !== null && 
                <DonutChart
                    data={graphData}
                    colors={['#60cf6b', '#f88d6c', '#ee3197']}
                    strokeColor={''}
                    selectedOffset={''}
                    innerRadius={0.80}
                    outerRadius={0.90}
                    clickToggle={false}
                    legend={true}
                    height='300'
                    width='480'
                />
            }
        </div>
    )
}

export default ProjectSummaryChart;
