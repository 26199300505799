import { useContext, useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { checkTokenValidity, custTaskDetails, custTaskDocsList, custTaskDocUpload, custTaskMakeComment, taskCommentList,customerJobRequestDetail } from '../api';
import { UserContext } from '../App'
import Comments from '../common/comments';



function ProjectMoreInfo(props) {
    const userContext = useContext(UserContext);
    const [formData, setFormData] = useState(null)
    const [attachedFiles, setAttachedFiles] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState(null);
    const [displayUpldList, setDisplayUpldList] = useState(null)
    const [userCommentList, setUserCommentList] = useState(null);
    const [customerJobRequest, setCustomerJobRequest] = useState(null);            //added by me
    const [job_request_id, setJobReqId] = useState(null);                            //added by me
    const [docFile, setDocFile] = useState([]);                            //added by me
    const [jobStatus, setJobStatus] = useState(null);                            //added by me
    const navigate = useNavigate();
    console.log('props from project more ', props);
    
    
    // Back button issue solved of projects and  Job request on 31 oct 2023
    const goBack = () => {
        const previousPath = document.referrer;
        console.log("previousPath",previousPath)
        if (previousPath.includes('/job-request')) {
          window.location.href = '/job-request';
        } else if (previousPath.includes('/projects')) {
          window.location.href = '/projects';
        } else {
            window.location.href = '/home'
        }
      };

    const handleAttachments = (data) => {
        let files = data.target.files;
        let fileName = files[0].name
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            let taskId = formData.task_info.task_id;
            let attachedFiles = e.target.result.split(',');
            custTaskDocUpload({ taskId: taskId, attachedFiles: attachedFiles[1], attached_file_name: fileName }).then((response) => {
                console.log(response.status);
                custTaskDocsList(taskId).then((response) => {
                    setUploadedFiles(response.data.data.resultData)
                })
            }).catch((e) => console.log(e));
        }
    }

    const allowtaskComment = JSON.parse(sessionStorage.getItem('userInfo'))

    const handleComment = (usercomment) => {
        let data = {
            task_id: formData.task_info.task_id,
            comments: usercomment
        }
        custTaskMakeComment(data).then((response) => {
            console.log(response);
            if (response.status) {
                taskCommentList(formData.task_info.task_id).then((response) => {
                    setUserCommentList(response.data.data.resultData)
                }).catch((e) => console.log(e));
            }
        }).catch((e) => console.log(e));
    }

    useEffect(() => {
        const projectId = window.location.pathname.split('/projects/')[1];
        if (formData === null && userContext !== null) {
            custTaskDetails(projectId).then((response) => {
                if (checkTokenValidity(response.data)) {
                    console.log("custTaskDetails ", response.data.data);
                    setFormData(response.data.data);
                    console.log('job_request_id--->',response.data.data.task_info.job_request_id)
                    const jobReqId = response.data.data.task_info.job_request_id;
                    setJobReqId(jobReqId)
                    console.log('job_request_id--->',job_request_id)
                    custTaskDocsList(response.data.data.task_info.task_id).then((response) => {
                        console.log(response.data.data.resultData);
                        setUploadedFiles(response.data.data.resultData)
                    })
                    taskCommentList(response.data.data.task_info.task_id).then((response) => {
                        console.log('comments list from project details', response)
                        setUserCommentList(response.data.data.resultData)
                        
                    }).catch((e) => console.log(e));
                }
                
            }).catch((e) => console.log(e));

        }
    // }, [userContext, userCommentList,formData])
    }, [userContext,formData])

    useEffect(() => {
        
        console.log(job_request_id)
        if (userContext !== null && job_request_id) {
            customerJobRequestDetail(job_request_id).then((response) => {
                if (response.data && response.data.data && response.data.data.resultData ) {
                // console.log('customerJobRequestDetail', response.data);
                setCustomerJobRequest(response.data.data);
                console.log(response.data.data.resultData.document_files)
                setDocFile(response.data.data.resultData.document_files)
                console.log(response.data.data.resultData.job_status)
                setJobStatus(response.data.data.resultData.job_status)
                }
            }).catch((e) => console.log(e));
        }
        
    }, [userContext,job_request_id]);
    console.log('customerJobRequestDetail', customerJobRequest);
        console.log('setDocFile', docFile);
        console.log('setJobStatus', jobStatus);

    return (
        <div className='container-fluid mt-50'>
            <div className='row'>
                <div className='projectHeader padlr50'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='d-flex justify-content-start align-items-center'>
                            <button type='button' className='prev' onClick={goBack}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="11px" height="20px" viewBox="0 0 11 20" version="1.1">
                                    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g id="Rounded" transform="translate(-548.000000, -3434.000000)">
                                            <g id="Navigation" transform="translate(100.000000, 3378.000000)">
                                                <g id="-Round-/-Navigation-/-arrow_back_ios" transform="translate(442.000000, 54.000000)">
                                                    <g>
                                                        <polygon id="Path" opacity="0.87" points="0 0 24 0 24 24 0 24" />
                                                        <path d="M16.62,2.99 C16.13,2.5 15.34,2.5 14.85,2.99 L6.54,11.3 C6.15,11.69 6.15,12.32 6.54,12.71 L14.85,21.02 C15.34,21.51 16.13,21.51 16.62,21.02 C17.11,20.53 17.11,19.74 16.62,19.25 L9.38,12 L16.63,4.75 C17.11,4.27 17.11,3.47 16.62,2.99 Z" id="🔹-Icon-Color" fill="#2a9bdb" />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                            <div>
                                <h2>{formData !== null && formData.task_info.task_title}</h2><span style={{ color: '#2a9bdb', fontWeight: 700 }}>{formData !== null && formData.task_info.billing_mode + ' - ' + formData.task_info.task_code}</span>
                            </div>
                        </div>
                        {/* <div>
                            <h3>48:58:09</h3><span style={{ color: '#2a9bdb', fontWeight: 700 }}>Project Timer</span>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className='row mt50 padlr50'>
                <div className='col-md-12 col-lg-9 mt10'>
                    <div className='card border pad20'>
                        {/* <h3 className='text-primary'>About Work</h3> */}
                        {/* <p className='text-gray'>{formData !== null && formData.task_info.about_task}</p> */}
                        <div className='row'>
                            <div className='col-6 col-md-2'>
                                <label className='text-gray'>Start Date</label>
                                <h4>{formData !== null && formData.task_info.start_date}</h4>
                            </div>
                            <div className='col-6 col-md-2'>
                                <label className='text-gray'>End Date</label>
                                <h4>{formData !== null && formData.task_info.end_date}</h4>
                            </div>
                            <div className='col-6 col-md-2'>
                                <label className='text-gray'>Status</label>
                                <h4 className='text-warning'>{formData !== null && formData.task_info.status_title}</h4>
                            </div>
                            {/* <div className='col-6 col-md-3'>
                                <label className='text-gray'>Initiated by</label>
                                <h4>{formData !== null && formData.task_info.initiated_by}</h4>
                                <small>{formData !== null && formData.task_info.initiated_on}</small>
                            </div>
                            <div className='col-6 col-md-3'>
                                <label className='text-gray'>Assigned to</label>
                                <h4>{formData !== null && formData.task_info.assigned_to + ' - ' + formData.task_info.assigned_to_code}</h4>
                                <small>{formData !== null && formData.task_info.assign_date}</small>
                            </div> */}
                        </div>
                    </div>
                    {formData !== null && formData.task_info.billing_mode === 'PAYG' &&
                        <div className='card border pad20 mt20'>
                            <div className='row'>
                                <div className='col'>
                                    <h3 className='text-primary'>Download your Hours Log File</h3>
                                </div>
                                <div className='col text-right'>
                                    <a href={formData !== null ? formData.task_info.hours_log_filepath : ''} download className='btn btn-primary' target="_blank"><svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#ffffff"><path d="M0 0h24v24H0z" fill="none"></path><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path></svg> Download</a>
                                </div>
                            </div>
                        </div>
                    }

                    <div className='card border pad20 mt20'>
                        <div className='row'>
                            <div className='col'>
                                <h3 className='text-primary'>Uploaded Files</h3>
                            </div>
                            <div className='col text-right d-flex justify-content-end'>
                                <div className="fileuploadBtn">
                                    <input type="file" id="fileUpload" onChange={(e) => handleAttachments(e)} />
                                    <button type='button' className='btn btn-primary'><svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#ffffff"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm4 11h-3v3c0 .55-.45 1-1 1s-1-.45-1-1v-3H8c-.55 0-1-.45-1-1s.45-1 1-1h3V8c0-.55.45-1 1-1s1 .45 1 1v3h3c.55 0 1 .45 1 1s-.45 1-1 1z" /></svg> | Upload Files <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="20px" viewBox="0 0 24 24" width="20px" fill="#ffffff"><g><rect fill="none" height="24" width="24" /></g><g><path d="M7.4,10h1.59v5c0,0.55,0.45,1,1,1h4c0.55,0,1-0.45,1-1v-5h1.59c0.89,0,1.34-1.08,0.71-1.71L12.7,3.7 c-0.39-0.39-1.02-0.39-1.41,0L6.7,8.29C6.07,8.92,6.51,10,7.4,10z M5,19c0,0.55,0.45,1,1,1h12c0.55,0,1-0.45,1-1s-0.45-1-1-1H6 C5.45,18,5,18.45,5,19z" /></g></svg></button>
                                </div>
                            </div>
                            {uploadedFiles !== null &&
                                <ul className='filelist'>
                                    {uploadedFiles.map((item, index) =>
                                        <li key={index}>
                                            <span className='fileExtension'>{item.filename.split('.', 3).pop()}</span>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <span>{item.filename}</span> <a href={item.filepath} className="text-primary" download target="_blank"><i className="fa fa-download" aria-hidden="true"></i></a>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            }
                        </div>
                    </div>
                    {allowtaskComment !== null && allowtaskComment.allow_task_comment === "1" &&
                        <div className='card border pad20 mt20'>
                            <div className='row'>
                                <div className='col'>
                                    <h3 className='text-primary'>Additional Instruction(s)</h3>
                                    <Comments data={userCommentList !== null ? userCommentList : null} handleComment={(c) => handleComment(c)} />
                                </div>
                            </div>
                        </div>
                    }
                    <div className='card border pad20 mt20'>
                            <div className='row'>
                                <div className='col'>
                                    <h3 className='text-primary'>Documents</h3>
                                    {/* <Comments data={userCommentList !== null ? userCommentList : null} handleComment={(c) => handleComment(c)} /> */}
                                </div>
                            </div>
                        </div>
                </div>
                <div className='col-md-12 col-lg-3 mt10'>
                    {formData !== null && formData.people_on_project !== null && formData.people_on_project.length > 0 &&
                        <div className='card border pad20'>
                            <h3 className='text-primary'>Project Members</h3>
                            <nav className='mt20 projmembers'>
                                {formData.people_on_project.length > 0 && formData.people_on_project.map((item, index) =>
                                    <li key={index} className='d-flex justify-content-between align-items-center mb20'>
                                        <div className='name d-flex align-items-center'>
                                            <div className='thumb' style={{ marginRight: 10 }}>{item.member_name.slice(0, 1)}</div>
                                            <h4><strong>{item.member_name}</strong> <br /><small>{item.designation_name}</small></h4>
                                        </div>
                                        {/* <button type='button' className='btn btn-primary'>Connect</button> */}
                                    </li>
                                )}
                            </nav>
                        </div>
                    }
                    <div className='card border pad20'>
                        <h3 className='text-primary'>Job related documents</h3>
                        {formData !== null && formData.task_related_document !== null &&
                            <nav className='mt20 projmembers'>
                                {formData.task_related_document.length > 0 && formData.task_related_document.map((item, index) =>
                                    <li key={index} className='d-flex justify-content-between align-items-center'>
                                        <h4>{/*<span>{item.document_path.split('.', 3).pop() === 'docx' ? <img src="/assets/imgs/wordIcon.png" alt="fileicon" width="35px" /> : item.name.split('.', 3).pop() === 'xlsx' ? <img src="/assets/imgs/excelIcon.png" alt="fileicon" width="35px" /> : item.name.split('.', 3).pop() === 'pptx' ? <img src="/assets/imgs/powerpointIcon.png" alt="fileicon" width="35px" /> : item.name.split('.', 3).pop() === 'gif' ? <img src="/assets/imgs/gifIcon.png" alt="fileicon" width="35px" /> : item.name.split('.', 3).pop() === 'jpg' ? <img src="/assets/imgs/jpgIcon.png" alt="fileicon" width="35px" /> : item.name.split('.', 3).pop() === 'png' ? <img src="/assets/imgs/pngIcon.png" alt="fileicon" width="35px" /> : item.name.split('.', 3).pop() === 'pdf' ? <img src="/assets/imgs/pdfIcon.png" alt="fileicon" width="35px" /> : <img src="/assets/imgs/file.png" alt="fileicon" width="35px" />}</span>*/} {item.document_name}</h4>
                                        <a href={item.document_path} download className='btn btn-primary'><svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#ffffff"><path d="M0 0h24v24H0z" fill="none"></path><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path></svg></a>
                                    </li>
                                )}
                            </nav>
                        }
                        {formData !== null && formData.task_related_document !== null && formData.task_related_document.length === 0 && 'No Document(s) Attached'}
                    </div>
                    {jobStatus === 'Converted To Job' && docFile.length > 0 && (
                    <div className='card border pad20 mt20'>
                        <h3 className='text-primary'>Documents Shared During Job Request</h3>
                        {formData !== null && docFile !== null && (
        <nav className='mt20 projmembers'>
            {docFile.length > 0 && jobStatus === 'Converted To Job' ? (
                docFile.map((item, index) => (
                    <li key={index} className='d-flex justify-content-between align-items-center'>
                        <h4>{item.filepath.split('/customer-job-request/')[1]}</h4>
                        
                        <a href={item.filepath} className='btn btn-primary' download>
                            <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#ffffff">
                                <path d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path>
                            </svg>
                        </a>
                        

                    </li>
                ))
            ) : (
                'No Document(s) Attached'
            )}
        </nav>
    )}
                    </div>
                    )}
                    <div className='card border pad20 mt20'>
                        <h3 className='text-primary'>Output workpapers</h3>
                        {formData !== null && formData.output_workpapers !== null &&
                            <nav className='mt20 projmembers'>
                                {formData.output_workpapers.length > 0 && formData.output_workpapers.map((item, index) =>
                                    <li key={index} className='d-flex justify-content-between align-items-center'>
                                        <h4>{/*<span>{item.document_path.split('.', 3).pop() === 'docx' ? <img src="/assets/imgs/wordIcon.png" alt="fileicon" width="35px" /> : item.name.split('.', 3).pop() === 'xlsx' ? <img src="/assets/imgs/excelIcon.png" alt="fileicon" width="35px" /> : item.name.split('.', 3).pop() === 'pptx' ? <img src="/assets/imgs/powerpointIcon.png" alt="fileicon" width="35px" /> : item.name.split('.', 3).pop() === 'gif' ? <img src="/assets/imgs/gifIcon.png" alt="fileicon" width="35px" /> : item.name.split('.', 3).pop() === 'jpg' ? <img src="/assets/imgs/jpgIcon.png" alt="fileicon" width="35px" /> : item.name.split('.', 3).pop() === 'png' ? <img src="/assets/imgs/pngIcon.png" alt="fileicon" width="35px" /> : item.name.split('.', 3).pop() === 'pdf' ? <img src="/assets/imgs/pdfIcon.png" alt="fileicon" width="35px" /> : <img src="/assets/imgs/file.png" alt="fileicon" width="35px" />}</span>*/} {item.document_name}</h4>
                                        <a href={item.document_path} download className='btn btn-primary'><svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#ffffff"><path d="M0 0h24v24H0z" fill="none"></path><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path></svg></a>
                                    </li>
                                )}
                            </nav>
                        }
                        {formData !== null && formData.output_workpapers !== null && formData.output_workpapers.length === 0 && 'No File(s) Available'}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProjectMoreInfo;