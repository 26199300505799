import { useState, useContext, useEffect } from 'react';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { UserInbox, custMessageDetail, custComposeGetUsers, composeEmailApi, UserSendbox, checkTokenValidity } from '../api';
import { UserContext } from '../App';
import '../App.css';
import Multiselect from 'multiselect-react-dropdown';
import { DialogBox } from '../common/modalView';

function Inbox() {
  const [inboxlist, setInboxlist] = useState(null);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [composeMail, setComposeMail] = useState(false);
  const [userComposeList, setUserComposeList] = useState(null);
  const [composeSubject, setComposeSubject] = useState('');
  const [filesAttached, setFilesAttached] = useState('');
  const [shoDialog, setShoDialog] = useState(false);
  const [msgDisplay, setMsgDisplay] = useState('');
  const [folderView, setFolderView] = useState('inbox');
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [selectedValue, setSelectedValue] = useState([]);

  const userContext = useContext(UserContext);

  const handleComposeMail = () => {
    setComposeMail(true)
    setSelectedEmail(null)
    custComposeGetUsers().then((response) => {
      //console.log('compose users list: ', response.data.data.resultData)
      setUserComposeList(response.data.data.resultData)
    })
  }

  const handleFileAttached = (data) => {
    setFilesAttached(data)
  }

  const handleDiscard = () => {
    setSelectedValue([]);
    setFilesAttached('');
    setComposeSubject('');
    setEditorState(EditorState.createEmpty())
    setComposeMail(false);
  }

  const handleSubmitCompose = () => {
    let tempUserId = []
    selectedValue.forEach(element => {
      tempUserId.push(element.user_id)
    });

    let fileAttach = '';
    let fileName = filesAttached !== '' && filesAttached.target.files[0].name;
    console.log('filesAttached: ', filesAttached.target.files);
    let files = filesAttached !== '' && filesAttached.target.files;
    if (filesAttached !== '' && filesAttached.target.files.length > 0) {
      let reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (e) => {
        let tempfile = e.target.result.split(',');
        fileAttach = tempfile[1];
        console.log('fileAttach: ', fileAttach);
      }
    }

    // let dataToPass = {
    //   userId: tempUserId,
    //   subject: composeSubject,
    //   message: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    //   document_files: fileAttach,
    //   attached_file_name: fileName

    // }
    setTimeout(function () {
      composeEmailApi({ userId: tempUserId, subject: composeSubject, message: draftToHtml(convertToRaw(editorState.getCurrentContent())), document_files: fileAttach, attached_file_name: fileName }).then((response) => {
        console.log(response);
        if (response.status) {
          setMsgDisplay({ status: true, message: response.message })
          shoDialog ? setShoDialog(false) : setShoDialog(true);
          setSelectedValue([]);
          setFilesAttached('');
          setComposeSubject('');
          setEditorState(EditorState.createEmpty())
          setComposeMail(false);
        }
      }).catch((e) => console.log(e));
    }, 500)
  }

  const onSelect = (data) => {
    console.log('onSelect: ', data);
    setSelectedValue(data)
  }

  const onRemove = (data) => {
    console.log('onRemove: ', data)
    setSelectedValue(data)
  }

  const handleDialogue = () => {
    shoDialog ? setShoDialog(false) : setShoDialog(true);
  }

  const handleFolderView = (type) => {
    setFolderView(type);
    if (type === 'inbox') {
      UserInbox().then((response) => {
        console.log("UserInbox", response.data.data.resultData);
        setInboxlist(response.data.data.resultData);
      }).catch((e) => console.log(e));
    } else {
      UserSendbox().then((response) => {
        console.log("UserInbox", response.data.data.resultData);
        setInboxlist(response.data.data.resultData);
      }).catch((e) => console.log(e));
    }
  }

  useEffect(() => {
    console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    if (inboxlist === null && userContext !== null) {
      UserInbox().then((response) => {
        if (checkTokenValidity(response.data)) {
          console.log("UserInbox", response.data.data.resultData);
          setInboxlist(response.data.data.resultData);
        }
      }).catch((e) => console.log(e));
    }
  }, [userContext, editorState])

  const handleSelectedmail = (data) => {
    console.log('selected email', data.id)
    //setSelectedEmail(data);
    custMessageDetail(data.id).then((response) => {
      if (checkTokenValidity(response.data)) {
        console.log('message Details: ', response.data.data.resultData);
        setSelectedEmail(response.data.data.resultData)
      }
    }).catch((e) => console.log(e));
  }

  return (
    <div className='container-fluid mt50 padlr50'>
      <div className='row' id='mailbox'>
        <div className='col-12 col-md-5 col-lg-4'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className="btn-group">
              <button type='button' className={folderView === 'inbox' ? "btn btn-primary active" : "btn btn-primary"} onClick={() => handleFolderView('inbox')}>Inbox</button>
              <button type='button' className={folderView === 'sendbox' ? "btn btn-primary active" : "btn btn-primary"} onClick={() => handleFolderView('sendbox')}>Send Messages</button>
            </div>
            <button type='button' className='btn btn-primary-outline' onClick={() => handleComposeMail()}><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#2a9bdb"><path d="M0 0h24v24H0z" fill="none" /><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" /></svg>&nbsp;&nbsp;<span className='hidden-md'>Compose</span></button>
          </div>
          {inboxlist !== null && inboxlist.length > 0 ?
            <nav className='mt20'>
              {inboxlist.map((item) =>
                <li key={item.id} className={selectedEmail !== null && selectedEmail.id === item.id ? 'active' : ''} onClick={() => handleSelectedmail(item)}>
                  <div className='d-flex justify-content-between align-items-center'>
                    <h3>{item.subject}</h3>
                    <small>{item.date}</small>
                  </div>
                  <p dangerouslySetInnerHTML={{ __html: item.body }} />
                </li>
              )}
            </nav>
            : ''
          }
        </div>
        <div className='col-12 col-md-7 col-lg-8 padlr50 col-xs-hidden'>
          {selectedEmail === null ?
            !composeMail &&
            <div className='text-center'>
              <img src='/assets/imgs/Inbox.jpg' alt='No Email Selected' />
            </div>
            :
            <>
              <div className='d-flex justify-content-between align-items-center'>
                <h2>{selectedEmail.subject}</h2>
                <small>{selectedEmail.date}</small>

              </div>
              <div className='d-flex justify-content-between align-items-center mt10'>
                <h4>From: {selectedEmail.from_email}</h4>
                <h4>To: {selectedEmail.to_email}</h4>
              </div>
              <div className='mt20' dangerouslySetInnerHTML={{ __html: selectedEmail.body }} />
              <div className='mt20'>
                <h4><strong>Attached Files</strong></h4>
                {selectedEmail.attached_files.length > 0 ?
                  <ul className='attachlist'>
                    {selectedEmail.attached_files.map((item, index) =>
                      <li key={index}>
                        <div className='icon'><img src={item.filename.slice(-3) === 'pdf' ? "/assets/imgs/pdfIcon.png" : item.filename.slice(-3) === 'png' ? "/assets/imgs/pngIcon.png" : item.filename.slice(-3) === 'ocx' ? "/assets/imgs/wordIcon.png" : item.filename.slice(-3) === 'lsx' ? "/assets/imgs/excelIcon.png" : item.filename.slice(-3) === 'ptx' ? "/assets/imgs/powerpointIcon.png" : item.filename.slice(-3) === 'gif' ? "/assets/imgs/gifIcon.png" : item.filename.slice(-3) === 'jpg' ? "/assets/imgs/jpgIcon.png" : "/assets/imgs/file.png"} width="20px" /> &nbsp;</div>
                        <h4><strong>{item.filename}</strong></h4>
                        <div className='download'>
                          <a href={item.filepath} target="_blank" download><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#2a9bdb"><path d="M0 0h24v24H0z" fill="none" /><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" /></svg></a>
                        </div>
                      </li>
                    )}
                    {/* <li>
                      <div className='icon'><img src="/assets/imgs/pdfIcon.png" width="20px" /> &nbsp;</div>
                      <h4><strong>Document Name.pdf</strong></h4>
                      <div className='download'>
                        <button type='button'><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#2a9bdb"><path d="M0 0h24v24H0z" fill="none" /><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" /></svg></button>
                      </div>
                    </li>
                    <li>
                      <div className='icon'><img src="/assets/imgs/pdfIcon.png" width="20px" /> &nbsp;</div>
                      <h4><strong>Document Name.pdf</strong></h4>
                      <div className='download'>
                        <button type='button'><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#2a9bdb"><path d="M0 0h24v24H0z" fill="none" /><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" /></svg></button>
                      </div>
                    </li> */}
                  </ul>
                  : ''}
              </div>
              {/* <div className='mt30 d-flex justify-content-end align-items-center'>
                <button type='button' className='btn btn-primary-outline'><svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#2a9bdb"><g><path d="M0,0h24v24H0V0z" fill="none" /></g><g><path d="M15,5l-1.41,1.41L15,7.83L17.17,10H8c-2.76,0-5,2.24-5,5v4h2v-4c0-1.65,1.35-3,3-3h9.17L15,14.17l-1.41,1.41L15,17l6-6 L15,5z" /></g></svg>&nbsp;&nbsp;Forward</button>
                <button type='button' className='btn btn-primary-outline'><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#2a9bdb"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M10 9V5l-7 7 7 7v-4.1c5 0 8.5 1.6 11 5.1-1-5-4-10-11-11z" /></svg>&nbsp;&nbsp;Reply</button>
                <button type='button' className='btn btn-primary-outline'><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#2a9bdb"><path d="M0 0h24v24H0z" fill="none" /><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" /></svg>&nbsp;&nbsp;Delete</button>
              </div> */}
            </>
          }
          {composeMail && selectedEmail === null &&
            <div className='composeMessage'>
              <h3 className='text-primary'>Compose Mail</h3>
              <div className="mb-3 mt50">
                {/* <input type="text" className="form-control" list={userComposeList !== null ? "data" : ""} onChange={this} placeholder="Receipients" /> */}
                {userComposeList !== null &&
                  <Multiselect
                    options={userComposeList} // Options to display in the dropdown
                    selectedValues={selectedValue} // Preselected value to persist in dropdown
                    onSelect={(e) => onSelect(e)} // Function will trigger on select event
                    onRemove={(e) => onRemove(e)} // Function will trigger on remove event
                    displayValue="full_name" // Property name to display in the dropdown options
                  />
                }
                {/* {userComposeList !== null &&
                  <datalist id="data" multiple>
                    {userComposeList.map((item, key) =>
                      <option key={key} value={item.full_name} />
                    )}
                  </datalist>
                } */}
              </div>
              <div className="mb-3">
                <input type="text" className="form-control" placeholder="Subject" value={composeSubject} onChange={(e) => setComposeSubject(e.target.value)} />
              </div>
              <div className="mb-3">
                <div style={{ border: "1px solid black", padding: '2px', minHeight: '400px' }}>
                  <Editor
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                  />
                </div>
                {/* <textarea className="form-control" id="exampleFormControlTextarea1" rows="5"></textarea> */}
              </div>
              <div className='mb-3 d-flex justify-content-between mt20'>
                <div className='col-sm-5'>
                  <div className="fileuploadBtn" style={{ display: 'inline-block' }}>
                    <input type="file" id="formFileMultiple" multiple onChange={(e) => handleFileAttached(e)} />
                    <button type="button" className="btn btn-primary">Attach Files</button>
                  </div>
                  <button type='button' className='btn btn-danger' onClick={() => handleDiscard()} style={{ marginLeft: 10 }}>Discard</button>
                </div>
                <button type='button' className='btn btn-primary' onClick={() => handleSubmitCompose()}>Submit</button>
              </div>
              {filesAttached !== '' && filesAttached.target.files.length > 0 ?
                <div className='mb-3'>
                  <ul className='attachfilelistCompose'>
                    <li><span>{filesAttached.target.files[0].name}</span> <button type='button' className='plain-btn' onClick={() => setFilesAttached('')}>X</button></li>
                  </ul>
                </div>
                : ''
              }
            </div>
          }
        </div>
      </div>
      {shoDialog && <DialogBox data={msgDisplay} handleDialogue={handleDialogue} />}
    </div>
  );
}

export default Inbox;
