import { useState, useEffect, useContext } from 'react';
import { UserContext } from '../App';
import { checkTokenValidity, customerStagewiseTask } from "../api";

function StatewiseAnalyse() {
    const [custStagewiseTask, setCustStagewiseTask] = useState(null);
    const userContext = useContext(UserContext);

    useEffect(() => {
        if (custStagewiseTask === null && userContext !== null) {
            //let tempData = userContext;
            customerStagewiseTask().then((response) => {
                console.log('response.data ', response.data)                
                if (checkTokenValidity(response.data)) {
                    console.log("custStagewiseTask", response.data.data);
                    setCustStagewiseTask(response.data.data);
                }
            }).catch((e) => console.log(e));
        }
    }, [userContext])

    return (
        <div className='row padlr20'>
            <h3 className="text-primary">Stagewise Analysis</h3>
            <div className="maxHeight250 slim-scroll">
                <ul className="stagewiselist">
                    {custStagewiseTask !== null ?
                        custStagewiseTask.task_data.map((item, index) =>
                            <li key={index}>
                                <div className="d-flex justify-content-between mt20">
                                    <span className='font1Vew'>{item.status_title}</span>
                                    <span className="text-secondary font1Vew">{item.task_count}</span>
                                </div>
                                <div className="progress mt10">
                                    <div className="progress-bar" role="progressbar" style={{ width: `${item.task_count / custStagewiseTask.total_task_count * 100}%` }} aria-valuenow={item.task_count} aria-valuemin="0" aria-valuemax={custStagewiseTask.total_task_count}></div>
                                </div>
                            </li>
                        )
                        : 'Loading...'
                    }
                </ul>
            </div>
        </div>
    )
}

export default StatewiseAnalyse;
