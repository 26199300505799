import { useContext, useEffect, useState } from "react";
import { NavLink, Navigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import  playstoreIcon  from "./assets/imgs/playstore.svg";

function LoginPage(props) {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [checkLogin, setCheckLogin] = useState(props.IsLogged);
  const [loginForm, setLoginForm] = useState({
    username: "",
    password: "",
  });

  // username: "stacust",
  //password: "Erp12345"

  const handleLoginForm = () => {
    //console.table(loginForm);
    props.doLogin(loginForm);
  };

  useEffect(() => {
    if (sessionStorage.getItem("userInfo")) {
      const pathToGo = JSON.parse(sessionStorage.getItem("userInfo"))
        .menu_access[0].menu;
      console.log("from login page ", pathToGo);
      return (
        <Navigate
          replace
          to={
            pathToGo === "Dashboard"
              ? "/home"
              : pathToGo === "Projects"
              ? "/projects"
              : pathToGo === "Billing"
              ? "/billing"
              : pathToGo === "Job Request"
              ? "/job-request"
              : pathToGo === "Users"
              ? "/users"
              : pathToGo === "Inbox" && "/inbox"
          }
        />
      );
    }
  }, []);

  return (
    <div className="d-flex mt50 justify-content-center">
      <div className="col-md-3">
        <div className="card box-shadow">
          <h2 className="card-title text-center">Login</h2>
          <div className="card-body">
            <form>
              {props.isLoginError !== null && (
                <div className="alert alert-danger" role="alert">
                  {props.isLoginError}
                </div>
              )}
              <div className="mb-3">
                <label htmlFor="username" className="form-label">
                  Username
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  name="username"
                  aria-describedby="emailHelp"
                  autoComplete="off"
                  value={loginForm.username}
                  onChange={(e) =>
                    setLoginForm({
                      ...loginForm,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>

              <div className="mb-3 ">
                <label htmlFor="pass" className="form-label">
                  Password
                </label>
                {/* <input type="password" */}
                <div className="input-group">
                  <input
                    type={passwordVisible ? "text" : "password"}
                    className="form-control"
                    id="pass"
                    name="password"
                    value={loginForm.password}
                    autoComplete="off"
                    onChange={(e) =>
                      setLoginForm({
                        ...loginForm,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={() => setPasswordVisible(!passwordVisible)}
                  >
                    {passwordVisible ? (
                      <FontAwesomeIcon icon={faEyeSlash} />
                    ) : (
                      <FontAwesomeIcon icon={faEye} />
                    )}
                  </button>
                </div>
              </div>
              {/* added password icon date:31 oct 2023 */}
              <div className="d-flex justify-content-between align-items-center mt50">
                <button type="button" className="btn plain-btn text-primary">
                  Forgot Password?
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-lg"
                  onClick={handleLoginForm}
                >
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
        <div >
              <a
                href="https://play.google.com/store/apps/details?id=com.client.ncserp"
                target="_blank"
                className="d-flex align-items-center"
                style={{marginTop:"-20px"}}
              >
                <img
                  src={playstoreIcon}
                  alt="Play Store"
                  style={{ width: "150px", height: "auto" }}
                />
              </a>
              </div>
      </div>
    </div>
  );
}

export default LoginPage;
